import * as React from 'react'
import PropTypes from 'prop-types'

import { useRecoilValue } from 'recoil'
import { marketCodeManager } from '@/recoil/marketCode'

import Link from '@/components/LocalizedGatsbyLink'

import isExternalLink from '@/utils/isExternalLink'
import isPdf from '@/utils/isPdf'

import {
  Container,
  NavLink
} from './style'

const Nav = ({ links, navItemComponent, gtm }) => {
  const marketCode = useRecoilValue(marketCodeManager)
  const NavItemComponent = navItemComponent || NavLink

  const handleClick = (e, title, uri) => {
    gtm && gtm(title)

    if (uri.includes('<cookie>')) {
      if (window?.OneTrust?.ToggleInfoDisplay) {
        window.OneTrust.ToggleInfoDisplay()
      }

      e.preventDefault()
    }
  }

  return (
    <Container>
      {
        links.map(({ title, url, uri = null, target = '_self' }, i) => (
          <NavItemComponent
            key={ i }
            title={ title }
            { ...((url && (isExternalLink(url.path) || isPdf(url.path))) && { as: 'a', href: url.path, target: '_blank' }) }
            { ...((url && !isExternalLink(url.path) && !isPdf(url.path)) && { as: Link, to: url.path, target }) }
            onClick={ (e) => handleClick(e, title, uri) }
          >
            { title }
            {title === 'SUPPLIER INFO PAGE' && <span>(open in a new tab)</span>}
          </NavItemComponent>
        ))
      }
      {/* {marketCode === 'en-us'
        ? <NavItemComponent as='a' target='_blank' href='https://privacyportal.onetrust.com/webform/f5393f3d-f62c-4f9e-9058-d9b07c5122c2/eccd150c-d40c-4ec7-9fa8-6ab2322a0133'>Data request form</NavItemComponent>
        : ''} */}
    </Container>
  )
}

Nav.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    to: PropTypes.string,
    target: PropTypes.string,
    navItemComponent: PropTypes.node
  })).isRequired
}

export default Nav
