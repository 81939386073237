import styled from '@emotion/styled'

import { mediaMax } from '@/styles/mixins'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 32px 30px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.lightVanilla2};
  ${mediaMax.xs} {
    padding: 3rem;
  }
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Title = styled.span`
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  color: ${({ theme }) => theme.colors.brown};
  text-transform: uppercase;
  letter-spacing: .15rem;
  ${mediaMax.xs} {
    font-size: 1.2rem;
  }
`

export const Separator = styled.span`
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  color: ${({ theme }) => theme.colors.brown};
  text-transform: uppercase;
  margin: 0 10px;
  ${mediaMax.xs} {
    font-size: 1.2rem;
  }
`

export const SubTitle = styled.span`
  font-size: 1.6rem;
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  color: ${({ theme }) => theme.colors.grey};
  letter-spacing: .1rem;
  ${mediaMax.xs} {
    font-size: 1.2rem;
  }
`

export const Close = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 32px;
  right: 20px;
  transform: translateY(-25%);
  cursor: pointer;
`

export const FullScreen = styled.a`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: top: 50%;
  left: 20px;
  cursor: pointer;
`
