/**
 * Cookie Recoil store
 */

import { atom, selector } from 'recoil'
import { NEWSLETTER_MSG_POPUP_ID } from '@/components/Popup/config'

/* AcceptanceState */
export const POPUP_KEY = 'popupState'
export const POPUP_DATA_KEY = 'popupDataState'
export const POPUP_STATUS_KEY = 'popupManager'
export const POPUP_DATA_STATUS_KEY = 'popupDataManager'

// STATE
export const popupState = atom({
  key: POPUP_KEY,
  default: ''
})

export const popupDataState = atom({
  key: POPUP_DATA_KEY,
  default: null
})

// MANAGER
export const popupManager = selector({
  key: POPUP_STATUS_KEY,
  get: ({ get }) => get(popupState) && get(popupState).length,
  set: ({ set }, newValue) => {
    const scrollablePopups = [NEWSLETTER_MSG_POPUP_ID]
    // PREVENT SCROLL
    if (newValue.length) {
      if (!scrollablePopups.includes(newValue)) {
        document.documentElement.style.overflow = 'hidden'
      }
    } else {
      set(popupDataState, null)
      document.documentElement.style.overflow = 'initial'
    }

    set(popupState, newValue)
  }
})

export const popupDataManager = selector({
  key: POPUP_DATA_STATUS_KEY,
  get: ({ get }) => get(popupDataState) && Object.keys(get(popupDataState)).length,
  set: ({ set }, newValue) => {
    set(popupDataState, newValue)
  }
})
