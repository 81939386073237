import styled from '@emotion/styled'
import { mediaMax } from '@/styles/mixins'

export const Container = styled.div`
  margin-right: 4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-decoration: none;

  ${mediaMax.md}{
    display: flex;
    flex-direction: column-reverse;
  }

  ${mediaMax.sm} {
    margin-right: 0;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: var(--gap-items);
    gap: 2rem;
  }

  &:last-child {
    margin-right: 0;
  }
`

export const Image = styled.div`
  position: relative;
  width: ${({ imageSize }) => imageSize === 'small' ? '18vw' : '24vw'};
  height: ${({ imageSize }) => imageSize === 'small' ? '18vw' : '24vw'};
  border-radius: 50%;
  background-size: cover;
  background-image: ${({ src }) => `url(${src})`};


  ${mediaMax.sm} {
    width: ${({ imageSize }) => imageSize === 'small' ? '12vw' : '20vw'};
    height: ${({ imageSize }) => imageSize === 'small' ? '12vw' : '20vw'};
  }

  ${mediaMax.xs} {
    width: ${({ imageSize }) => imageSize === 'small' ? '18vw' : '24vw'};
    height: ${({ imageSize }) => imageSize === 'small' ? '18vw' : '24vw'};
  }

`

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 4.3rem;

  ${mediaMax.sm} {
    margin-top: 0;
    justify-content: flex-start;
    align-items: flex-start;
    width: 30ch;
  }
`

export const Subtitle = styled.span`
  font-size: 1.2rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.green};
  margin-bottom: 1rem;
  letter-spacing: 1px;
  ${mediaMax.md} {
    width: 100%;
  }

  ${mediaMax.sm} {
    font-size: 1.4rem;
    text-align: left;
  }
`

export const Title = styled.span`
  font-family: ${({ theme }) => theme.fonts.serif};
  font-size: 2.5rem;
  color: ${({ theme }) => theme.colors.brown};
  margin-bottom: 1.5rem;
  ${mediaMax.sm} {
    font-size: 2.8rem;
  }
`
