import styled from '@emotion/styled'
import { mediaMax } from '@/styles/mixins'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${mediaMax.xs} {
    overflow: hidden;
  }
`

export const LeftArrow = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: .2;
  transition: opacity ease-out .3s, clip-path .4s;
  clip-path: polygon(0 0, 50% 0, 50% 100%, 0 100%);

  &.prevActive {
    opacity: 1;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }

  &.bothActive {
    clip-path: polygon(0 0, 75% 0, 75% 100%, 0 100%);
  }
`

export const RightArrow = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: .2;
  transition: opacity ease-out .3s, clip-path .4s;
  clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%);

  &.nextActive {
    opacity: 1;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }

  &.bothActive {
    clip-path: polygon(25% 0, 100% 0, 100% 100%, 25% 100%);
  }
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 3.5rem;

  &.regular {
    ${LeftArrow},
    ${RightArrow} {
      transform: rotate(180deg);
    }
  }
`
