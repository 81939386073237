import styled from '@emotion/styled'
import { GridContainer } from '@/components/Grid'
import WysiwygBase from '@/components/Wysiwyg'
import ButtonBase from '@/components/Button'
import { mediaMax } from '@/styles/mixins'
import wineFinderImage from '@/images/subnav-wine-finder.png'

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.lightVanilla3};
  padding: 6rem 0;
  --gap-items: 2.4rem;
  @media (max-height: 870px) {
    --gap-items: 2rem;
  }
  ${mediaMax.xs} {
    height: calc(100vh - var(--header-height) + 1px);
    overflow: scroll;
    padding: var(--gap-items) 0;
  }
`

export const StyledGridContainer = styled(GridContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${mediaMax.xs}{
    flex-direction: column;
  }
`

export const TextColumn = styled.div`
  width: 45%;
  align-self: stretch;
  position: relative;
  ${mediaMax.xs}{
    width: 100%;
  }
`

export const LocationsColumn = styled.div`
  display: flex;

  ${mediaMax.md}{
    flex-direction: column;
    align-items: center;
  }

  ${mediaMax.xs}{
    width: 100%;
    align-items: flex-start;
    margin-bottom: var(--gap-items);
  }
`

export const Title = styled.p`
  font-family: ${({ theme }) => theme.fonts.serif};
  font-size: 5.5rem;
  color: ${({ theme }) => theme.colors.greyText};
  margin-bottom: 34px;
  font-weight: bold;

  ${mediaMax.xs}{
    font-size: 3.6rem;
    margin-bottom: var(--gap-items);
  }
`

export const Text = styled(WysiwygBase)`
  max-width: 28vw;
  color: ${({ theme }) => `${theme.colors.grey}`};
  margin-bottom: 39px;
  font-weight: regular;

  ${mediaMax.xs}{
    max-width: unset;
    margin-bottom: 2.5rem;
  }
`

export const Button = styled(ButtonBase)`
  display: inline-flex;
  width: auto;
  padding: 1.8rem 4rem;
  ${mediaMax.xs}{
    display: none;
  }

  span {
    font-size: 1.2rem;
  }
`

export const WineFinderContainer = styled.div`
  margin-right: 4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${mediaMax.sm} {
    margin-right: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`

export const WineFinderImage = styled.div`
  position: relative;
  width: 24vw;
  height: 24vw;
  border-radius: 50%;
  background-size: cover;
  background-image: url(${wineFinderImage});

  ${mediaMax.sm} {
    width: 52vw;
    height: 52vw;
  }
`

export const WineFinderTitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 4.3rem;

  ${mediaMax.sm} {
    margin-top: 8rem;
  }
`

export const WineFinderSubtitle = styled.span`
  font-size: 1.2rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.green};
  margin-bottom: 1rem;
  letter-spacing: 1px;

  ${mediaMax.sm} {
    font-size: 1rem;
  }
`

export const WineFinderTitle = styled.span`
  font-family: ${({ theme }) => theme.fonts.serif};
  font-size: 2.5rem;
  color: ${({ theme }) => theme.colors.brown};
  margin-bottom: 1.5rem;

  ${mediaMax.sm} {
    font-size: 2.8rem;
  }
`

export const Map = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 20vw;
  ${mediaMax.sm}{
    transform: translateX(-50%);
    right: -10vw;
  }
  ${mediaMax.xs} {
    display: none;
  }
`

export const MobileMap = styled(Map)`
  display: none;
  position: static;
  height: auto;
  max-height: 40vh;
  width: auto;

  ${mediaMax.xs} {
    display: block;
    transform: none;
    right: 0;
  }
`

export const Header = styled.div`
  display: none;
  ${mediaMax.xs}{
    display: flex;
    width: 100%;
    margin-bottom: var(--gap-items);
  }
`
