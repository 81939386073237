import styled from '@emotion/styled'
import Link from '@/components/LocalizedGatsbyLink'
import { GridContainer } from '@/components/Grid'
import WysiwygBase from '@/components/Wysiwyg'
import ButtonBase from '@/components/Button'
import { mediaMax } from '@/styles/mixins'
import { Container as WinesSliderItemContainer } from '@/components/WinesSlider/WinesSliderItem/style'
import { Wrapper as WinesItem, Art as WinesArt } from '@/components/WinesSlider/WinesSliderItem/style.js'

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.lightVanilla3};
  padding: 6rem 0;
  --gap-items: 2rem;
  --underline-thickness: 1px;
  --underline-gap: 5px;
  @media (max-height: 870px) {
    --gap-items: 1.6rem;
  }
  ${mediaMax.xs} {
    height: calc(100vh - var(--header-height) + 1px);
    overflow: scroll;
    padding: var(--gap-items) 0;
  }

`

export const StyledGridContainer = styled(GridContainer)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  ${mediaMax.xs}{
    flex-direction: column;
  }
`

export const TextColumn = styled.div`
  width: 45%;
  align-self: flex-start;
  ${mediaMax.xs}{
    width: 100%;
  }
  div p{
    font-weight: normal;
    color: ${({ theme }) => theme.colors.brown}8c;
    margin-bottom: 2.5rem;
  }
`

export const WinesListColumn = styled.div`
  display: flex;
  flex-direction: column;
  ${mediaMax.xs}{
    width: 100%;
    margin-bottom: var(--gap-items);
  }
`

export const WinesListItem = styled(Link)`
  margin-bottom: calc(${({ theme }) => theme.spacing.small} - var(--underline-gap) - var(--underline-thickness)) ;
  font-size: 1.4rem;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.brown};
  cursor: pointer;
  text-decoration: none;

  span {
    position: relative;
    display: flex;
    width: fit-content;
    flex-direction: column;
    gap: 5px;
    justify-content: flex-start;
  }

  span::after {
    display: block;
    content: "";
    width: 100%;
    height: var(--underline-thickness);
    transition: transform .4s ease;
    transform-origin: left;
    background-color: ${({ theme }) => theme.colors.brown};
    transform: scaleX(${p => p.active ? 1 : 0});
    bottom: var(--underline-gap);
  }

  ${mediaMax.xs}{
    font-size: 1.8rem;
    color: ${({ theme }) => theme.colors.brown}7d;
    margin-bottom: 2.5rem;
  }

`

export const AllWinesLink = styled(Link)`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.green};
  text-transform: uppercase;
  text-decoration: underline;
  letter-spacing: 1px;
  ${mediaMax.xs}{
    font-size: 1.7rem;
    color: ${({ theme }) => theme.colors.green};
  }
`

export const SelectedWineColumn = styled.div`
  width: 24vw;
  ${mediaMax.xs}{
    width: 50vw;
    width: 100%;
    margin-top: 2rem;
    padding-bottom: 12dvh;
    ${WinesItem}{
      width: 50vw;
      height: 50vw;
    }
  }
`

export const Title = styled.p`
  font-family: ${({ theme }) => theme.fonts.serif};
  font-size: 5.5rem;
  color: ${({ theme }) => theme.colors.greyText};
  margin-bottom: 34px;
  font-weight: bold;
  ${mediaMax.xs}{
    font-size: 3.6rem;
    margin-bottom: var(--gap-items);
  }
`

export const Text = styled(WysiwygBase)`
  max-width: 28vw;
  color: ${({ theme }) => `${theme.colors.grey}`};
  font-weight: bold;
  margin-bottom: 39px;
  ${mediaMax.xs}{
    max-width: unset;
    margin-bottom: var(--gap-items);
  }
`

export const Button = styled(ButtonBase)`
  display: inline-flex;
  width: auto;
  padding: 1.8rem 4rem;
  ${mediaMax.xs}{
    display: none;
  }

  span {
    font-size: 1.2rem;
  }
`

export const ButtonMobile = styled(Button)`
  display: none;
  ${mediaMax.xs}{
    display: block;
    margin: var(--gap-items) 0;
    width: 100%;
  }
`

export const Header = styled.div`
  display: none;
  ${mediaMax.xs}{
    display: flex;
    width: 100%;
    margin-bottom: var(--gap-items);
  }
`
export const WineItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${WinesSliderItemContainer}{
    margin-right: 0;
  }
  ${WinesArt}{
    opacity: 1;
  }

`
