export const WD_ENDPOINT = 'https://shop.cloudybay.com' // PROD
// export const WD_ENDPOINT = 'https://shop-test.cloudybay.com' // DEV

/**
 * @class WineDirect (Singleton)
 */
export default class WineDirect {
  static instance = null

  constructor (options) {
    if (!(this.constructor).instance) {
      (this.constructor).instance = this
    } else {
      return (this.constructor).instance
    }

    if (this.onInit) { this.onInit(options) }
  }

  onInit () {
    this.isCartInit = false
    this.isAddToCartInit = false
    this.isUserToolsInit = false
  }

  initWDCart () {
    if (!this.isCartInit) {
      if (window.vin65remote) {
        window.vin65remote.cart.init(WD_ENDPOINT, 0)
        this.isCartInit = true
      }
    }

    if(document.querySelector('[v65remotejs="modalCart"]').innerHTML === '')
      window.vin65remote.cart._loadExternalScripts()
  }

  destroyWDAddToCart (el) {
    this.isAddToCartInit = false
    el.innerHTML = ''
  }

  initWDAddToCart () {
    if (!this.isAddToCartInit) {
      if (window.vin65remote) {
        window.vin65remote.product.addToCartForm(WD_ENDPOINT)
        this.isAddToCartInit = true
      }
    }
  }

  initWDUserTools () {
    if (!this.isUserToolsInit) {
      if (window.vin65remote) {
        window.vin65remote.usertools.loginWidget(WD_ENDPOINT)
        this.isUserToolsInit = true
      }
    }

    if(document.querySelector('[v65remotejs=loginWidget]').innerHTML === '')
      window.vin65remote.usertools.loginWidget(WD_ENDPOINT)
  }
}
