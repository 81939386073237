import * as React from 'react'
import { useRef, useEffect, useState } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { useLocation } from '@reach/router'
import { popupManager, popupDataManager, popupDataState } from '@/recoil/popup'
import Popup from '@/components/Popup'
import { NL_POPIN_CLICK } from '@/utils/gtmEvents'
import { NEWSLETTER_MSG_POPUP_ID } from '@/components/Popup/config'
import EmailField from '@/components/Forms/Inputs/Email'
import { loadingState } from '@/recoil/loader'
import { hasValidAgeManager } from '@/recoil/customer'
import { isMobileState, isTabletState } from '@/recoil/layout'

import {
  ContentContainer,
  PopupWrapper,
  Button,
  Text
} from './style'

import {
  FormGroup
} from '../../Forms/ContactForm/style'

const NewsletterMsgPopup = () => {
  const emailRef = useRef(null)
  const setPopupData = useSetRecoilState(popupDataManager)
  const setPopup = useSetRecoilState(popupManager)
  const popupData = useRecoilValue(popupDataState)
  const [clickOnPopup, setClickOnPopup] = useState(0)
  const [gtmSubmit, setGtmSubmit] = useState(false)
  const isLoading = useRecoilValue(loadingState)
  const ageGateValidated = useRecoilValue(hasValidAgeManager)
  const isMobile = useRecoilValue(isMobileState)
  const isTablet = useRecoilValue(isTabletState)

  const [isHidden, setIsHidden] = useState(false)
  const route = useLocation()

  const checkPathname = (pathname) => ['wine-club', 'newsletter'].some(path => pathname.includes(path))

  useEffect(() => {
    if ((isMobile || isTablet) && checkPathname(route.pathname)) {
      setIsHidden(true)
    }

    if (!isLoading && ageGateValidated) {
      const id = setInterval(handleSubmit, 120000)
      return () => clearInterval(id)
    }

    return () => {
      setIsHidden(false)
    }
  }, [route, isLoading, ageGateValidated, clickOnPopup])

  const handleSubmit = (push = false) => {
    if (push && !gtmSubmit) {
      setGtmSubmit(true)
      window.dataLayer && window.dataLayer.push({
        event: NL_POPIN_CLICK
      })
    }

    setPopup('')
    setPopupData({
      email: emailRef.current.getValue(),
      origin: 'Popin'
    })
  }

  return (
    <PopupWrapper>
      <Popup
        id={ NEWSLETTER_MSG_POPUP_ID }
        subtitle={ popupData?.data?.fieldNewsletterSubtitle }
        title={ popupData?.data?.fieldNewsletterLabel }
        className={ isHidden ? 'newsletterMsgPopup is-hidden' : 'newsletterMsgPopup' }
      >
        <ContentContainer onClick={ () => setClickOnPopup(clickOnPopup + 1) }>
          <Text dangerouslySetInnerHTML={ { __html: popupData?.data?.fieldNewsletterContent } } />

          <FormGroup>
            <EmailField
              id='newsletter_msg_email'
              ref={ emailRef }
              label={ popupData?.data?.fieldNewsletterInputLabel }
              placeholder={ popupData?.data?.fieldNewsletterInputPlacehold }
              name='email'
              autocomplete='nope'
            />

          </FormGroup>

          <Button to='/newsletter' ctaTracking={ false } onClick={ () => handleSubmit(true) }>{ popupData?.data?.fieldNewsletterButtonLabel }</Button>
        </ContentContainer>
      </Popup>
    </PopupWrapper>
  )
}

export default NewsletterMsgPopup
