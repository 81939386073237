import styled from '@emotion/styled'
import { mediaMax } from '@/styles/mixins'
import { FormContainer as ContactFormContainer } from '@/components/Forms/ContactForm/style'
import { Label as Checkbox } from '@/components/Forms/Inputs/Checkbox/style'

export const FormContainer = styled(ContactFormContainer)`
  --fs-placeholders: 1rem;
  --fs-labels: 1.2rem;
  --fs-form-group-labels: 1.2rem;

  --gap-inputs: 1.4rem;
  --gap-form-group: 1.4rem;
  --gap-form-group-label: 18px;

  --padding-input-x: 2.3rem;
  --padding-input-y: 1.4rem;

  margin: auto;
  max-width: 80rem;
  ${Checkbox}{
    font-size: 1rem;
    a{
      font-size: 1rem;
      color: ${p => p.theme.colors.green};
    }
  }
  ${mediaMax.sm}{
    --fs-placeholders: 1.4rem;
    --fs-labels: 1.2rem;
    --padding-input-x: 2rem;
    --padding-input-y: 1rem;
  }

  ${mediaMax.xs}{
    margin-bottom: 4rem;
  }
`

export const Consent = styled.div`
  font-family: ${p => p.theme.fonts.sansSerif};
  font-size: 1rem;
  color: ${p => p.theme.colors.black};
  display: flex;
  flex-direction: column;
  ${mediaMax.sm}{
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
  a {
    cursor: pointer;
    text-decoration: underline;
  }

  span {
    position: absolute;
    width: 1px;
    clip: rect(0 0 0 0);
    overflow: hidden;
    white-space: nowrap;
  }
`

export const Mandatory = styled.div`
    font-style: italic;
    font-family: ${p => p.theme.fonts.sansSerif};
    font-size: 1rem;
    color: ${p => p.theme.colors.grey};
    line-height: 1.6rem;
    ${mediaMax.sm}{
      font-size: 1.2rem;
    }
`

export const SuccessMessage = styled.div`
  text-align: center;
  font-size: 1.75rem;
  margin-top: 4rem;
  color: ${p => p.theme.colors.green};
`

export const ErrorMessage = styled.div`
  text-align: center;
  font-size: 1.75rem;
  margin-top: 4rem;
  color: ${p => p.theme.colors.redError};
`

export const ErrorLabel = styled.p`
  text-transform: uppercase;
  font-family: ${(props) => props.theme.fonts.sansSerif};
  color: ${(props) => props.theme.colors.redError};
  font-size: 0.8rem;
  line-height: 1.2rem;
  margin-bottom: 1rem;
  /* text-align: center; */
`
