import React from 'react'
import PropTypes from 'prop-types'

import {
  Container,
  Wrapper,
  Title,
  Type,
  Text
} from './style'

const SearchCard = ({ title = '', type = '', text = '', url = '' }) => {
  return (
    <Container to={ url }>
      <Wrapper>
        <Title>
          { title }
        </Title>
        <Type>
          { type }
        </Type>
        {
            (text && text.length) && (
              <Text dangerouslySetInnerHTML={ { __html: text } } />
            )
          }
      </Wrapper>
    </Container>
  )
}

SearchCard.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string
}

export default SearchCard
