import React from 'react'
import { useTheme } from '@emotion/react'

import Icon from '@/components/Icon'

import {
  Container,
  Type,
  Name,
  Details,
  Links,
  Link,
  LinkLabel,
  Distance
} from './style'

const StoresListItem = ({
  onClick,
  type,
  name,
  area,
  city,
  country,
  details,
  googlePlaceId,
  distanceFromSelectedPlace,
  isActive
}) => {
  const { colors } = useTheme()

  return (
    <Container onClick={ onClick } isActive={ isActive }>
      {type && (
        <Type>{ type }</Type>
      )}
      <Name>{ name }</Name>
      {details && (
        <Details>{ details }</Details>
      )}
      {city && (
        <Details>{ city }</Details>
      )}
      {area && (
        <Details>{ area.toUpperCase() }</Details>
      )}
      {country && (
        <Details>{ country }</Details>
      )}
      {googlePlaceId && (
        <Links>
          {googlePlaceId && (
            <Link href={ `https://www.google.com/maps/place/?q=place_id:${googlePlaceId}` } target='_blank' rel='noreferrer'>
              <Icon name='pin' color={ colors.green } />
              <LinkLabel>View on Google Maps</LinkLabel>
            </Link>
          )}
        </Links>
      )}
      {distanceFromSelectedPlace && (
        <Distance>{ `at ${Math.round(distanceFromSelectedPlace * 100) / 100} km` }</Distance>
      )}
    </Container>
  )
}

export default StoresListItem
