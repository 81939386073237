import styled from '@emotion/styled'
import { mediaMax, styledScrollbar } from '@/styles/mixins'

import { Container as PopupBodyContainer } from '@/components/Popup/atoms/Body/style'
import { Container as InputContainer } from '@/components/Forms/Inputs/Email/style'
import {
  Container as PopupHeadContainer,
  Title as PopupHeadTitle
} from '@/components/Popup/atoms/Header/style'

import ButtonBase from '@/components/Button'
import { ButtonLabel as ButtonLabelBase } from '@/components/Button/style'

export const PopupWrapper = styled.div`

  ${PopupBodyContainer} {
    padding: 0;
    align-items: center;
    overflow-y: auto;
    ${styledScrollbar()}
    background-color: ${({ theme }) => theme.colors.lightVanilla3};
    height: 60rem;
    ${mediaMax.xs}{
      height: 90vh;
    }
  }

  ${PopupHeadContainer}{
    justify-content: flex-start;
    background-color: ${({ theme }) => theme.colors.lightVanilla3};
    border-bottom: none;
    padding: 32px 30px 0px;

    > div {
      flex-direction: column-reverse;
      align-items: flex-start;

      span:nth-child(3) {
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.green};
        font-size: 1rem;
        letter-spacing: 0.1rem;
        font-family: ${({ theme }) => theme.fonts.sansSerif};
        margin-bottom: 1rem;
      }

      span:nth-child(1) {
        font-family: ${({ theme }) => theme.fonts.serif};
        font-size: 3.5rem;
      }

      span:nth-child(2) {
        display: none;
      }
    }
  }

  ${PopupHeadTitle} {
    text-transform: none;
    font-size: 3rem;
  }

  .newsletterMsgPopup {
    position: absolute;
    top: 9.7rem;
    right: 2.5vw;
    width: 50rem;
    height: initial;

    ${mediaMax.sm} {
      width: 90%;

      right: auto;
      left: auto;
    }

    ${PopupBodyContainer} {
      height: initial;
    }
  }
`
export const ContentContainer = styled.div`
  width: 100%;
  --fs-placeholders: 1rem;
  --fs-labels: 1.2rem;
  --fs-form-group-labels: 1.2rem;

  --gap-inputs: 1.4rem;
  --gap-form-group: 1.4rem;
  --gap-form-group-label: 18px;

  --padding-input-x: 2.3rem;
  --padding-input-y: 1.4rem;

  margin: auto;

  ${mediaMax.sm}{
    --fs-placeholders: 1.4rem;
    --fs-labels: 1.2rem;
    --padding-input-x: 2rem;
    --padding-input-y: 1rem;
  }

  ${mediaMax.xs}{
    margin-bottom: 4rem;
  }

  padding: 3rem;

  > div > div > div > div {
    padding: 1.5rem 2.3rem !important;
  }

  ${InputContainer} {
    background-color: ${p => p.theme.colors.white};
  }
`

export const Button = styled(ButtonBase)`
  width: 100%;
  margin-top: 15px;
  padding: 1.8rem 4rem;
  border: 1px solid ${p => p.theme.colors.green};
  // padding: 25px 30px;
  // font-size: 1.1rem;

  ${mediaMax.sm} {
    width: 100%;
    margin-top: 3rem;
    margin-left: 0;
  }

  ${ButtonLabelBase} {
    font-size: 1.4rem;
  }
`
export const Text = styled.p`
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  font-size: 1.2rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.colors.grey};
  margin-bottom: 3rem;
`

export const Subtitle = styled.p`
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.4;
  margin-bottom: 3rem;
`
