import React from 'react'
import { useTheme } from '@emotion/react'

// import { Link } from 'gatsby'
import { useLocation } from '@reach/router'

import { useRecoilValue, useSetRecoilState } from 'recoil'
import { popupManager } from '@/recoil/popup'

import { marketCodeManager } from '@/recoil/marketCode'
import Icon from '@/components/Icon'
import Button from '@/components/Button'

import { STORE_LOCATOR } from '@/utils/gtmEvents'
import { STORE_LOCATOR_POPUP_ID } from '@/components/Popup/config'

import {
  Container,
  Name,
  Link,
  LinkLabel,
  Image,
  ContainerHorizontal
} from './style'

const StoresListItemRetailer = ({
  onClick,
  name,
  url,
  isActive,
  logo = 'https://i.etsystatic.com/17725599/r/il/f04b09/3649826844/il_1588xN.3649826844_5io4.jpg',
  isProductCard = false,
  pageType
}) => {
  const marketCode = useRecoilValue(marketCodeManager)

  const setPopup = useSetRecoilState(popupManager)

  const { colors } = useTheme()
  const location = useLocation()

  const gtm = (location, action) => {
    window.dataLayer && window.dataLayer.push({
      event: STORE_LOCATOR,
      site_location: pageType,
      interaction_type: action
    })
  }

  const Item = (

    <Container onClick={ onClick } isActive={ isActive }>
      <Name>{ name }</Name>

      <Link href={ url } target='_blank' rel='external noreferrer'>
        <Icon name='cart' color={ colors.green } />
        <LinkLabel
          onClick={ () => {
            gtm(location, 'Buy Online')
          } }
        >
          Buy online
        </LinkLabel>
      </Link>
    </Container>
  )

  const CardItemVersion = (
    <ContainerHorizontal onClick={ onClick } isActive={ isActive }>
      {logo && <Image loading='lazy' src={ logo } />}
      <Name className={ `${!logo ? 'noMarginLeft' : ''}` }>{ name }</Name>
      <Button
        target='_blank'
        className='productCardRetailersBtn'
        onClick={ () => { marketCode === 'en-ww' ? setPopup(STORE_LOCATOR_POPUP_ID) : window.open(`${url}`, '_blank') } }
        ctaTracking={ false }
      >Buy now
      </Button>
    </ContainerHorizontal>
  )

  return isProductCard ? CardItemVersion : Item
}

export default StoresListItemRetailer
