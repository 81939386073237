/**
 * Device Recoil store
 */
 import { atom } from 'recoil'

 /* isTouchDevice */
 export const IS_TOUCH_DEVICE = 'IS_TOUCH_DEVICE'
 
 export const isTouchDeviceState = atom({
   key: IS_TOUCH_DEVICE,
   default: false
 })
  