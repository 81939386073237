import * as React from 'react'
import { Helmet } from 'react-helmet'

const HrefLangs = ({
  path = '',
  locales = ['en-us', 'en-au', 'en-nz', 'en-gb', 'en-ww']
}) => {
  const currentMarketCode = path.split('/')[1]
  const currentPath = path.split('/')[2]
  const nzSpecificPages = ['wine-club', 'other-products']
  const isNzSpecificPage = nzSpecificPages.indexOf(currentPath) > -1
  const url = `${process.env.URL || process.env.GATSBY_DRUPAL_URL}${path}`.endsWith('/') ? `${process.env.URL || process.env.GATSBY_DRUPAL_URL}${path}` : `${process.env.URL || process.env.GATSBY_DRUPAL_URL}${path}/`
  const marketCode = (currentMarketCode && currentMarketCode.length && currentMarketCode.split('-').length >= 2) ? currentMarketCode.split('-')[1] : ''
  const isNz = marketCode.toLowerCase() === 'nz'
  const isAu = marketCode.toLowerCase() === 'au'

  return (
    <Helmet
      htmlAttributes={ {
        lang: currentMarketCode
      } }
    >
      <link rel='canonical' href={ url } />

      {isNzSpecificPage && (
        <link rel='alternate' href={ url } hrefLang='en-nz' />
      )}

      {!isNzSpecificPage && locales.map((marketCode, i) => (
        <link key={ `alt-${i}` } rel='alternate' href={ url.replace(currentMarketCode, marketCode) } hrefLang={ marketCode === 'en-ww' ? 'x-default' : marketCode } />
      ))}


      {/* NZ | AU Specific GTAG SCRIPT */}

      {(isNz || isAu) && (
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11296723790"></script>
      )}

      {(isNz || isAu) && (
        <script
          dangerouslySetInnerHTML={ {
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'AW-11296723790');
            `
          } }
        >
        </script>
      )}
    </Helmet>
  )
}

export default HrefLangs
