import * as React from 'react'
import PropTypes from 'prop-types'

import {
  Container,
  Wrapper
} from './style'

const Body = ({ children }) => {
  return (
    <Container>
      <Wrapper>
        { children }
      </Wrapper>
    </Container>
  )
}

Body.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default Body
