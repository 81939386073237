import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { useLocation } from '@reach/router'
import Icon from '@/components/Icon'
import { STORE_LOCATOR } from '@/utils/gtmEvents'
import { MAPBOX_TOKEN } from '../config'

import {
  Container,
  SearchInput,
  GeolocationInput
} from './style'

const Geocoder = ({
  pageType,
  onSelectPlace = () => {}
}) => {
  const [selectedOption, setSelectedOption] = useState(null)
  const [isGeolocationLoading, setIsGeolocationLoading] = useState(false)
  const websiteLocation = useLocation()

  const gtm = (location, action) => {
    window.dataLayer && window.dataLayer.push({
      event: STORE_LOCATOR,
      site_location: pageType,
      interaction_type: action
    })
  }

  useEffect(() => {
    if (selectedOption) {
      onSelectPlace(selectedOption.value)
      gtm(websiteLocation, 'Search on map')
    }
  }, [selectedOption])

  const formatOption = (option) => ({
    label: option.place_name,
    value: option
  })

  const loadLocations = (inputValue, callback) => {
    const fetchData = async () => {
      const response = await axios(`https://api.mapbox.com/geocoding/v5/mapbox.places/${inputValue}.json?access_token=${MAPBOX_TOKEN}&limit=10`)
      const data = response.data.features.map(formatOption)
      callback(data)
    }

    fetchData()
      .catch(err => console.error(err))
  }

  const getUserLocation = () => {
    if (!isGeolocationLoading) {
      setIsGeolocationLoading(true)

      const fetchData = async (position) => {
        const response = await axios(`https://api.mapbox.com/geocoding/v5/mapbox.places/${position.coords.longitude},${position.coords.latitude}.json?access_token=${MAPBOX_TOKEN}`)
        setSelectedOption(formatOption(response.data.features[0]))
        setIsGeolocationLoading(false)
      }

      navigator.geolocation.getCurrentPosition((position) => {
        fetchData(position)
          .catch(err => console.error(err))
      })
    }
  }

  return (
    <Container>
      <SearchInput
        loadOptions={ loadLocations }
        value={ selectedOption }
        placeholder='Enter your address'
        onChange={ (option) => setSelectedOption(option) }
        openMenuOnClick
        components={ { DropdownIndicator: () => null, IndicatorSeparator: () => null } }
        noOptionsMessage={ () => null }
        isClearable='true'
      />
      {typeof window !== 'undefined' && navigator.geolocation && (
        <GeolocationInput
          isLoading={ isGeolocationLoading }
          onClick={ getUserLocation }
        >
          <Icon name='geolocation' size={ 20 } />
        </GeolocationInput>
      )}
    </Container>
  )
}

Geocoder.propTypes = {
  onSelectPlace: PropTypes.func
}

export default Geocoder
