// POPUP TRANSITION DURATION
export const ANIMATION_DURATION = 400

// POPUP ID KEY
export const MAP_POPUP_ID = 'MAP_POPUP_ID'
export const NEWSLETTER_POPUP_ID = 'NEWSLETTER_POPUP_ID'
export const TASTING_POPUP_ID = 'TASTING_POPUP_ID'
export const TEAM_POPUP_ID = 'TEAM_POPUP_ID'
export const EVENT_POPUP_ID = 'EVENT_POPUP_ID'
export const VIDEO_POPUP_ID = 'VIDEO_POPUP_ID'
export const STORE_LOCATOR_POPUP_ID = 'STORE_LOCATOR_POPUP_ID'
export const HTML_POPUP_ID = 'HTML_POPUP_ID'
export const SUSTAINABILITY_POPUP_ID = 'SUSTAINABILITY_POPUP_ID'
export const NEWSLETTER_MSG_POPUP_ID = 'NEWSLETTER_MSG_POPUP_ID'
