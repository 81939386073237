import * as React from 'react'
import { useRecoilValue } from 'recoil'
import moment from 'moment'
import { popupState, popupDataState } from '@/recoil/popup'
import { HTML_POPUP_ID } from '@/components/Popup/config'
import Popup from '@/components/Popup'

import {
  Container,
  Content,
  Wysiwyg
} from './style'

const HTMLPopup = () => {
  const popupData = useRecoilValue(popupDataState)

  return (
    <Popup
      id={ HTML_POPUP_ID }
      className={ popupData?.className }
      title={ popupData?.title }
      closeCallback={ popupData?.closeCallback}
    >
      <Container>
        <Content>
          <Wysiwyg>
            { popupData?.content }
          </Wysiwyg>
        </Content>
      </Container>
    </Popup>
  )
}

export default HTMLPopup
