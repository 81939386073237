import styled from '@emotion/styled'

const Line = styled.div`
    background-color: var(--text-color);
    position: absolute;
    height: 1.5px;
    transform-origin:center;
  
`

export const Top = styled(Line)``

export const Middle = styled(Line)``

export const Bottom = styled(Line)``

export const Container = styled.div`
    position: relative;
    height: ${p => p.size}px;
    width: ${p => p.size}px;


    ${Top}, ${Middle}, ${Bottom} {
        transition: transform var(--anim-duration) var(--anim-delay) ease, opacity ${p => p.isOpen ? '0.2s' : '0.2s 0.2s'} ease, background-color var(--anim-duration) var(--anim-delay) ease;
    }


    ${Middle}{
        width: ${p => p.size * 1}px;
        opacity: ${p => p.isOpen ? 0 : 1};
        top: 50%;

    }
    ${Top}{
        position: relative;
        width: ${p => p.size * 1}px;
        transform: ${p => p.isOpen ? `translate3d(0, ${Math.abs(p.size * 0.5)}px, 0) rotate(45deg)` : `translate3d(0, ${Math.abs(p.size * 0.15)}px, 0) rotate(0deg)`};
    }

    ${Bottom}{
        position: relative;
        width: ${p => p.size * 1}px;
        transform: ${p => p.isOpen ? `translate3d(0, ${Math.abs(p.size * 0.45)}px, 0) rotate(-45deg)` : `translate3d(0, ${Math.abs(p.size * 0.85)}px, 0) rotate(0deg)`};
    }
`
