import { resolve } from './entity'

class Search {
  static types = {
    Drupal_NodeWineClub: {
      name: 'Drupal_NodeWineClub',
      label: 'Page',
      title: 'entityLabel',
      field: ['fieldWineClubMetaTags.entity.fieldMetaDescription', 'fieldWineClubMetaTags.entity.fieldMetaTitle', 'fieldWineClubQuote', 'fieldWineClubBenefits.entity.fieldWineClubBenefitDesc', 'fieldWineClubBenefits.entity.fieldWineClubBenefitSubtitle', 'fieldWineClubBenefits.entity.fieldWineClubBenefitTitle', 'fieldWineClubHeroBlock.entity.fieldHeroTitle', 'fieldWineClubHeroBlock.entity.fieldHeroSubtitle', 'fieldWineClubHeroBlock.entity.fieldHeroDescription', 'fieldWineClubIntroBlock.entity.fieldWineClubIntroIntro', 'fieldWineClubIntroBlock.entity.fieldWineClubIntroSubtitle', 'fieldWineClubIntroBlock.entity.fieldWineClubIntroTitle', 'fieldWineClubSelectionBlock.entity.fieldWineClubSelectSubtitle', 'fieldWineClubSelectionBlock.entity.fieldWineClubSelectDetails.entity.fieldWineClubSelectTitle', 'fieldWineClubSelectionBlock.entity.fieldWineClubSelectDetails.entity.fieldWineClubSelectionDesc', 'fieldWineClubSelectionBlock.entity.fieldWineClubSelectDetails.entity.fieldWineClubSelectionYear', 'fieldWineClubSelectionBlock.entity.fieldWineClubSelectDetails.entity.fieldWineClubSelectionQuart', 'fieldWineClubSelectionBlock.entity.fieldWineClubSelectDetails.entity.fieldTastingNotesCtaLabel', 'fieldWineClubFaqBlock.entity.fieldFaqDescription', 'fieldWineClubFaqBlock.entity.fieldFaqTitle', 'fieldWineClubFaqBlock.entity.fieldFaqListItem.entity.fieldFaqItemAnswer', 'fieldWineClubFaqBlock.entity.fieldFaqListItem.entity.fieldFaqItemQuestion'],
      marketField: 'fieldWineClubCountry'
    },
    Drupal_NodeArticle: {
      name: 'Drupal_NodeArticle',
      label: 'Article',
      title: 'title',
      field: 'body.value',
      marketField: 'fieldStaticPageCountry'
    },
    Drupal_NodeCellarDoor: {
      name: 'Drupal_NodeCellarDoor',
      label: 'Cellar Door',
      title: 'fieldCellarDoorName',
      field: ['fieldCellarDoorDescription', 'fieldCellarDoorName', 'fieldCellarDoorSubtitle', 'title', 'fieldCellarDoorOurPlace.entity.fieldStandardTextBlock.entity.fieldStandardTitle', 'fieldCellarDoorOurPlace.entity.fieldStandardTextBlock.entity.fieldStandardDescription', 'fieldCellarDoorTastingExp.entity.fieldStandardContentBlock.entity.fieldStandardTitle', 'fieldCellarDoorTastingExp.entity.fieldStandardContentBlock.entity.fieldStandardSubtitle', 'fieldCellarDoorTastingExp.entity.fieldStandardContentBlock.entity.fieldStandardDescription', 'fieldCellarDoorRestaurant.entity.fieldStandardContentBlock.entity.fieldStandardTitle', 'fieldCellarDoorRestaurant.entity.fieldStandardContentBlock.entity.fieldStandardSubtitle', 'fieldCellarDoorRestaurant.entity.fieldStandardContentBlock.entity.fieldStandardDescription'],
      marketField: 'fieldCellarDoorCountry'
    },
    Drupal_NodeJournal: {
      name: 'Drupal_NodeJournal',
      label: 'Journal',
      title: 'title',
      field: 'fieldNewsBlockDescription',
      marketField: 'fieldJournalCountry'
    },
    Drupal_NodeEvents: {
      name: 'Drupal_NodeEvents',
      label: 'Event',
      title: 'fieldEventTitle',
      field: ['fieldEventDescription', 'fieldEventTitle'],
      marketField: 'fieldEventCountry'
    },
    Drupal_NodeExperience: {
      name: 'Drupal_NodeExperience',
      label: 'Experience',
      title: 'fieldExperienceName',
      field: ['fieldExperienceDescription', 'fieldExperienceName', 'fieldExperienceDetails.entity.fieldStandardContentBlock.entity.fieldStandardSubtitle', 'fieldExperienceDetails.entity.fieldStandardContentBlock.entity.fieldStandardDescription'],
      marketField: 'fieldExperienceCountry'
    },
    Drupal_NodeModularPage: {
      name: 'Drupal_NodeModularPage',
      label: 'Page',
      title: 'fieldNewsTitle',
      field: 'fieldNewsIntroduction',
      marketField: 'fieldModularPageMarkets'
    },
    Drupal_NodeNews: {
      name: 'Drupal_NodeNews',
      label: 'News',
      title: 'fieldNewsTitle',
      field: ['fieldNewsIntroduction', 'fieldNewsTitle', 'fieldNewsShortDescription', 'fieldNewsBodyLayer.entity.fieldSimpTxt2colTxtColumn1', 'fieldNewsBodyLayer.entity.fieldSimpTxt2colTxtColumn2', 'fieldNewsBodyLayer.entity.fieldSimpTxt1colSectContent', 'fieldNewsCategories.entity.name'],
      marketField: 'fieldEventMarkets'
    },
    Drupal_NodeOtherProducts: {
      name: 'Drupal_NodeOtherProducts',
      label: 'Product',
      title: 'fieldWineProductName',
      field: 'fieldWineProductDescription',
      marketField: 'fieldWineProductCountry'
    },
    Drupal_NodeWineProduct: {
      name: 'Drupal_NodeWineProduct',
      label: 'Product',
      title: 'fieldWineProductName',
      field: ['fieldWineProductDescription', 'title', 'fieldWineProductShortDesc', 'fieldWineProductTasteTitle', 'fieldWineProductTastesubtitle', 'fieldWineProductTasteDesc'],
      marketField: 'fieldWineProductCountry'
    },
    Drupal_NodeAboutUs: {
      name: 'Drupal_NodeAboutUs',
      label: 'Page',
      title: 'entityLabel',
      field: ['fieldHeroBlock.entity.fieldHeroDescription', 'fieldHeroBlock.entity.fieldHeroTitle', 'fieldHeroBlock.entity.fieldHeroSubtitle', 'fieldPhilosophyBlock.entity.fieldStandardContentBlock.entity.fieldStandardSubtitle', 'fieldPhilosophyBlock.entity.fieldStandardContentBlock.entity.fieldStandardDescription', 'fieldOriginsBlock.entity.fieldStandardTextBlock.entity.fieldStandardTitle', 'fieldOriginsBlock.entity.fieldStandardTextBlock.entity.fieldStandardSubtitle', 'fieldOriginsBlock.entity.fieldStandardTextBlock.entity.fieldStandardDescription', 'fieldAboutUsQuote.entity.fieldQuoteText', 'fieldAboutUsQuote.entity.fieldStandardQuoteSubtitle', 'fieldAboutUsVineyardsBlock.entity.fieldStandardTextBlock.entity.fieldStandardTitle', 'fieldAboutUsVineyardsBlock.entity.fieldStandardTextBlock.entity.fieldStandardSubtitle', 'fieldAboutUsVineyardsBlock.entity.fieldStandardTextBlock.entity.fieldStandardDescription', 'fieldOurPeopleScrollingTitle.entity.fieldScrollingText', 'fieldOurPeopleVideoBlock.entity.fieldWideVideoTextBlock.entity.fieldStandardTitle', 'fieldOurPeopleVideoBlock.entity.fieldWideVideoTextBlock.entity.fieldStandardSubtitle', 'fieldOurPeopleVideoBlock.entity.fieldWideVideoTextBlock.entity.fieldStandardDescription', 'fieldOurPeopleVideoBlock.entity.fieldWideVideoBlock.entity.fieldWideVideoTitle', 'fieldOurPeopleVideoBlock.entity.fieldWideVideoBlock.entity.fieldWideVideoDescription', 'fieldOurPeopleTeam.entity.fieldCarrouselFullItem.entity.fieldFullItemCarrouselTitle', 'fieldOurPeopleTeam.entity.fieldCarrouselFullItem.entity.fieldFullItemCarrouselStitle', 'fieldOurPeopleTeam.entity.fieldCarrouselFullItem.entity.fieldFullItemCarrouselDesc', 'fieldAboutSustainabilityBlock.entity.fieldStandardTextBlock.entity.fieldStandardTitle', 'fieldAboutSustainabilityBlock.entity.fieldStandardTextBlock.entity.fieldStandardSubtitle', 'fieldAboutSustainabilityBlock.entity.fieldStandardTextBlock.entity.fieldStandardDescription'],
      marketField: 'fieldCountry'
    }
  }

  static weight = ['Drupal_NodeWineProduct', 'Drupal_NodeOtherProducts', 'Drupal_NodeExperience', 'Drupal_NodeNews', 'Drupal_NodeEvents', 'Drupal_NodeAboutUs', 'Drupal_NodePage', 'Drupal_NodeArticle', 'Drupal_NodeJournal', 'Drupal_NodeModularPage', 'Drupal_NodeCellarDoor', 'Drupal_NodePodcasts']

  static extract (data) {
    const { entities, count } = data

    const res = {
      total: count,
      items: []
    }

    for (let i = 0; i < entities.length; i++) {
      const entity = entities[i]

      if (entity && !!entity.status) {
        if (!entity.entityUrl.path.includes('/node/')) {
          res.items.push({
            id: entity.entityId,
            url: entity.entityUrl.path,
            title: Search.getTitles(entity),
            type: Search.getTypes(entity),
            text: Search.getBody(entity),
            query: Search.getQuery(entity),
            markets: Search.getMarketsCode(entity),
            __typename: entity.__typename
          })
        }
      }
    }

    res.total = res.items.length

    return res
  }

  static getTypes (entity) {
    const type = entity.__typename

    if (Search.types[type]) {
      if (entity.fieldNewsCategories) {
        if (entity.fieldNewsCategories[0].entity.name === 'Recipes') {
          return entity.fieldNewsCategories[0].entity.name
        } else {
          return 'News'
        }
      } else {
        return Search.types[type].label
      }
    }
  }

  // static getType (entity) {
  //   const type = entity.__typename
  //   if (Search.types[type]) {
  //     return Search.types[type].label
  //   } else {
  //     return Search.types.Drupal_NodeArticle.label
  //   }
  // }

  static getTitles (entity) {
    const type = entity.__typename

    if (Search.types[type]) {
      return entity[Search.types[type].title]
    } else { return [] }
  }

  static getBody (entity) {
    const type = entity.__typename

    if (Search.types[type]) {
      const f = (typeof Search.types[type].field !== 'string') ? Search.types[type].field[0] : Search.types[type].field

      return Search.resolve(f, entity)
    } else {
      return ''
    }
  }

  static getQuery (entity) {
    const type = entity.__typename

    if (Search.types[type]) {
      return Search.resolve(Search.types[type].field, entity)
    } else {
      return ''
    }
  }

  static getMarketsCode (entity) {
    const type = entity.__typename

    if (Search.types[type]) { return entity[Search.types[type].marketField].map((v) => `${v.entity.langcode.value}-${v.entity.fieldCountryCode}`.toLowerCase()) } else { return [] }
  }

  static getTitles (entity) {
    const type = entity.__typename

    if (Search.types[type]) {
      return entity[Search.types[type].title]
    } else { return [] }
  }

  static resolve (f, e) {
    if (typeof f !== 'string') {
      return f.map(v => resolve(v, e)).join(' ')
    }

    return resolve(f, e)
  }

  static order (data, marketCode) {
    const weight = Search.weight

    return data
      .filter(a => a.markets.indexOf(marketCode) > -1)
      .sort((a, b) => weight.indexOf(a.__typename) - weight.indexOf(b.__typename))
  }
}

export default Search
