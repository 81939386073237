import React, { useRef, useState, useEffect, useCallback } from 'react'
import { Map, Marker, NavigationControl } from 'react-map-gl'
import distance from '@turf/distance'
import axios from 'axios'
import { useRecoilValue } from 'recoil'
import Geocoder from './Geocoder'
import StoresListItem from './StoresListItem'
import StoresListItemRetailer from './StoresListItemRetailer'
import { useLocation } from '@reach/router'
import { STORE_LOCATOR } from '@/utils/gtmEvents'
import { useWindowSize } from '@/hook'
import { customerManager } from '@/recoil/customer'
import { isMobileState } from '@/recoil/layout'
import { marketCodeManager, marketCodesListState } from '@/recoil/marketCode'
import 'mapbox-gl/dist/mapbox-gl.css'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import marker from '@/images/marker.svg'
import markerActive from '@/images/marker-active.svg'
import getStoreLocatorData from './query'
import { MAPBOX_TOKEN } from './config'
import mapStyle from './mapStyle.json'
import countryCodeIso from '@/utils/country-code-iso.json'
import isPOSInCurrentMarket from '@/utils/isPOSInCurrentMarket'
import { SwiperSlide } from 'swiper/react'

import statesHash from '@/config/states_hash.json'

import {
  Container,
  MarkerIcon,
  StoresListContainer,
  StoresList,
  Filters,
  Filter,
  FilterLabel,
  FilterCount,
  Title,
  MobileResultsContainer,
  MobileResultsList,
  SwiperContent
} from './style'

const FILTER_BOUTIQUES = 'boutiques'
const FILTER_ERETAILERS = 'eretailers'
const DRUPAL_PHYSICAL_POS_TID = 71
const DRUPAL_ERETAILERS_POS_TID = 126

const StoreLocator = ({ pageType = 'Others' }) => {
  const customer = useRecoilValue(customerManager)
  const data = getStoreLocatorData()
  const pointsOfSale = data.locations.nodeQuery.entities.filter(Boolean)
  const marketCode = useRecoilValue(marketCodeManager)
  const marketCodesList = useRecoilValue(marketCodesListState)

  const locations = pointsOfSale
    .filter((pos) => pos.queryFieldPosType.entities.find((cat) => cat.tid === DRUPAL_PHYSICAL_POS_TID))

  const eretailers = pointsOfSale
    .filter((pos) => customer?.countryCode === pos.fieldPosAddress?.countryCode)
    .filter((pos) => pos.queryFieldPosType.entities.find((cat) => cat.tid === DRUPAL_ERETAILERS_POS_TID))
    // .filter((pos) => isPOSInCurrentMarket(pos, marketCode, marketCodesList))
    .sort((x, y) => {
      return y.sticky - x.sticky || y.promote - x.promote
    }) // sticky true goes first

  const websiteLocation = useLocation()
  const mapRef = useRef()
  const listRef = useRef()
  const windowSize = useWindowSize()
  const [currentLocation, setCurrentLocation] = useState(null)
  const [listScroll, setListScroll] = useState(null)
  const [filter, setFilter] = useState(FILTER_BOUTIQUES)
  const [sortedLocations, setSortedLocations] = useState([])
  const isMobile = useRecoilValue(isMobileState)

  useEffect(() => {
    if (marketCode) {
      setSortedLocations(locations)
    }
  }, [marketCode])

  useEffect(() => {
    if (customer && sortedLocations) {
      centerMapOnUserCountry()
    }
  }, [customer, sortedLocations.length > 0])

  useEffect(() => {
    mapRef.current?.flyTo({
      center: [currentLocation?.fieldPosCoordinates.lng, currentLocation?.fieldPosCoordinates.lat],
      duration: 2000
    })
  }, [currentLocation])

  useEffect(() => {
    if (customer) {
      if (isMobile) {
        listRef.current.children[0].style.transform = listScroll
      } else {
        listRef.current.scrollTop = listScroll
      }
    }
  }, [listScroll])

  if (!customer) {
    return null
  }

  const centerMapOnUserCountry = async () => {
    if (customer?.country) {
      const response = await axios(`https://api.mapbox.com/geocoding/v5/mapbox.places/${customer.country}.json?access_token=${MAPBOX_TOKEN}&limit=10`)
      const entry = response.data.features.find((location) => location?.place_type.includes('country'))
      const center = entry?.center || [locations[0].fieldPosCoordinates?.lng || 0, locations[0].fieldPosCoordinates?.lat || 0]

      mapRef.current?.flyTo({
        center,
        duration: 2000,
        zoom: 4
      })

      if (sortedLocations.length > 0) { sortLocationsFromClosestToFarthest(center[0], center[1]) }
    }
  }

  const gtm = (action) => {
    window.dataLayer && window.dataLayer.push({
      event: STORE_LOCATOR,
      site_location: pageType,
      interaction_type: action
    })
  }

  const sortLocationsFromClosestToFarthest = (longitude, latitude) => {
    const newLocations = sortedLocations

    for (const location of newLocations) {
      location.distanceFromSelectedPlace = distance(
        [longitude, latitude],
        [location?.fieldPosCoordinates?.lng || 0, location?.fieldPosCoordinates?.lat || 0]
      )
    }

    newLocations.sort((a, b) => a.distanceFromSelectedPlace - b.distanceFromSelectedPlace)
    setSortedLocations([...newLocations])
  }

  const onSelectPlace = ({ center: [lng, lat] }) => {
    sortLocationsFromClosestToFarthest(lng, lat)
    mapRef.current?.flyTo({
      center: [lng, lat],
      duration: 2000
    })
  }

  const onClickMarker = (location) => {
    gtm('Click on Map')
    setCurrentLocation(location)
    const { fieldPosCoordinates: { lng, lat } } = location
    sortLocationsFromClosestToFarthest(lng, lat)
    setFilter(FILTER_BOUTIQUES)
  }

  const onDrag = ({ viewState: { longitude, latitude } }) => {
    sortLocationsFromClosestToFarthest(longitude, latitude)
  }

  const ResultsContainer = ({ children }) => (
    isMobile
      ? (
        <SwiperContent slidesPerView='auto' ref={ isMobile ? listRef : '' }>
          { children }
        </SwiperContent>
        )
      : (
        <StoresList ref={ isMobile ? '' : listRef }>
          { children }
        </StoresList>
        )
  )

  const renderResults = () => {
    return (
      <ResultsContainer>
        {filter === FILTER_BOUTIQUES && sortedLocations.map((location, i) => {
          const country = countryCodeIso.find((c) => c.iso === location?.fieldPosAddress.countryCode)
          return (isMobile
            ? <SwiperSlide key={ `store-swiper-item-${i}` }>
              <StoresListItem
                key={ `store-list-item-${i}` }
                onClick={ () => {
                  setListScroll(listRef.current.children[0].style.transform)
                  setCurrentLocation(location)
                  gtm('Click on Store')
                } }
                type={ location?.fieldPosType?.entity?.name }
                name={ location?.fieldPosName }
                area={ statesHash[location?.fieldPosAddress.administrativeArea] }
                details={ `${location?.fieldPosAddress.addressLine1}${location?.fieldPosAddress.addressLine2 || ''}` }
                city={ `${location?.fieldPosAddress.locality}` }
                country={ `${country?.name?.toUpperCase()}` }
                googlePlaceId={ location?.fieldPosGooglePlaceId }
                isActive={ currentLocation?.nid === location?.nid }
              />
              </SwiperSlide>
            : <StoresListItem
                key={ `store-list-item-${i}` }
                onClick={ () => {
                  setListScroll(listRef.current.scrollTop)
                  setCurrentLocation(location)
                  gtm('Click on Store')
                } }
                type={ location?.fieldPosType?.entity?.name }
                name={ location?.fieldPosName }
                area={ statesHash[location?.fieldPosAddress.administrativeArea] }
                details={ `${location?.fieldPosAddress.addressLine1}${location?.fieldPosAddress.addressLine2 || ''}` }
                city={ `${location?.fieldPosAddress.locality}` }
                country={ `${country?.name?.toUpperCase()}` }
                googlePlaceId={ location?.fieldPosGooglePlaceId }
                isActive={ currentLocation?.nid === location?.nid }
              />
          )
        })}

        {filter === FILTER_ERETAILERS && eretailers.map((eretailer, i) => {
          return (isMobile
            ? <SwiperSlide key={ `eretailer-swiper-item-${i}` }>
              <StoresListItemRetailer
                pageType={ pageType }
                key={ `store-list-item-${i}` }
                name={ eretailer.fieldPosName }
                url={ eretailer?.fieldPosExternalUrl?.uri }
              />
              </SwiperSlide>
            : <StoresListItemRetailer
                pageType={ pageType }
                key={ `store-list-item-${i}` }
                name={ eretailer.fieldPosName }
                url={ eretailer?.fieldPosExternalUrl?.uri }
              />
          )
        })}
      </ResultsContainer>
    )
  }

  return (
    <Container>
      <Map
        mapboxAccessToken={ MAPBOX_TOKEN }
        mapStyle={ mapStyle }
        ref={ mapRef }
        style={ { width: windowSize.width, height: windowSize.height * 0.8 } }
        doubleClickZoom={ false }
        boxZoom={ false }
        keyboard={ false }
        dragRotate={ false }
        scrollZoom={ false }
        touchPitch={ false }
        onLoad={ centerMapOnUserCountry }
        touchZoomRotate={ false }
        onDragEnd={ onDrag }
        padding={ {
          left: windowSize?.width / 4 || 0,
          top: 0,
          right: 0,
          bottom: 0
        } }
      >
        {locations.map((location, i) => (
          <Marker
            key={ `marker-${i}` }
            latitude={ location?.fieldPosCoordinates?.lat || 0 }
            longitude={ location?.fieldPosCoordinates?.lng || 0 }
            anchor='bottom'
            onClick={ () => onClickMarker(location) }
          >
            <MarkerIcon loading='lazy' src={ currentLocation?.nid === location?.nid ? markerActive : marker } />
          </Marker>
        ))}

        <NavigationControl
          position='bottom-right'
          showCompass={ false }
        />

      </Map>
      <StoresListContainer>
        <Title>Where to buy</Title>
        <Geocoder pageType={ pageType } onSelectPlace={ onSelectPlace } />
        <Filters>
          <Filter onClick={ () => setFilter(FILTER_BOUTIQUES) } active={ filter === FILTER_BOUTIQUES }>
            <FilterLabel>Boutiques</FilterLabel>
            <FilterCount>{ locations.length }</FilterCount>
          </Filter>
          <Filter onClick={ () => setFilter(FILTER_ERETAILERS) } active={ filter === FILTER_ERETAILERS }>
            <FilterLabel>E-retailers</FilterLabel>
            <FilterCount>{ eretailers.length }</FilterCount>
          </Filter>
        </Filters>
        {!isMobile && renderResults()}
      </StoresListContainer>
      {isMobile && renderResults()}
    </Container>
  )
}

export default StoreLocator
