import styled from '@emotion/styled'

import { mediaMax } from '@/styles/mixins'
import { Container as PopupBodyContainer } from '@/components/Popup/atoms/Body/style'

export const PopupWrapper = styled.div`
  ${PopupBodyContainer} {
    background-color: #fcfaf6;
    padding: 0;
  }
`

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`

export const Image = styled.img`
  width: 100%;
  display: flex;
  height: 100%;
  // position: absolute;
  // top: 0;
  // left: 0;
  object-fit: contain;
  max-height: 85vh;
  ${mediaMax.xs} {
    max-height: unset;
    height: auto;
    width: 100%;
  }
`
