import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useSetRecoilState} from 'recoil'

import { popupManager, popupDataManager } from '@/recoil/popup'
import { NEWSLETTER_POPUP_ID } from '@/components/Popup/config'

import EmailField from '@/components/Forms/Inputs/Email'

import NewslettterPopup from '@/components/Popup/NewsletterPopup'

import { useRef } from 'react'

import {
  Container,
  Content,
  Title,
  Text,
  InputWrapper,
  Button
} from './style'

const PreviewNewsletterForm = ({id, title, subtitle, labelRef, buttonContent}) => {
  const emailRef = useRef(null)
  const setPopupData = useSetRecoilState(popupDataManager)

  const handleSubmit = () => {
    setPopupData({
      email: emailRef.current.getValue(),
      origin: 'Footer'
    })
  }

  return (
    <Container>
      <Content id='footer'>
        <Title>{title}</Title>
        <Text>{subtitle}</Text>
      </Content>
      <InputWrapper>
        <EmailField
          id={id}
          ref={ emailRef }
          label={ labelRef }
          name='email'
        />
        <Button to='/newsletter' ctaTracking={ false } type='green-alt3' onClick={ () => handleSubmit() }>
        {/* <Button type='green-alt3' to={"https://www.google.com/url?q=https://cloud.email.cloudybay.com/capture-form?uid%3DS07JJbLBxg1cI8dGIKBu2PtxO7EMDcO4WtcmVtgUuRArgGESiqxFqIZutepWa&source=gmail-imap&ust=1655222418000000&usg=AOvVaw1xrGZlqmR70nEWxeleRr_v"} target="_blank"> */}
          { buttonContent }
        </Button>
      </InputWrapper>
    </Container>
  )
}

PreviewNewsletterForm.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  labelRef: PropTypes.string,
  buttonContent: PropTypes.string
}

export default PreviewNewsletterForm
