const resolveAssetURL = (url = '') => {
  if (typeof url !== 'string')
    return url

  if (!url || url.includes('static/')) {
    return url
  }

  return (process.env.GATSBY_DRUPAL_REMOTE_FILES === 'true' || !url) ? url : `/drupal${(new URL(url).pathname)}`
}

export default resolveAssetURL
