import styled from "@emotion/styled"

export const Container = styled.button`
    background: none;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 1.4rem;
`

export const Label = styled.p`
    font-size: 1.4rem;
    font-family: ${p => p.theme.fonts.sansSerif};
    color: ${p => p.theme.colors.black};
    text-transform: uppercase;
    letter-spacing: 0.1rem;
`
