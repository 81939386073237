import styled from '@emotion/styled'
import { mediaMax, styledScrollbar } from '@/styles/mixins'

import { Container as PopupBodyContainer } from '@/components/Popup/atoms/Body/style'
import { Container as PopupHeadContainer } from '@/components/Popup/atoms/Header/style'

export const PopupWrapper = styled.div`

${PopupBodyContainer} {
  padding: 0;
  align-items: center;
  overflow-y: auto;
  ${styledScrollbar()}
  background-color: ${({ theme }) => theme.colors.lightVanilla3};
  height: 60rem;
  ${mediaMax.xs}{
    height: 90vh;
  }
}
${PopupHeadContainer}{
  text-align: center;
  background-color: ${({ theme }) => theme.colors.lightVanilla3};
}
`
export const ContentContainer = styled.div`
  padding-top: 100px;  
  ${mediaMax.xs}{
    padding-top: 3rem;
    height: 100%;
  }
`
