import styled from "@emotion/styled";
import { mediaMax } from "@/styles/mixins";

export const Container = styled.div`
    display: none;
    ${mediaMax.xs} {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`

export const Text = styled.p`
    color: ${p => p.theme.colors.black};
    font-size: 1.2rem;
    font-family: ${p => p.theme.fonts.sansSerif};
`

export const Icons = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 3.6rem;
    padding-right: 3rem;
`
export const Icon = styled.a``