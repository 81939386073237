import styled from '@emotion/styled'
import Link from '@/components/LocalizedGatsbyLink'

import { mediaMax, mediaBetween } from '@/styles/mixins'

export const BaseContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 75px;
  ${mediaMax.sm} {
    margin-bottom: 0;
  }
`

export const LogoWrapper = styled(Link)`
  display: flex;
  align-items: center;
  margin-right: 4rem;
  justify-content: center;
  width: 200px;
  cursor: pointer;

  ${mediaMax.xs}{
    margin-right: 0;
  }
`

export const InformativeText = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  font-size: 1.4rem;

  ${mediaMax.sm} {
    font-size: 1.4rem;
  }

`

export const Copyright = styled(InformativeText)`
  white-space: nowrap;
`

export const Label = styled(InformativeText)`
  text-transform: uppercase;
  white-space: nowrap;
`

export const TextSeparator = styled(InformativeText)`
  margin: 0 .5rem;
`

export const ChangeLang = styled(InformativeText)`
  white-space: nowrap;
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
  font-size: 1.2rem;
`

export const LegalTerms = styled(Label)`
  font-size: 1.1rem;
  letter-spacing: 1px;
  font-weight: 500;
  white-space: pre-wrap;
  text-align: center;
  ${mediaMax.xs}{
    padding: 0px 10px;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const MidContentWrapper = styled(BaseContent)`
  flex-direction: column;

  ${LogoWrapper}{
    display: none;
  }

  ${mediaMax.sm} {
    order: 1;
    ${LogoWrapper}{
      display: block;
      margin-bottom: 6rem;
      width: 80%;
    }
  }
  ${mediaMax.xs}{
    width: 100%;
  }
`

export const BottomContentWrapper = styled(BaseContent)`
  margin-bottom: 0;
  border-top: 1px solid ${({ theme }) => theme.colors.white}20;
  padding-top: 1.7rem;

  ${mediaMax.sm} {
    border: none;
    order: 2;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 6rem;
    ${Copyright}, ${LegalTerms}, ${TextSeparator}{
      display: none;
    }
  }

  ${LogoWrapper} {
    display: block;

    ${mediaMax.sm} {
      display: none;
    }
  }
`

export const SocialsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 60px;

  ${mediaMax.sm} {
    flex-direction: column;
    margin-right: 0;
  }
`

export const IconsWrapper = styled.div`
  display: flex;

  ${mediaMax.sm} {
    display: flex;
    gap: 4rem;
    margin-bottom: var(--spacing-section);
  }
`

export const AdditionalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${mediaBetween('sm', 'lg')} {
    flex-direction: column;
  }
  
  ${Label} {
    ${mediaMax.sm} {
      display: none;
    }
  }
`

export const LabelLeft = styled(Label)`
  margin-right: 15px;

  ${mediaMax.sm} {
    margin-right: 0;
    margin-bottom: 2.6rem;
    font-size: 1.6rem;
  }
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;

  a > span {
    position: absolute;
    width: 1px;
    clip: rect(0 0 0 0);
    overflow: hidden;
    white-space: nowrap;
  }
`

export const TopContentWrapper = styled(BaseContent)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.white}20;
  padding-bottom: 3rem;
  justify-content: space-between;

  ${AdditionalWrapper}{
    display: none;
  }

  ${Label} {
    display: none;
  }

  ${mediaMax.sm} {
    order: 3;
    flex-direction: column;
    border-bottom: none;
    border-top: 1px solid ${({ theme }) => theme.colors.white}20;
    padding-top: var(--spacing-section);
    margin-top: var(--spacing-section);
    width: 100vw;

    ${LogoWrapper}{
      display: none;
    }

    ${AdditionalWrapper}{
      display: flex;
      flex-direction: column;
      gap: 2rem;
      border-top: 1px solid ${({ theme }) => theme.colors.white}20;
      padding-top: var(--spacing-section);
      width: 100vw;
    }

    ${Label} {
      font-size: 1.8rem;
      /* margin-bottom: 2rem; */
      display: block;
    }
  }
`

export const Container = styled.div`
  width: 100%;
  padding-bottom: 2rem;
  padding-top: 3rem;
  background-color: ${({ theme }) => theme.colors.lightGreen3};
  --spacing-section: 4.5rem;
  ${mediaMax.sm} {
    padding-top: var(--spacing-section);
    padding-bottom: 0;
  }
  
  &.smallFooter{
    padding-bottom: 1rem;
    padding-top: 1rem;

    ${TopContentWrapper}{
      margin-bottom: 0;
      border-bottom: none;
      padding-bottom: 0;
      ${AdditionalWrapper}{
        display: flex;
      }
      ${mediaMax.sm} {
        border-bottom: none;
        border-top: none;
        padding-top: 1rem;
      }
      ${mediaMax.xs} {
        padding-top: 3rem;
      }
    }

    ${AdditionalWrapper}{
      flex-direction:row;
      ${mediaMax.md}{
        flex-direction: column-reverse;
      }
      ${mediaMax.xs} {
        padding-bottom: 3rem;
      }
    }

    ${BaseContent}{
      margin-bottom: 0;
      ${mediaMax.sm}{
        flex-direction: row;
      }
    }

    ${LogoWrapper}{
      width: 150px;
    }

    ${TextSeparator}{
      ${mediaMax.sm} {
        display: none;
        margin: 0;
      }
    }
  }
`
