import styled from '@emotion/styled'

import { mediaMax, styledScrollbar } from '@/styles/mixins'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: center;
  position: relative;
  ${mediaMax.xs} {
    flex-direction: column;
  }
`

export const Image = styled.img`
  width: 42%;
  height: 100%;
  object-fit: cover;
  ${mediaMax.xs} {
    width: 100%;
  }
`

export const Wrapper = styled.div`
  width: 58%;
  height: 100%;
  padding-left: 8rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  ${mediaMax.xs} {
    width: 100%;
    padding-left: 0;
    margin-top: 4rem;
  }
`

export const Content = styled.div`
  width: 100%;
  height: calc(100% - 10rem);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  overflow-y: auto;
  ${styledScrollbar()}
  ${mediaMax.xs} {
    margin-bottom: 4rem;
  }
`

export const Text = styled.div`
  font-size: 1.6rem;
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  color: ${({ theme }) => theme.colors.grey};
  letter-spacing: .1rem;
  line-height: 3.2rem;
`
