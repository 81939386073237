/**
 * Search Recoil store
 */

import { atom } from 'recoil'

export const SEARCH_QUERY_KEY = 'searchQueryState'
export const SEARCH_RESULTS_STATE = 'searchResultsState'

export const searchQueryState = atom({
  key: SEARCH_QUERY_KEY,
  default: ''
})

export const searchResultsState = atom({
  key: SEARCH_RESULTS_STATE,
  default: []
})
