import ease from '@/utils/ease'
import { isTabletOrMobileDetect } from '@/utils/layout'

export default class BlobOverlay {
  constructor ({ endCbk, loopCbk, pointsLen = 3, duration = 2500, autoplay = false }) {
    this.pointsLen = pointsLen
    this.duration = duration
    this.path = null
    this.loopCbk = loopCbk
    this.endCbk = endCbk

    if (autoplay) {
      this.animate()
    }
  }

  animate () {
    this.timeStart = Date.now()
    this.renderLoop()
  }

  renderLoop () {
    this.path = this.updatePath(Date.now() - this.timeStart)
    if (this.loopCbk) { this.loopCbk(this.path) }
    if ((Date.now() - this.timeStart) < this.duration) {
      window.requestAnimationFrame(() => {
        this.renderLoop()
      })
    } else {
      if (this.endCbk) { this.endCbk() }
    }
  }

  updatePath (time) {
    const points = []
    const beziers = [(t) => ease.cubicOut(t), (t) => ease.cubicOut(t + 0.02)]

    for (let i = 0; i < this.pointsLen; i++) {
      const bezier = beziers[i % 2]
      if (isTabletOrMobileDetect()) {
        points[i] = (bezier(Math.min(Math.max(time, 0) / this.duration, 1))) * window.innerHeight
      } else {
        points[i] = (bezier(Math.min(Math.max(time, 0) / this.duration, 1))) * window.innerWidth
      }
    }

    let str

    if (isTabletOrMobileDetect()) {
      str = `M 0 ${window.innerHeight} V ${points[0]} `
    } else {
      str = `M 0 0 H ${points[0]} `
    }

    for (let i = 0; i < this.pointsLen - 1; i++) {
      if (isTabletOrMobileDetect()) {
        const p = ((i + 1) / (this.pointsLen - 1)) * window.innerWidth
        const cp = p - (1 / (this.pointsLen - 1) * (window.innerWidth)) / 2
        str += `C ${cp} ${points[i]} ${cp} ${points[i + 1]} ${p} ${points[i + 1]} `
      } else {
        const p = ((i + 1) / (this.pointsLen - 1)) * window.innerHeight
        const cp = p - (1 / (this.pointsLen - 1) * (window.innerHeight)) / 2
        str += `C ${points[i]} ${cp} ${points[i + 1]} ${cp} ${points[i + 1]} ${p} `
      }
    }

    if (isTabletOrMobileDetect()) {
      str += `H ${window.innerWidth} V ${window.innerHeight}`
    } else {
      str += `H ${window.innerWidth} V 0`
    }

    return str
  }
}
