import React from 'react'
import PropTypes from 'prop-types'

import { Bottom, Middle, Top, Container } from './style'

export default function Burger ({ size = 24, onClick = () => {}, isOpen = false }) {
  return (
    <Container size={ size } onClick={ onClick } isOpen={ isOpen }>
      <Top />
      <Middle />
      <Bottom />
    </Container>
  )
}

Burger.propTypes = {
  size: PropTypes.number,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func
}
