import styled from '@emotion/styled'
import { mediaMax } from '@/styles/mixins'

export const Container = styled.div`
    display: none;
    ${mediaMax.xs} {
        display: flex;
        flex-direction: column;
        gap: 3.6rem;
    }
`

export const Item = styled.div`
    display: flex;
    text-decoration: none;
    align-items: center;
    gap: 1.4rem;
`

export const Label = styled.p`
    font-size: 1.4rem;
    text-transform: uppercase;
    color: ${p => p.theme.colors.black};
    font-family: ${p => p.theme.fonts.sansSerif};
`
