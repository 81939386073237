import React from 'react'

const DateFormat = ({ dayComponent, monthComponent, yearComponent, dateFormat = 'DD/MM/YYYY' }) => {

    if (!dayComponent || !monthComponent || !yearComponent) return null
    if (dateFormat === 'MM/DD/YYYY') {
      return (
        <>
          {monthComponent}
          {dayComponent}
          {yearComponent}
        </>
      )
    } else if (dateFormat === 'DD/MM/YYYY') {
      return (
        <>
          {dayComponent}
          {monthComponent}
          {yearComponent}
        </>
      )
    } else {
      return null
    }
}

export default DateFormat