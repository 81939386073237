import * as React from 'react'
import { useRecoilValue } from 'recoil'
import PropTypes from 'prop-types'

import Popup from '@/components/Popup'
import { popupDataState } from '@/recoil/popup'
import NewsletterForm from '@/components/Forms/NewsletterForm'
import { NEWSLETTER_POPUP_ID } from '@/components/Popup/config'

import {
  ContentContainer,
  PopupWrapper
} from './style'

const NewsletterPopup = () => {
  const popupData = useRecoilValue(popupDataState)
  return (
    <PopupWrapper>
      <Popup
        id={ NEWSLETTER_POPUP_ID }
        title={ "Subscribe to cloudy bay's newsletter" }
      >
        <ContentContainer>
          <NewsletterForm email={ popupData?.email ? popupData.email : null } />
        </ContentContainer>
      </Popup>
    </PopupWrapper>
  )
}

NewsletterPopup.propTypes = {
  email: PropTypes.string
}

export default NewsletterPopup
