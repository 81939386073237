import { useIsFocused, useUpdateEffect } from '@/hook'
import cn from 'classnames'
import React, {
  forwardRef, useEffect, useImperativeHandle, useRef, useState
} from 'react'

import PropTypes from 'prop-types'

import { Container, Input, InputWrapper, Label, Wrapper } from './style'

const Email = forwardRef(
  (
    {
      id,
      label = '',
      isRequired = false,
      onChange = null,
      validator = null,
      name = '',
      placeholder = '',
      defaultValue = null,
      autocomplete = '',
      ...otherProps
    },
    ref
  ) => {
    // REF
    const inputRef = useRef(null)

    // // HOOK
    const [focus] = useIsFocused(inputRef)

    // LOCAL VALUE
    const [value, setValue] = useState('')
    const [error, setError] = useState(false)

    // ON COMPONENT UPDATE
    useUpdateEffect(() => {
      checkStatus()
      if (onChange) {
        onChange(value)
      }
    }, [value])

    // PRIVATE METHODS
    const handleChange = (newVal) => {
      setValue(newVal)
    }

    const checkStatus = () => {
      let err = false

      if (isRequired) {
        if (!value.length) {
          err = true
        }
        if (value.length && validator) {
          err = validator(value)
        }
      }

      setError(err)

      return err
    }

    const focusInput = () => {
      if (inputRef.current) inputRef.current.focus()
    }

    useEffect(() => {
      if (defaultValue) {
        setValue(defaultValue)
      }
    }, [defaultValue])

    // PUBLIC METHODS
    useImperativeHandle(ref, () => ({
      isRequired: () => isRequired,
      getName: () => name,
      getValue: () => value,
      hasError: () => error,
      triggerError: (e) => setError(e),
      isValid: () => !checkStatus()
    }))

    return (
      <Container>
        <Wrapper error={ error } className={ cn({ focus }) }>
          <InputWrapper onClick={ focusInput }>
            {/* {placeholder ? <Label htmlFor="email">{label}</Label> : null} */}
            <Label htmlFor={ id || label }>{label}</Label>
            <Input
              id={ id || label }
              type='email'
              title={ id || label }
              placeholder={ placeholder || label }
              value={ value }
              onChange={ (e) => handleChange(e.target.value) }
              ref={ inputRef }
              autoComplete={ autocomplete }
              { ...otherProps }
            />
          </InputWrapper>
        </Wrapper>
      </Container>
    )
  }
)

Email.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
  validator: PropTypes.func,
  name: PropTypes.string,
  autoComplete: PropTypes.string,
  defaultValue: PropTypes.string
}

export default Email
