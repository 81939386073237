import * as React from 'react'
import PropTypes from 'prop-types'

import resolveAssetURL from '@/utils/resolveAssetURL'

import Popup from '@/components/Popup'
import { TASTING_POPUP_ID } from '@/components/Popup/config'
import Button from '@/components/Button'
import formatText from '@/utils/formatText'

import {
  Container,
  Image,
  Wrapper,
  Content,
  Text
} from './style'

const TastingPopup = ({ title = '', subtitle = '', image, text, actionLabel = '', actionUrl = '' }) => {
  return (
    <Popup
      id={ TASTING_POPUP_ID }
      title={ title }
      subtitle={ subtitle }
    >
      <Container>
        <Image loading='lazy' src={ resolveAssetURL(image) } alt='wine' />
        <Wrapper>
          <Content>
            <Text dangerouslySetInnerHTML={ { __html: formatText(text) } } />
          </Content>
          {(actionLabel.length && actionUrl.length) && <Button to={ actionUrl } target='_blank'>{ actionLabel }</Button>}
        </Wrapper>
      </Container>
    </Popup>
  )
}

TastingPopup.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  actionLabel: PropTypes.string,
  actionUrl: PropTypes.string
}

export default TastingPopup
