import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

import { mediaMax } from '@/styles/mixins'

export const Container = styled.div`
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${props => props.theme.colors.white};
  width: 100%;
  height: 100%;
  &.no-js{
    display: none;
  }
`

export const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.white};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LogoBgWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${mediaMax.xs} {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 150%;
  }
`

const LogoBgWrapperEnter = keyframes`
  from {
    clip-path: inset(0 100% 0 0);
  }

  to {
    clip-path: inset(0 0 0 0);
  }
`

export const LogoWrapper = styled.div`
  width: 18.5%;
  // display: flex;
  // align-items: center;
  // justify-content: center;

  ${mediaMax.xs} {
    width: 50%;
  }
`

const LogoWrapperEnter = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

export const Before = styled.div`
  background-color: ${props => props.theme.colors.lightGreen};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  clip-path: ${props => props.path};

  ${LogoBgWrapper} {
    opacity: .03;
    clip-path: inset(0 100% 0 0);
    animation-name: ${LogoBgWrapperEnter};
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-delay: 1s;
    animation-fill-mode: forwards;
  }

  ${LogoWrapper} {
    opacity: 0;
    animation-name: ${LogoWrapperEnter};
    animation-duration: 1.05s;
    animation-timing-function: ease-in-out;
    animation-delay: 2s;
    animation-fill-mode: forwards;
  }
`

export const After = styled.div`
  background-color: ${props => props.theme.colors.lightVanilla};
  z-index: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${LogoBgWrapper} {
    opacity: .11;
  }
`
