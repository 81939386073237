import styled from '@emotion/styled'

import { mediaMax, styledScrollbar } from '@/styles/mixins'

export const Container = styled.div`
  width: 100%;
  height: calc(100% - 82px);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 54px 83px;
  ${mediaMax.xs} {
    padding: 3rem;
    overflow-y: auto;
    overflow-x: hidden;
    ${styledScrollbar()}
  }
`

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${mediaMax.xs} {
    height: fit-content;
  }
`
