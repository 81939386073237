import styled from '@emotion/styled'

import { mediaMax, mediaMin } from '@/styles/mixins'

import WysiwygBase from '@/components/Wysiwyg'
import ButtonBase from '@/components/Button'
import { ButtonLabel } from '@/components/Button/style'

import {
  Container as EmailContainer,
  Wrapper as EmailWrapper,
  InputWrapper as EmailInputWrapper,
  Input as EmailInput
} from '@/components/Forms/Inputs/Email/style'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  ${mediaMax.sm} {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

export const Content = styled.div`
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 5rem;

  ${mediaMax.md}{
    width: auto;
  }

  ${mediaMax.sm} {
    text-align: center;
    margin-right: 0;
    width: 100%;
    align-items: center;
  }
`

export const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.serif};
  font-size: 5.2rem;
  color: ${p => p.theme.colors.white };
  margin-bottom: 2rem;

  ${mediaMax.sm} {
    text-align: center;
    font-size: 4.5rem;
  }

  ${mediaMax.xs}{
    font-size: 3.8rem;
  }
`

export const Text = styled(WysiwygBase)`
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  max-width: 28vw;
  width: 32ch;
  font-size: 1.4rem;
 

  ${mediaMax.md}{
    width: auto;
  }

  ${mediaMin.xs}{
    color: ${p => p.theme.colors.white};
  }

  ${mediaMax.sm} {
    text-align: center;
    max-width: 100%;
    font-size: 1.4rem;
    color: ${p => p.theme.colors.white };
    line-height: 1.6em;
  }
`

export const Button = styled(ButtonBase)`
  width: auto;
  margin-top: 15px;
  margin-left: 4px;
  padding: 16px 30px;
  font-size: 1.1rem;
  &.green-alt3{
  border-color: ${p => p.theme.colors.white };

    ${ButtonLabel}{
      color: ${p => p.theme.colors.white };
    }
  }

  ${mediaMax.sm} {
    width: 100%;
    margin-top: 3rem;
    margin-left: 0;
  }
`

export const InputWrapper = styled.div`
  width: 40%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  ${mediaMax.sm} {
    width: 100%;
    margin-top: 5rem;
  }

  ${EmailContainer} {
    background-color: transparent;

    ${EmailWrapper} {
      border: none;
      border-bottom: 1px solid ${p => p.theme.colors.white };

      ${EmailInputWrapper} {
        ${EmailInput} {
          width: 100%;
          padding: 4px 4px 24px;
          text-transform: none;
          text-align: left;
          font-size: 1.8rem;
          letter-spacing: 1.2px;
          color: ${p => p.theme.colors.white };

          ${mediaMax.sm} {
            text-align: center;
            font-size: 1.6rem;
          }

          &::placeholder {
            color: ${p => p.theme.colors.white };
            text-transform: none;
            font-size: 1.6rem;
          }
        }
      }
    }
  }
`
