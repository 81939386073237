import PropTypes from 'prop-types'
import React from 'react'

import Icon from '@/components/Icon'

import * as S from './style'

export default function Socials (props) {
  const { 
    items = [],
    data = {}
  } = props

  return (
    <S.Container>
      <S.Text>{data?.fieldFooterSocialTitle}</S.Text>
      <S.Icons>
        {items.length > 0 && items.map(
          (item, i) => (
            <a key={ i } href={ item.to } title='social' target='_blank' rel='noreferrer'>
              <Icon name={ item.icon } size={ 24 } />
            </a>)
        )}
      </S.Icons>
    </S.Container>
  )
}

Socials.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      to: PropTypes.string
    })
  )
}
