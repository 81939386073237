export const genders = [
  'Mrs',
  'Mr',
  'Ms',
  'Miss',
  'MX',
  'Prefer not to say'
]

export const MDYCountries = [
  'USA'
]
