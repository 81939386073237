import styled from '@emotion/styled'
import { Swiper } from 'swiper/react'
import { mediaMax } from '@/styles/mixins'

import { Container as PopupBodyContainer } from '@/components/Popup/atoms/Body/style'
import { Wrapper as PopupContainer } from '@/components/Popup/style'

import 'swiper/css'
import 'swiper/css/virtual'

export const PopUpWrapper = styled.div`
  ${PopupBodyContainer}{
    padding: 0;
    height: fit-content;
  }
  ${PopupContainer}{
    height: fit-content;
  }
`
export const ImageWrapper = styled.div`
  width: 42%;
  height: 100%;
`

export const SwiperContainer = styled(Swiper)`
  width: 100%;
  height: 100%;

  .swiper-slide {
    width: 100%;
  }
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const Wrapper = styled.div`
  width: 58%;
  height: 100%;
  padding-left: 8rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
`

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  overflow-y: scroll;
`

export const Text = styled.div`
  font-size: 1.6rem;
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  color: ${({ theme }) => theme.colors.grey};
  letter-spacing: .1rem;
  line-height: 3.2rem;
`

export const MediaWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  ${mediaMax.xs} {
    /* flex: 1; */
  }
`

export const Thumbnail = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  left: 0;
  object-fit: cover;
  opacity: 1;
  transition: opacity ease-out .3s;
  cursor: pointer;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
`

export const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  pointer-events: none;
  opacity: 0;

  > *:not(.icon-pause) {
    position: relative;
    left: 2px;
  }
`

export const Source = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color:transparent;
`

export const Container = styled.div`
  width: 100%;
  height: fit-content;
  /* display: flex; */
  /* align-items: stretch;
  justify-content: center; */
  position: relative;

  &:hover ${IconWrapper} {
    opacity: 1;
  }
`
