import { useIsFocused, useUpdateEffect } from "@/hook";
import cn from "classnames";
import React, {
    forwardRef, useEffect, useImperativeHandle, useRef, useState
} from "react";

import PropTypes from "prop-types";

import { Container, Input, InputWrapper, Label, Wrapper } from "./style";

const Text = forwardRef(
  (
    {
      label = "",
      placeholder = "",
      isRequired = false,
      onChange = null,
      validator = null,
      name = "",
      type = "text",
      maxLength = null,
      ...otherProps
    },
    ref
  ) => {
    // REF
    const inputRef = useRef(null);

    // HOOK
    const [focus] = useIsFocused(inputRef);

    // LOCAL VALUE
    const [value, setValue] = useState("");
    const [error, setError] = useState(false);

    // ON COMPONENT UPDATE
    useUpdateEffect(() => {
      checkStatus();
      if (onChange) {
        onChange(value);
      }
    }, [value]);

    // PRIVATE METHODS
    const handleChange = (newVal) => {
      if (maxLength && newVal.length > maxLength) return;
      setValue(newVal);
    };

    const checkStatus = () => {
      let err = false;

      if (isRequired) {
        if (!value.length) {
          err = true;
        }
        if (value.length && validator) {
          err = validator(value);
        }
      }

      setError(err);

      return err;
    };

    const focusInput = () => {
      if (inputRef.current) inputRef.current.focus();
    };

    // PUBLIC METHODS
    useImperativeHandle(ref, () => ({
      isRequired: () => isRequired,
      getName: () => name,
      getValue: () => value,
      hasError: () => error,
      triggerError: (e) => setError(e),
      isValid: () => !checkStatus(),
    }));

    return (
      <Container>
        <Wrapper error={error} className={cn({ focus })}>
          <InputWrapper onClick={focusInput}>
            {placeholder ? <Label htmlFor={label}>{label}</Label> : null}
            <Input
              id={label}
              title={label}
              type={type}
              placeholder={placeholder ? placeholder : label}
              value={value}
              onChange={(e) => handleChange(e.target.value)}
              ref={inputRef}
              {...otherProps}
            />
          </InputWrapper>
        </Wrapper>
      </Container>
    );
  }
);

Text.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
  validator: PropTypes.func,
  name: PropTypes.string,
  maxLength: PropTypes.number,
  type: PropTypes.oneOf(["text", "number", "email"]),
};

export default Text;
