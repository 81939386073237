import styled from '@emotion/styled'
import 'swiper/css'
import 'swiper/css/virtual'
import WysiwygBase from '@/components/Wysiwyg'
import { mediaMax, styledScrollbar } from '@/styles/mixins'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: center;
  position: relative;
`

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  overflow-y: auto;
  ${styledScrollbar()}
`

export const Wysiwyg = styled(WysiwygBase)`
  br{
    display: none;
  }
  p {  
    color: ${({ theme }) => theme.colors.grey};
    ${mediaMax.xs} {
      width: 100%;
    }
  }
`
