import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { graphql, useStaticQuery, navigate } from 'gatsby'

import { useRecoilValue, useSetRecoilState } from 'recoil'
import { isMobileState, isTabletState } from '@/recoil/layout'
import { marketCodeManager } from '@/recoil/marketCode'
import { SOCIAL_FOLLOW, FOOTER_CLICK } from '@/utils/gtmEvents'
import { loadingState } from '@/recoil/loader'
import { hasValidAgeManager } from '@/recoil/customer'
import { popupDataManager, popupManager } from '@/recoil/popup'
import { useLocation } from '@reach/router'
import { NEWSLETTER_MSG_POPUP_ID } from '@/components/Popup/config'

import { useTheme } from '@emotion/react'

import { GridContainer } from '@/components/Grid'
import Nav from '@/components/Nav'
import PreviewNewsletterForm from '@/components/Forms/PreviewNewsletterForm'
import Logo from '@/components/Logo'
import Icon from '@/components/Icon'

import isExternalLink from '@/utils/isExternalLink'
import resolveAssetURL from '@/utils/resolveAssetURL'
import isPdf from '@/utils/isPdf'

import {
  Container,
  Wrapper,
  TopContentWrapper,
  MidContentWrapper,
  BottomContentWrapper,
  Copyright,
  LogoWrapper,
  AdditionalWrapper,
  SocialsWrapper,
  LabelLeft,
  IconWrapper,
  IconsWrapper,
  LegalTerms,
  TextSeparator,
  ChangeLang
} from './style'

// eslint-disable-next-line no-unused-vars
const linksList = [
  {
    label: 'Terms & conditions',
    title: 'Read more on our Terms and conditions page',
    to: '/terms-of-use',
    target: '_self'
  },
  {
    label: 'Privacy notice',
    title: 'Read more on our Terms and conditions page',
    to: '/privacy-cookie-notice',
    target: '_self'
  },
  {
    label: 'Our certifications',
    title: 'Read more on our Terms and conditions page',
    to: '/certifications',
    target: '_self'
  },
  {
    label: 'Contact',
    title: 'Read more on our Terms and conditions page',
    to: '/contact',
    target: '_self'
  }
]

const Footer = ({ smallFooter = false }) => {
  const marketCode = useRecoilValue(marketCodeManager)
  const { colors } = useTheme()
  const isTablet = useRecoilValue(isTabletState)
  const isMobile = useRecoilValue(isMobileState)
  const location = useLocation()
  const isLoading = useRecoilValue(loadingState)
  const ageGateValidated = useRecoilValue(hasValidAgeManager)
  const setPopup = useSetRecoilState(popupManager)
  const setPopupData = useSetRecoilState(popupDataManager)

  const gtmNav = (name) => {
    window.dataLayer && window.dataLayer.push({
      event: FOOTER_CLICK,
      cta_name: name
    })
  }
  const gtmFollow = (name) => {
    window.dataLayer && window.dataLayer.push({
      event: SOCIAL_FOLLOW,
      social_action: name.title
    })
  }

  const data = useStaticQuery(graphql`
  query MyQuery {
    drupal {
      nodeQuery(
        filter: {conditions: {field: "type", value: "footer", operator: EQUAL}}
        limit: 1000
      ) {
        entities {
          ... on Drupal_NodeFooter {
            nid
            fieldFooterNewsCtaLabel
            fieldFooterFacebookLink {
              url {
                path
              }
              title
            }
            fieldFooterInstagramLink {
              url {
                path
              }
              title
            }
            fieldFooterLegalLabels
            fieldFooterNavigationLinks {
              uri
              url {
                routed
                path
              }
              title
            }
            fieldFooterNewsPlaceholder
            fieldFooterNewsSubtitle
            fieldFooterNewsTitle
            fieldFooterSocialTitle
            fieldFooterMarkets {
              entity {
                ... on Drupal_TaxonomyTermCountry {
                  fieldCountryCode
                  langcode {
                    value
                  }
                }
              }
            }
            fieldFooterNewsCtaLabel
            fieldFooterYoutubeLink {
              url {
                routed
                path
              }
              title
            }
            fieldNewsletterPopup {
              entity {
                ... on Drupal_ParagraphPopupNewsletter {
                  fieldNewsletterButtonLabel
                  fieldNewsletterCookieTime
                  fieldNewsletterDisplayTime
                  fieldNewsletterInputLabel
                  fieldNewsletterInputPlacehold
                  fieldNewsletterStatus
                  fieldNewsletterContent
                  fieldNewsletterLabel
                  fieldNewsletterSubtitle
                }
              }
            }
          }
        }
      }
    }
  }

  `)

  const footer = data.drupal.nodeQuery.entities.find((footer) => {
    const footerMarketsCodes = footer.fieldFooterMarkets.map((market) =>
      `${market.entity.langcode.value}-${market.entity.fieldCountryCode}`.toLowerCase()
    )

    return footerMarketsCodes.includes(marketCode)
  })

  const socials = [
    { ...footer.fieldFooterFacebookLink, name: 'facebook' },
    { ...footer.fieldFooterInstagramLink, name: 'instagram' },
    { ...footer.fieldFooterYoutubeLink, name: 'youtube' }
  ]

  const changeCountry = _ => {
    localStorage.removeItem('customer')
    navigate(location.origin)
  }

  let footerLinks = [...footer.fieldFooterNavigationLinks] // eslint-disable-line prefer-const

  for (let i = 0; i < footerLinks.length; i++) {
    footerLinks[i].url.path = footerLinks[i].url.path.replace(`/${marketCode}`, '')

    if (isPdf(footerLinks[i].url.path) && isExternalLink(footerLinks[i].url.path)) {
      footerLinks[i].url.path = resolveAssetURL(footerLinks[i].url.path)
    }
  }

  // OPen newsletter popin & set cookie
  const newsletterMsgData = footer.fieldNewsletterPopup.entity
  const newsletterMsgCookieName = 'newsletterPopup'

  useEffect(() => {
    const checkPathname = (pathname) => ['wine-club', 'newsletter'].some(path => pathname.includes(path))

    if (!isLoading && ageGateValidated && newsletterMsgData?.fieldNewsletterStatus && document.cookie.indexOf(newsletterMsgCookieName) === -1) {
      if ((isMobile || isTablet) && checkPathname(location.pathname)) return

      setTimeout(_ => {
        setPopup(NEWSLETTER_MSG_POPUP_ID)
        setPopupData({ data: newsletterMsgData })
        const newsletterCookieDate = new Date()
        newsletterCookieDate.setTime(newsletterCookieDate.getTime() + (newsletterMsgData.fieldNewsletterCookieTime * 1000))
        document.cookie = `${newsletterMsgCookieName}=true; expires=${newsletterCookieDate.toUTCString()}; path=/`
      }, newsletterMsgData?.fieldNewsletterDisplayTime * 1000)
    }
  }, [isLoading, ageGateValidated, newsletterMsgData, location, isMobile, isTablet, setPopup, setPopupData])

  return (
    <Container className={ smallFooter ? 'smallFooter' : '' }>
      {/* <div id="ot-sdk-cookie-policy"></div> */}
      <GridContainer>
        <Wrapper>
          <TopContentWrapper>
            <LogoWrapper
              to='/'
              href='/'
              id='Go to the homepage'
              target='_self'
              name='Go to the homepage'
              aria-label='Go to the homepage'
              title='Cloudy Bay New Zealand Sauvignon Blanc, Chardonnay, Pinot Noir - Red and White wines - Marlborough Wine & Central Otago Wine Region'
              onClick={ () => { gtmNav('Home') } }
            >
              <Logo titleColor={ colors.white } subColor={ colors.white } />
            </LogoWrapper>
            { !smallFooter && <Nav links={ footerLinks } gtm={ gtmNav } />}
            { smallFooter && <Nav links={ [footerLinks[0], footerLinks[1]] } gtm={ gtmNav } />}

            { smallFooter && (
              <AdditionalWrapper>
                <Copyright>{footer.fieldFooterLegalLabels[0]}</Copyright>
                <TextSeparator>&nbsp; - &nbsp;</TextSeparator>
                <LegalTerms>{footer.fieldFooterLegalLabels[1]}</LegalTerms>
              </AdditionalWrapper>
            )}

            { !smallFooter && (
              <AdditionalWrapper>
                <LegalTerms>{footer.fieldFooterLegalLabels[1]}</LegalTerms>
                <Copyright>{footer.fieldFooterLegalLabels[0]}</Copyright>
              </AdditionalWrapper>
            )}
          </TopContentWrapper>

          { !smallFooter && (
            <MidContentWrapper>
              <LogoWrapper to='/' href='/' target='_self'>
                <Logo titleColor={ colors.white } subColor={ colors.white } />
              </LogoWrapper>
              <PreviewNewsletterForm
                id='footer_email'
                title={ footer.fieldFooterNewsTitle }
                subtitle={ footer.fieldFooterNewsSubtitle }
                labelRef={ footer.fieldFooterNewsPlaceholder }
                buttonContent={ footer.fieldFooterNewsCtaLabel }
              />
            </MidContentWrapper>
          )}

          { !smallFooter && (
            <BottomContentWrapper>
              <AdditionalWrapper>
                <SocialsWrapper>
                  <LabelLeft>{footer.fieldFooterSocialTitle}</LabelLeft>
                  <IconsWrapper>
                    {
                      socials.map((social, i) => (
                        <IconWrapper key={ social + i } onClick={ () => { gtmFollow(social) } }>
                          <Icon
                            name={ social.name.toLowerCase() }
                            title={ social.title.toLowerCase() }
                            size={ isMobile ? 26 : 20 }
                            color={ colors.white }
                            link={ social.url.path }
                          />
                        </IconWrapper>
                      ))
                    }
                  </IconsWrapper>
                </SocialsWrapper>
              </AdditionalWrapper>
              <AdditionalWrapper>
                <ChangeLang role='button' tabIndex={ 0 } onClick={ changeCountry } onKeyDown={ (e) => e.key === 'Enter' && changeCountry() }>Change your country</ChangeLang>
              </AdditionalWrapper>
              <AdditionalWrapper>
                <Copyright>{footer.fieldFooterLegalLabels[0]}</Copyright>
                <TextSeparator>&nbsp; - &nbsp;</TextSeparator>
                <LegalTerms>{footer.fieldFooterLegalLabels[1]}</LegalTerms>
              </AdditionalWrapper>
            </BottomContentWrapper>
          )}
        </Wrapper>
      </GridContainer>
    </Container>
  )
}

Footer.propTypes = {
  smallFooter: PropTypes.bool
}

export default Footer
