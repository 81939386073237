import React, { useState } from 'react'

import { useRecoilValue } from 'recoil'

import resolveAssetURL from '@/utils/resolveAssetURL'
import isPdf from '@/utils/isPdf'

import { popupDataState } from '@/recoil/popup'
import Button from '@/components/Button'

import { SUSTAINABILITY_POPUP_ID } from '@/components/Popup/config'

import Popup from '@/components/Popup'

import {
  Container,
  ImageWrapper,
  Image,
  Wrapper,
  Content,
  Text
} from './style'

const SustainabilityPopup = () => {
  const popupData = useRecoilValue(popupDataState)

  return (
    <Popup
      id={ SUSTAINABILITY_POPUP_ID }
      title={ `${popupData?.title}` }
    >
      <Container>
        <ImageWrapper>
          { popupData?.image && (<Image loading='lazy' src={ resolveAssetURL(popupData?.image.src) } alt={ popupData?.image.alt } />)}
        </ImageWrapper>
        <Wrapper>
          <Content>
            <Text>
              { popupData?.description }
            </Text>
            { popupData?.cta && (<Button download={ popupData.cta.download } target='_blank' to={ isPdf(popupData.cta.to) ? resolveAssetURL(popupData.cta.to) : popupData.cta.to }>{popupData.cta.label}</Button>)}
          </Content>
        </Wrapper>
      </Container>
    </Popup>
  )
}

export default SustainabilityPopup
