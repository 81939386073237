import React from 'react'
import PropTypes from 'prop-types'

import Icon from '@/components/Icon'

import * as S from './style'

export default function BackBtn ({ label = '', onClick = () => {} }) {
  return (
    <S.Container onClick={ onClick }>
      <Icon name='arrow-left' size={ 32 } />
      <S.Label>Back</S.Label>
    </S.Container>
  )
}

BackBtn.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func
}
