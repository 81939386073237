import styled from '@emotion/styled'
import Link from '@/components/LocalizedGatsbyLink'
import { mediaMax } from '@/styles/mixins'

export const Image = styled.img`
  position: absolute;
  height: 130%;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${mediaMax.sm} {
    height: 120%;
  }
`

export const Wrapper = styled.div`
  position: relative;
  width:  ${p => p.size === 'medium' ? '24vw' : '18vw'};
  height: ${p => p.size === 'medium' ? '24vw' : '18vw'};
  border-radius: 50%;
  background-color: ${({ colorBg }) => colorBg};
  display: flex;
  justify-content: center;
  align-items: center;

  &.medium{
    width: 24vw;
    height: 24vw;
    ${mediaMax.sm} {
      width:  52vw;
      height: 52vw;
    }
    ${Image}{
      height: 125%;
      top: 54%;
    }
  }
  &.small{
    width: 18vw;
    height: 18vw;
    ${Image}{
      height: 110%;
      top: 50%;
    }
  }
`

export const Art = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  object-fit: cover;
  opacity: 0;
  transition: opacity ease-out .4s;
  ${mediaMax.xs} {
      opacity: 1;
  }
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 6.5rem;

  ${mediaMax.sm} {
    margin-top: 3.5rem;
  }
`

export const Name = styled.h3`
  font-family: ${({ theme }) => theme.fonts.serif};
  font-size: 2.5rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.brown};

  &.hasPrice {
    margin-bottom: 1.5rem;
  }

  ${mediaMax.sm} {
    font-size: 2rem;
  }
`

export const Price = styled.span`
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.brown};

  ${mediaMax.sm} {
    font-size: 1.8rem;
  }
`

export const PriceValue = styled.span`
  font-family: ${({ theme }) => theme.fonts.serif};
`

export const Container = styled(Link)`
  margin-right: 4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-decoration: none;
  color: inherit;

  ${mediaMax.sm} {
    margin-right: 0;
  }

  &:hover {
    ${Art} {
      opacity: 1;
    }

  }
`
