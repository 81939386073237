import { useTheme } from '@emotion/react'
import React, { useRef, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { useUpdateEffect } from '@/hook'

import { isMobileState } from '@/recoil/layout'

import { Mousewheel } from 'swiper'
import { SwiperSlide } from 'swiper/react'

import Arrows from '@/components/Block/Sliders/atoms/Arrows'
import Icon from '@/components/Icon'

import { VIEW_SEARCH_RESULTS } from '@/utils/gtmEvents'

import { marketCodeState } from '@/recoil/marketCode'
import { searchQueryState, searchResultsState } from '@/recoil/search'
import { graphql, useStaticQuery } from 'gatsby'

import SearchCard from './Card'

import * as JsSearch from 'js-search'
import SearchParse from '../../../utils/Search.js'

import {
  ActionWrapper, Categories,
  Category, Container, SearchBar,
  SearchInput, SearchLabel, SearchResults,
  SearchResultsLength,
  SearchResultsList, StyledGridContainer, SwiperCat, SwiperContent
} from './style'

const Search = () => {
  const isMobile = useRecoilValue(isMobileState)
  const marketCode = useRecoilValue(marketCodeState)
  const theme = useTheme()
  const [filters, setFilters] = useState([])
  const [filter, setFilter] = useState(null)
  const [searchQuery, setSearchQuery] = useRecoilState(searchQueryState)
  const [searchResults, setSearchResults] = useRecoilState(searchResultsState)
  const debounceFnRef = useRef()

  const data = useStaticQuery(graphql`
    query SearchQuery {
      drupal {
        nodeQuery(
          limit: 1000,
          offset: 0,
          filter: {
            conjunction: OR,
            conditions: [
              {field: "field_search_engine_configuratio.entity.field_exclude_from_search_engine", value: "0", operator: EQUAL},
              {field: "field_search_engine_configuratio", operator: IS_NULL}
            ]
          }
        ) {
          count
          entities {
            entityId
            entityLabel
            entityUrl {
              path
            }
            __typename
            ... on Drupal_NodeArticle {
              title
              status
              fieldStaticPageCountry {
                entity {
                  ... on Drupal_TaxonomyTermCountry {
                    name
                    fieldCountryCode
                    langcode {
                      value
                    }
                  }
                }
              }
              body {
                value
              }
            }
            ... on Drupal_NodeCellarDoor {
              title
              status
              fieldCellarDoorCountry {
                entity {
                  ... on Drupal_TaxonomyTermCountry {
                    name
                    fieldCountryCode
                    langcode {
                      value
                    }
                  }
                }
              }
              fieldCellarDoorName
              fieldCellarDoorSubtitle
              fieldCellarDoorDescription
              title
              fieldCellarDoorTastingExp {
                entity {
                  ... on Drupal_ParagraphStandardContentAndImageCarro {
                    fieldStandardContentBlock {
                      entity {
                        ... on Drupal_ParagraphStandardContentBlock {
                          fieldStandardTitle
                          fieldStandardSubtitle
                          fieldStandardDescription
                        }
                      }
                    }
                  }
                }
              }
              fieldCellarDoorRestaurant {
                entity {
                  ... on Drupal_ParagraphStandardContentAndImageCarro {
                    fieldStandardContentBlock {
                      entity {
                        ... on Drupal_ParagraphStandardContentBlock {
                          fieldStandardTitle
                          fieldStandardSubtitle
                          fieldStandardDescription
                        }
                      }
                    }
                  }
                }
              }
              fieldCellarDoorOurPlace {
                entity {
                  ... on Drupal_ParagraphStandardContentAnd2imgBlock {
                    fieldStandardTextBlock {
                      entity {
                        ... on Drupal_ParagraphStandardContentBlock {
                          fieldStandardTitle
                          fieldStandardDescription
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on Drupal_NodeJournal {
              title
              status
              fieldNewsBlockDescription
              fieldJournalCountry {
                entity {
                  ... on Drupal_TaxonomyTermCountry {
                    name
                    fieldCountryCode
                    langcode {
                      value
                    }
                  }
                }
              }
            }
            ... on Drupal_NodeEvents {
              status
              fieldEventDescription
              fieldEventTitle
              fieldEventCountry {
                entity {
                  ... on Drupal_TaxonomyTermCountry {
                    name
                    fieldCountryCode
                    langcode {
                      value
                    }
                  }
                }
              }
            }
            ... on Drupal_NodeExperience {
              status
              fieldExperienceName
              fieldExperienceDescription
              fieldExperienceDetails {
                entity {
                  ... on Drupal_ParagraphStandardContentAndImageCarro {
                    fieldStandardContentBlock {
                      entity {
                        ... on Drupal_ParagraphStandardContentBlock {
                          fieldStandardSubtitle
                          fieldStandardDescription
                        }
                      }
                    }
                  }
                }
              }
              fieldExperienceCountry {
                entity {
                  ... on Drupal_TaxonomyTermCountry {
                    name
                    fieldCountryCode
                    langcode {
                      value
                    }
                  }
                }
              }
            }
            ... on Drupal_NodeModularPage {
              status
              fieldNewsIntroduction
              fieldNewsTitle
              fieldModularPageMarkets {
                entity {
                  ... on Drupal_TaxonomyTermCountry {
                    name
                    fieldCountryCode
                    langcode {
                      value
                    }
                  }
                }
              }
            }
            ... on Drupal_NodeNews {
              status
              fieldNewsTitle
              fieldNewsCategories {
                entity {
                  ... on Drupal_TaxonomyTermNewsCategories {
                    name
                  }
                }
              }
              fieldEventMarkets {
                entity {
                  ... on Drupal_TaxonomyTermCountry {
                    name
                    fieldCountryCode
                    langcode {
                      value
                    }
                  }
                }
              }
              title
              fieldNewsTitle
              fieldNewsShortDescription
              fieldNewsIntroduction
              fieldNewsBodyLayer {
                entity {
                  ... on Drupal_ParagraphSimpleText2Columns {
                    fieldSimpTxt2colTxtColumn1
                    fieldSimpTxt2colTxtColumn2
                  }
                  ... on Drupal_ParagraphSimpleText1Column {
                    fieldSimpTxt1colSectContent
                  }
                }
              }
            }
            ... on Drupal_NodeOtherProducts {
              status
              fieldWineProductName
              fieldWineProductShortDesc
              fieldWineProductDescription
              fieldWineProductCountry {
                entity {
                  ... on Drupal_TaxonomyTermCountry {
                    name
                    fieldCountryCode
                    langcode {
                      value
                    }
                  }
                }
              }
            }
            ... on Drupal_NodeAboutUs {
              status
              fieldHeroBlock {
                entity {
                  ... on Drupal_ParagraphHeroBlock {
                    fieldHeroTitle
                    fieldHeroSubtitle
                    fieldHeroDescription
                  }
                }
              }
              fieldPhilosophyBlock {
                entity {
                  ... on Drupal_ParagraphStandardContentAndImageCarro {
                    fieldStandardContentBlock {
                      entity {
                        ... on Drupal_ParagraphStandardContentBlock {
                          fieldStandardSubtitle
                          fieldStandardDescription
                        }
                      }
                    }
                  }
                }
              }
              fieldOriginsBlock {
                entity {
                  ... on Drupal_ParagraphStandardContentAnd2imgBlock {
                    fieldStandardTextBlock {
                      entity {
                        ... on Drupal_ParagraphStandardContentBlock {
                          fieldStandardTitle
                          fieldStandardSubtitle
                          fieldStandardDescription
                        }
                      }
                    }
                  }
                }
              }
              fieldAboutUsQuote {
                entity {
                  ... on Drupal_ParagraphStandardQuoteBlock {
                    fieldQuoteText
                    fieldStandardQuoteSubtitle
                  }
                }
              }
              fieldAboutUsVineyardsBlock {
                entity {
                  ... on Drupal_ParagraphStandardContentAnd2imgBlock {
                    fieldStandardTextBlock {
                      entity {
                        ... on Drupal_ParagraphStandardContentBlock {
                          fieldStandardTitle
                          fieldStandardSubtitle
                          fieldStandardDescription
                        }
                      }
                    }
                  }
                }
              }
              fieldOurPeopleScrollingTitle {
                entity {
                  ... on Drupal_ParagraphStandardSingleScrollingText {
                    fieldScrollingText
                  }
                }
              }
              fieldOurPeopleVideoBlock {
                entity {
                  ... on Drupal_ParagraphStandardContentWVideoBlock {
                    fieldWideVideoTextBlock {
                      entity {
                        ... on Drupal_ParagraphStandardContentBlock {
                          fieldStandardTitle
                          fieldStandardSubtitle
                          fieldStandardDescription
                        }
                      }
                    }
                    fieldWideVideoBlock {
                      entity {
                        ... on Drupal_ParagraphStandardWidescreenVideoBlock {
                          fieldWideVideoTitle
                          fieldWideVideoDescription
                        }
                      }
                    }
                  }
                }
              }
              fieldOurPeopleTeam {
                entity {
                  ... on Drupal_ParagraphStandardCarrouselFullItems {
                    fieldCarrouselFullItem {
                      entity {
                        ... on Drupal_ParagraphStandardFullItemCarrousel {
                          fieldFullItemCarrouselTitle
                          fieldFullItemCarrouselStitle
                          fieldFullItemCarrouselDesc
                        }
                      }
                    }
                  }
                }
              }
              fieldAboutSustainabilityBlock {
                entity {
                  ... on Drupal_ParagraphStandardContentAnd2imgBlock {
                    fieldStandardTextBlock {
                      entity {
                        ... on Drupal_ParagraphStandardContentBlock {
                          fieldStandardTitle
                          fieldStandardSubtitle
                          fieldStandardDescription
                        }
                      }
                    }
                  }
                }
              }
              fieldCountry {
                entity {
                  ... on Drupal_TaxonomyTermCountry {
                    name
                    fieldCountryCode
                    langcode {
                      value
                    }
                  }
                }
              }
            }
            ... on Drupal_NodeWineProduct {
              title
              status
              fieldWineProductShortDesc
              fieldWineProductName
              fieldWineProductDescription
              fieldWineProductTasteTitle
              fieldWineProductTastesubtitle
              fieldWineProductTasteDesc
              fieldWineProductCountry {
                entity {
                  ... on Drupal_TaxonomyTermCountry {
                    name
                    fieldCountryCode
                    langcode {
                      value
                    }
                  }
                }
              }
            }
            ... on Drupal_NodeWineClub {
              title
              status
              fieldWineClubCountry {
                entity {
                  ... on Drupal_TaxonomyTermCountry {
                    name
                    fieldCountryCode
                    langcode {
                      value
                    }
                  }
                }
              }
              title
              fieldWineClubMetaTags {
                entity {
                  ... on Drupal_ParagraphMetaTags {
                    fieldMetaTitle
                    fieldMetaDescription
                  }
                }
              }
              fieldWineClubQuote
              fieldWineClubBenefits {
                entity {
                  ... on Drupal_ParagraphWineClubBenefits {
                    fieldWineClubBenefitDesc
                    fieldWineClubBenefitSubtitle
                    fieldWineClubBenefitTitle
                    fieldWineClubBenefitsList {
                      entity {
                        ... on Drupal_ParagraphWineClubBenefitsList {
                          fieldWineClubBenefitCat
                          fieldWineClubBenefitCat
                          fieldWineClubBenefitList {
                            entity {
                              ... on Drupal_ParagraphWineClubBenefitItem {
                                fieldWineClubBenefitLabel
                                fieldStandardDescription
                                fieldWineClubBenefitImage {
                                  alt
                                  url
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              fieldWineClubHeroBlock {
                entity {
                  ... on Drupal_ParagraphHeroBlock {
                    fieldHeroTitle
                    fieldHeroSubtitle
                    fieldHeroDescription
                  }
                }
              }
              fieldWineClubIntroBlock {
                entity {
                  ... on Drupal_ParagraphWineClubIntro {
                    fieldWineClubIntroIntro
                    fieldWineClubIntroSubtitle
                    fieldWineClubIntroTitle
                  }
                }
              }
              fieldWineClubSelectionBlock {
                entity {
                  ... on Drupal_ParagraphWineClubSelectionList {
                    fieldWineClubSelectSubtitle
                    fieldWineClubSelectDetails {
                      entity {
                        ... on Drupal_ParagraphWineClubSelectionDetails {
                          fieldWineClubSelectTitle
                          fieldWineClubSelectionDesc
                          fieldWineClubSelectionYear
                          fieldWineClubSelectionQuart
                          fieldTastingNotesCtaLabel
                        }
                      }
                    }
                  }
                }
              }
              fieldWineClubFaqBlock {
                entity {
                  ... on Drupal_ParagraphStandardFaqList {
                    id
                    fieldFaqDescription
                    fieldFaqTitle
                    fieldFaqListItem {
                      entity {
                        ... on Drupal_ParagraphStandardFaqItem {
                          fieldFaqItemAnswer
                          fieldFaqItemQuestion
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    `)
  const formattedData = SearchParse.extract(data.drupal.nodeQuery)

  const handleFilter = (f) => {
    setFilter(f)
  }

  const handleChange = (query) => {
    setSearchQuery(query)
  }

  useUpdateEffect(() => {
    clearTimeout(debounceFnRef.current)

    debounceFnRef.current = setTimeout(() => {
      if (searchQuery.length > 2) {
        window.dataLayer && window.dataLayer.push({
          event: VIEW_SEARCH_RESULTS,
          kw55: searchQuery.toLowerCase(),
          cat55: 'Search layer'
        })
      }
    }, 1500)

    const search = new JsSearch.Search('id')

    search.addIndex('title')
    search.addIndex('query')
    search.addIndex('text')
    search.addDocuments(formattedData.items)

    const res = SearchParse.order(search.search(searchQuery), marketCode)

    setSearchResults(res)

    const types = []

    for (let i = 0; i < res.length; i++) {
      const item = res[i]

      if (!types.find(f => f === item.type)) {
        types.push(item.type)
      }
    }

    setFilters(types)

    return () => clearTimeout(debounceFnRef.current)
  }, [searchQuery])

  const getFilteredResults = () => {
    if (!filter) { return searchResults }

    return searchResults.filter((item) => item.type === filter)
  }

  return (
    <Container>
      <StyledGridContainer large>
        <SearchBar>
          <Icon
            name='search'
            size={ 20 }
            color={ theme.colors.grey }
          />
          <SearchLabel htmlFor='search'>Search.</SearchLabel>
          <SearchInput
            id='search'
            title='search'
            placeholder='Search...'
            value={ searchQuery }
            onChange={ (e) => handleChange(e.target.value) }
            autoCorrect='off'
            autoComplete='off'
          />
        </SearchBar>

        { !!(searchResults && !!searchResults.length && filters && filters.length) && (
          <Categories>
            {
              isMobile
                ? (
                  <>
                    <SwiperCat slidesPerView='auto'>
                      <SwiperSlide>
                        <Category active={ !filter } onClick={ () => handleFilter(null) }>All</Category>
                      </SwiperSlide>
                      { filters.map((f, k) => (
                        <SwiperSlide key={ `filter-${k}` }>
                          <Category active={ filter && filter === f } onClick={ () => handleFilter(f) }>{ f }</Category>
                        </SwiperSlide>
                      )) }
                    </SwiperCat>
                  </>
                  )
                : (
                  <>
                    <Category active={ !filter } onClick={ () => handleFilter(null) }>All</Category>
                    {
                      filters.map((f, k) => (
                        <Category active={ filter && filter === f } key={ k } onClick={ () => handleFilter(f) }>{ f }</Category>
                      ))
                    }
                  </>
                  )
            }
          </Categories>
        )}
      </StyledGridContainer>
      { (searchResults && !!searchResults.length) && (
        <SearchResults>
          <SearchResultsLength>
            { getFilteredResults().length } results
          </SearchResultsLength>
          { isMobile
            ? (
              <SearchResultsList>
                {
                getFilteredResults().slice(0, 20).map((v, k) => (
                  <SearchCard
                    key={ `event-${k}` }
                    title={ v.title }
                    type={ v.type }
                    text={ v.text }
                    url={ v.url }
                  />
                ))
              }
              </SearchResultsList>
              )
            : (
              <SwiperContent
                slidesPerView='auto'
                mousewheel
                modules={ [Mousewheel] }
              >
                {
                getFilteredResults().slice(0, 20).map((v, k) => (
                  <SwiperSlide key={ `event-${k}` }>
                    <SearchCard
                      title={ v.title }
                      type={ v.type }
                      text={ v.text }
                      url={ v.url }
                    />
                  </SwiperSlide>
                ))
              }
                <ActionWrapper>
                  <Arrows display='inverted' />
                </ActionWrapper>
              </SwiperContent>
              ) }
        </SearchResults>
      )}
    </Container>
  )
}

export default Search
