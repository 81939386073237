import { React, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { useRecoilValue, useSetRecoilState } from 'recoil'

import { popupState, popupManager, popupDataManager } from '@/recoil/popup'

import PopupHeader from '@/components/Popup/atoms/Header'
import PopupBody from '@/components/Popup/atoms/Body'

import { Transition } from 'react-transition-group'
import { ANIMATION_DURATION, NEWSLETTER_MSG_POPUP_ID } from '@/components/Popup/config'

import {
  Container,
  Wrapper
} from './style'

const Popup = ({ id, title = '', className = '', subtitle = '', fullScreen = false, children, fullMap = '', closeCallback = _ => {} }) => {
  const popupId = useRecoilValue(popupState)

  const containerRef = useRef(null)
  const wrapperRef = useRef(null)
  const setPopup = useSetRecoilState(popupManager)
  const setPopupData = useSetRecoilState(popupDataManager)

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside (event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target) && popupId === id && !event.target.closest('#onetrust-banner-sdk') && id !== NEWSLETTER_MSG_POPUP_ID) {
        setPopup('')
        setPopupData({
          origin: null,
          className: 'careers-popup'
        })
      }
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [setPopupData, id, popupId])

  const isNewsletterPopup = id === NEWSLETTER_MSG_POPUP_ID
  const isOpen = popupId === id

  return (
    <Transition
      in={ isOpen }
      timeout={ {
        appear: ANIMATION_DURATION,
        enter: ANIMATION_DURATION,
        exit: 0
      } }
    >
      {state => (
        <Container ref={ containerRef } className={ cn(state, { fullScreen }, { isNewsletterPopup }) }>
          <Wrapper className={ className } ref={ wrapperRef }>
            <PopupHeader
              title={ title }
              subtitle={ subtitle }
              fullMap={ fullMap }
              closeCallback={ closeCallback }
            />
            <PopupBody>
              { isOpen && children }
            </PopupBody>
          </Wrapper>
        </Container>
      )}
    </Transition>
  )
}

Popup.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  fullScreen: PropTypes.bool,
  className: PropTypes.string,
  fullMap: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  closeCallback: PropTypes.func
}

export default Popup
