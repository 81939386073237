/**
 * Loader Recoil store
 */

import { atom } from 'recoil'

/* LoadingState */
export const LOADER_LOADING_KEY = 'loadingState'

export const loadingState = atom({
  key: LOADER_LOADING_KEY,
  default: true
})
/* LoadingState */
