import styled from '@emotion/styled'

import { styledScrollbar, mediaMax } from '@/styles/mixins'
import { ANIMATION_DURATION } from '@/components/Popup/config'

import { Container as Body } from './atoms/Body/style'

export const Wrapper = styled.div`
  width: 80%;
  height: 80%;
  background-color: ${({ theme }) => theme.colors.white};
  display: ${({ className }) => className.includes('is-hidden') ? 'none' : 'flex'};
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  opacity: 0;
  transition: opacity ${ANIMATION_DURATION / 2}ms ease-out ${ANIMATION_DURATION / 2}ms;

  &.careers-popup{
    width: 40%;
    height: 70%;
    transition: all ${ANIMATION_DURATION / 2}ms ease;

    ${mediaMax.xs}{
      width: 100%;
    }
  }
  ${mediaMax.xs} {
    width: 100%;
    height: 100vh;
  }

`

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 998;
  background-color: ${({ theme }) => `${theme.colors.black}40`};
  opacity: 0;
  pointer-events: none;
  transition: opacity ${ANIMATION_DURATION / 2}ms ease-out;

  &.fullScreen{
    align-items: flex-start;
    ${Wrapper}{
      width: 100%;
      height: fit-content;
      ${Body}{
        padding: 0;
      }
    }
  }

  &.isNewsletterPopup {
    background-color: transparent;
    pointer-events: none !important;

    &.entered {
      ${Wrapper} {
        pointer-events: auto;
      }
    }
  }

  ${mediaMax.xs} {
    align-items: center;
    justify-content: center;
    overflow: auto;
    ${styledScrollbar()}
    ${Wrapper}{
      margin: auto;
    }
  }

  &.entering {
    opacity: 1;
    ${Wrapper} {
      opacity: 1;
    }
  }

  &.entered {
    opacity: 1;
    pointer-events: initial;
    ${Wrapper} {
      opacity: 1;
    }
  }

  &.exiting {
    opacity: 0;
    ${Wrapper} {
      opacity: 0;
    }
  }

  &.exited {
    opacity: 0;
    ${Wrapper} {
      opacity: 0;
    }
  }
`
