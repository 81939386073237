import styled from '@emotion/styled'
import { Swiper } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/virtual'

import WysiwygBase from '@/components/Wysiwyg'
import { Wrapper as WrapperBody } from '@/components/Popup/atoms/Body/style'
import { Container as ContainerBody } from '@/components/Popup/atoms/Body/style'
import { styledScrollbar, mediaMax } from '@/styles/mixins'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: center;
  position: relative;
  ${mediaMax.sm} {
    flex-direction: column;
    justify-content: flex-start;
    gap: 2rem;
    ${WrapperBody}{
      height: 100vh;
      align-items: center;
    }
  }
`

export const ImageWrapper = styled.div`
  width: 42%;
  height: 100%;
  ${mediaMax.sm} {
    width: 100%;
  }
`

export const SwiperContainer = styled(Swiper)`
  width: 100%;
  height: 100%;

  .swiper-slide {
    width: 100%;
  }
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const Wrapper = styled.div`
  width: 58%;
  height: 100%;
  padding-left: 8rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  ${mediaMax.sm} {
    width: 100%;
    padding-left: 0;
  }
`

export const EventInfos = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 2.8rem;
  ${mediaMax.sm} {
    margin-bottom: 2rem;
  }
`

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.green};
  width: 10rem;
  height: 11rem;
  flex-shrink: 0;
  text-align: center;
  ${mediaMax.xs}{
    width: 10rem;
    height: 11rem;
  }
`

export const Day = styled.div`
  font-family: ${({ theme }) => theme.fonts.serif};
  font-size: 3.5rem;
  line-height: 3.5rem;
  letter-spacing: -3px;
  color: ${({ theme }) => theme.colors.white};
  ${mediaMax.sm} {
    line-height: 4.5rem;
    font-size: 5.5rem;
  }
  ${mediaMax.xs}{
    line-height: 4rem;
    font-size: 4.5rem;
  }
`

export const Month = styled.div`
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
  letter-spacing: .1rem;
  text-align: center;
  ${mediaMax.sm} {
    font-size: 1.5rem;
  }
`

export const DateItem = styled.div`

`

export const DateContainer = styled.div`
  transform: ${({ small }) => small ? 'scale(0.8)' : 'none'};
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const DateSeparator = styled.div`
  font-size: 5rem;
  margin: 0.8rem 0 0.3rem 0;
  background-color: ${({ theme }) => theme.colors.white};
  height: 1px;
  width: 15px;
`

export const DetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding-left: 2.5rem;
`

export const Name = styled.div`
  font-family: ${({ theme }) => theme.fonts.serif};
  font-size: 2.4rem;
  line-height: 2.8rem;
  color: ${({ theme }) => theme.colors.green};
  margin-bottom: 1rem;
`

export const Hours = styled.div`
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  font-size: 1.2rem;
  line-height: 1.8rem;
  letter-spacing: .1rem;
  color: ${({ theme }) => theme.colors.green};
`

export const References = styled.div`
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  font-size: 1.2rem;
  line-height: 1.8rem;
  letter-spacing: .1rem;
  color: ${({ theme }) => theme.colors.green};
`

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  // overflow-y: auto;
`

export const Text = styled(WysiwygBase)`
  font-size: 1.2rem;
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  color: ${({ theme }) => theme.colors.grey};
  letter-spacing: .1rem;
  line-height: 2.2rem;
  padding-right: 1.5rem;
`
