import * as React from 'react'
import PropTypes from 'prop-types'

import { useSwiper } from 'swiper/react'
import { useRecoilValue } from 'recoil'

import { useState } from 'react'
import { useTheme } from '@emotion/react'
import { useMountEffect } from '@/hook'
import { isMobileState } from '@/recoil/layout'

import Icon from '@/components/Icon'

import {
  Container,
  Wrapper,
  LeftArrow,
  RightArrow
} from './style'

const Arrows = ({ display = 'regular' }) => {
  const swiper = useSwiper()
  const theme = useTheme()
  const [progress, setProgress] = useState(swiper.progress)
  const isMobile = useRecoilValue(isMobileState)

  useMountEffect(() => {
    setProgress(swiper.progress)
    swiper.on('progress', (s) => {
      setProgress(s.progress)
    })
  })

  const isPrevActive = () => {
    return progress !== 0
  }

  const isNextActive = () => {
    return progress !== 1
  }

  const isBothActive = () => {
    return isPrevActive() && isNextActive()
  }

  if (swiper && swiper.isEnd && swiper.isBeginning) {
    return false
  }

  return (
    <Container>
      <Wrapper className={ `${display}` }>
        <LeftArrow
          className={ `${isPrevActive() && 'prevActive'} ${isBothActive() && 'bothActive'} ` }
          onClick={ () => swiper.slidePrev() }
        >
          <Icon
            name='left-arrow-slider'
            size={ isMobile ? 80 : 150 }
            color={ theme.colors.green }
          />
        </LeftArrow>
        <RightArrow
          className={ `${isNextActive() && 'nextActive'} ${isBothActive() && 'bothActive'} ` }
          onClick={ () => swiper.slideNext() }
        >
          <Icon
            name='right-arrow-slider'
            size={ isMobile ? 80 : 150 }
            color={ theme.colors.green }
          />
        </RightArrow>
      </Wrapper>
    </Container>
  )
}

Arrows.propTypes = {
  display: PropTypes.oneOf(['regular', 'inverted'])
}

export default Arrows
