import React, { useRef, useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

import { useLocation } from '@reach/router'
import { NL_SUBSCRIPTION } from '@/utils/gtmEvents'

import LocalizedGatsbyLink from '@/components/LocalizedGatsbyLink'
import EmailField from '@/components/Forms/Inputs/Email'
import TextField from '@/components/Forms/Inputs/Text'
import SelectField from '@/components/Forms/Inputs/Select'
import CheckboxField from '@/components/Forms/Inputs/Checkbox'
import Link from '@/components/Link/'

import countriesData from '@/config/legal_age.json'
import statesData from '@/config/states_hash.json'
import statesDataCA from '@/config/states_hash_ca.json'

import { countryState } from '@/recoil/ageGate'

import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil'
import { popupManager, popupDataState, popupDataManager } from '@/recoil/popup'
import { marketCodeManager } from '@/recoil/marketCode'
import { customerManager } from '@/recoil/customer'

import Button from '@/components/Button'
import DateFormat from '@/components/Forms/Inputs/DateFormat'

import { genders, MDYCountries } from './config'

import { checkFormData } from '@/utils/forms'
import { getAge } from '@/utils/date'

import { useStaticQuery, graphql, Link as GatsbyLink } from 'gatsby'

import { MarketCodeContext } from '@/context/MarketContext'

import {
  Container,
  GridContainer,
  FormGroup,
  FormGroupLabel,
  GridInputWrapper,
  Checkboxes
} from '../ContactForm/style'

import {
  Consent,
  Mandatory,
  FormContainer,
  SuccessMessage,
  ErrorMessage,
  ErrorLabel
} from './style'

const TWO_OPTIN_COUNTRIES = ['Japan', 'Poland', 'Germany']

const NewsletterForm = ({ modular = false, email = null, source, type = 0, conditions = [], confirmationText = null }) => {
  const pageMarketCode = useContext(MarketCodeContext)
  const marketCode = useRecoilValue(marketCodeManager)
  const market = typeof window !== 'undefined' ? marketCode : pageMarketCode

  const queryData = useStaticQuery(graphql`
    query NewsletterFormQuery {
      drupal {
        webformById(webform_id: "newsletter_subscription") {
          title
          description
          elements {
            ... on Drupal_WebformElement {
              id
              type
            }
            ... on Drupal_WebformElementActions {
              submitLabel
              title
            }
            ... on Drupal_WebformElementTextBase {
              title
              defaultValue
              required {
                message
              }
              size
              minLength
              maxLength
              pattern {
                message
                rule
              }
              placeholder
              multiple {
                limit
                message
              }
            }
            ... on Drupal_WebformElementMarkup {
              markup
            }
            ... on Drupal_WebformElementTextarea {
              rows
            }
            ... on Drupal_WebformElementHidden {
              defaultValue
            }
            ... on Drupal_WebformElementDate {
              dateMin
              dateMax
              step
              defaultValue
              title
              required {
                message
              }
              multiple {
                limit
                message
              }
            }
            ... on Drupal_WebformElementOptionsBase {
              title
              defaultValue
              options {
                title
                value
              }
              required {
                message
              }
              multiple {
                limit
                message
              }
            }
            ... on Drupal_WebformElementSelect {
              emptyOption {
                title
                value
              }
            }
            ... on Drupal_WebformElementManagedFile {
              title
              fileExtensions
              required {
                message
              }
              multiple {
                limit
                message
              }
            }
            ... on Drupal_WebformElementTermSelect {
              title
              termOptions(depth: 1) {
                entityId
                entityLabel
              }
            }
            ... on Drupal_WebformElementComposite {
              elements {
                id
                type
              }
            }
            ... on Drupal_WebformElementNumber {
              required {
                message
              }
              min
              max
              size
              step
            }
          }
        }
      }
    }
  `)
  const formConfigData = queryData?.drupal.webformById.elements

  const emailFieldConfig = formConfigData.find(
    (field) => field.id === 'subscription_email_address'
  )
  const countryFieldConfig = formConfigData.find(
    (field) => field.id === 'locationofresidence'
  )
  const titleFieldConfig = formConfigData.find((field) => field.id === 'title')
  const firstnameFieldConfig = formConfigData.find(
    (field) => field.id === 'first_name'
  )
  const lastnameFieldConfig = formConfigData.find(
    (field) => field.id === 'last_name'
  )
  const birthdateFieldConfig = formConfigData.find(
    (field) => field.id === 'birth_date'
  )
  const mandatoryFieldWarning = formConfigData.find(
    (field) => field.id === 'mandatory_fields_warning'
  )
  const legalParagraphs = formConfigData.find(
    (field) => field.id === 'legal_paragraphs'
  )
  const mhEntitiesConsent = formConfigData.find(
    (field) => field.id === 'mh_entities_consent'
  )
  const mhConsentText = formConfigData.find(
    (field) => field.id === 'mh_consent_text'
  )
  // const mhGdpr = formConfigData.find((field) => field.id === 'mh_gdpr')
  const checkboxSocialMedia = formConfigData.find(
    (field) => field.id === 'social_media_consent'
  )
  const submitButton = formConfigData.find((field) => field.id === 'actions')

  const [error, setError] = useState(false)
  const titleRef = useRef(null)
  const emailRef = useRef(null)
  const firstNameRef = useRef(null)
  const lastNameRef = useRef(null)
  const countryRef = useRef(null)
  const stateRef = useRef(null)
  const birthDayRef = useRef(null)
  const birthMonthRef = useRef(null)
  const birthYearRef = useRef(null)
  const dataConsentRef = useRef(null)
  const dataSharingRef = useRef(null)
  const location = useLocation()
  const setPopupData = useSetRecoilState(popupDataManager)
  const [recoilCountryName, setRecoilCountryName] = useRecoilState(countryState)
  const [countryName, setCountryName] = useState(recoilCountryName)
  const [stateName, setStateName] = useState(null)
  const setPopup = useSetRecoilState(popupManager)
  const [message, setMessage] = useState('')
  const [isSend, setIsSend] = useState(false)
  const [isUsa, setIsUsa] = useState(false)
  const [isCanada, setIsCanada] = useState(false)
  const [isGerPolJap, setIsGerPolJap] = useState(false)
  const [stateList, setStateList] = useState({})
  const [displayState, setDisplayState] = useState(false)
  const popupData = useRecoilValue(popupDataState)
  const handleClick = () => {
    setPopup('')
  }

  const validateDate = (value, country) => {
    let valid = false
    const minAge = countriesData.countries.find(
      (c) => c.label === country
    ).legal_age

    if (minAge >= 0 && value.length === 10) {
      const age = getAge(value)
      valid = age >= minAge
    }

    return valid
  }

  useEffect(() => {
    setStateList(getStateData(countryName === 'USA', countryName === 'Canada'))
    setIsUsa(countryName === 'USA')
    setIsCanada(countryName === 'Canada')
    setIsGerPolJap(countryName === 'Germany' || countryName === 'Poland' || countryName === 'Japan')
  }, [countryName])

  const getStateData = (usa, canada) => {
    if (usa) { return statesData }

    if (canada) { return statesDataCA }

    return {}
  }

  const getFieldLabel = (field) =>
    `${field?.title}${field?.required ? '*' : ''}`
  const handleSubmit = async (e) => {
    e.preventDefault()

    setPopupData({
      email: null,
      origin: null
    })

    setError(false)
    let data = {
      webform_id: 'newsletter_subscription',
      subscription_origin: (type === 1) ? 'Sweepstake' : 'Newsletter Form',
      touchpoint: (type === 1) ? 'sweepstake' : 'newsletter',
      legal_age: true,
      source_detail: source || ''
    }

    const inputs = [
      emailRef,
      firstNameRef,
      lastNameRef,
      titleRef,
      countryRef,
      stateRef,
      birthDayRef,
      birthMonthRef,
      birthYearRef,
      dataSharingRef,
      dataConsentRef
    ]

    // if (TWO_OPTIN_COUNTRIES.includes(countryName)) {
    //   inputs.push(socialMediaRef)
    // }

    const formData = checkFormData(inputs)

    if (!formData.errors.length) {
      // No errors
      const {
        data: { day, month, year, locationofresidence, state }
      } = formData
      // check birthdate inputs format
      const dayFormat =
        day.length === 2 && parseInt(day) >= 1 && parseInt(day) <= 31

      const monthFormat =
        month.length === 2 && parseInt(month) >= 1 && parseInt(month) <= 12

      const yearFormat =
        year.length === 4 &&
        parseInt(year) >= 1920 &&
        parseInt(year) <= new Date().getFullYear()

      if (!dayFormat || !monthFormat || !yearFormat) {
        if (!dayFormat) { birthDayRef.current.triggerError(true) && setError(true) }
        if (!monthFormat) { birthMonthRef.current.triggerError(true) && setError(true) }
        if (!yearFormat) { birthYearRef.current.triggerError(true) && setError(true) }
        return
      }

      const birthdate = `${year}-${month}-${day}`

      const checkBirthDate = `${day}/${month}/${year}`
      const isDateValid = validateDate(checkBirthDate, locationofresidence)

      const countryCode = countriesData.countries.find(
        (c) => c.label === locationofresidence
      )?.countryCode

      const locationOfResidenceIso3 = countriesData.countries.find(item => item.countryCode === countryCode)?.countryCodeISO3

      if (!isDateValid) {
        birthDayRef.current.triggerError(true)
        birthMonthRef.current.triggerError(true)
        birthYearRef.current.triggerError(true)
        setError('age')
      } else {
        data = {
          ...data,
          location_of_residence_iso3: locationOfResidenceIso3,
          subscription_email_address: formData.data.subscription_email_address,
          locationofresidence: countryCode,
          state: (isUsa || isCanada)
            ? Object.entries(stateList).find(
              ([key, value]) => value === stateName
            )[0]
            : '',
          title: formData.data.title,
          market: countryCode,
          first_name: formData.data.first_name,
          last_name: formData.data.last_name,
          birthdate: birthdate,
          birthdate_format: birthdate,
          mh_has_account: false,
          mh_entities_consent: isUsa ? false : formData.data.data_consent,
          cb_newsletter_subscription: formData.data.data_consent
        }
        if (formData?.data?.social_media_consent !== undefined) {
          data = {
            ...data,
            social_media_consent: true
          }
        }

        axios.post(
          process.env.GATSBY_DRUPAL_FORM_SUBMISSION_ENDPOINT,
          data
        )
          .then(() => {
            setIsSend(true)
            window.dataLayer &&
            window.dataLayer.push({
              event: NL_SUBSCRIPTION,
              site_location: modular ? 'Modular' : 'Newsletter',
              nl_type: (type === 1) ? 'Sweepstake' : 'Newsletter Form'
            })
          })
          .catch((e) => {
            if (e.response.data.error.subscription_email_address) {
              emailRef.current.triggerError(true)
              setMessage('Email already used')
            }
            setError(true)
          })
      }
    } else {
      setError(true)
    }
  }

  const customer = useRecoilValue(customerManager)

  const onCountryChange = (value) => {
    // setRecoilCountryName(value)
    setCountryName(value)
    setError(false)
    setDisplayState(value === 'USA' || value === 'Canada')
  }

  const onStateChange = (value) => {
    setStateName(value)
    setError(false)
  }

  useEffect(() => {
    if (customer) {
      const userCountry = countriesData.countries.find((country) => country.countryCode === customer.countryCode)?.label

      if (userCountry) {
        onCountryChange(userCountry)
      }
    }
  }, [customer])

  return (
    <Container>
      <GridContainer>
        <FormContainer onSubmit={ handleSubmit } noValidate>
          <FormGroup>
            <FormGroupLabel>Personal Information</FormGroupLabel>
            <GridInputWrapper cols={ displayState ? '3' : '2' }>
              <EmailField
                ref={ emailRef }
                id='email'
                label={ getFieldLabel(emailFieldConfig) }
                placeholder={ popupData?.email ? popupData.email : 'Email' }
                isRequired={ !!emailFieldConfig?.required }
                onChange={ () => setError(false) }
                name={ emailFieldConfig?.id }
                defaultValue={ popupData?.email ? popupData?.email : email }
              />
              <SelectField
                ref={ countryRef }
                label={ getFieldLabel(countryFieldConfig) }
                placeholder={ countryFieldConfig?.emptyOption?.title }
                name={ countryFieldConfig?.id }
                list={ countriesData.countries.map((country) => country.label) }
                isRequired={ !!countryFieldConfig?.required }
                onChange={ onCountryChange }
                withDropDownIcon
                withQuickSearch
                separation
                visible
                defaultValue={ countriesData.countries.find((country) => country?.countryCode === customer?.countryCode)?.label }
              />
              {(displayState && (isUsa || isCanada)) && (
                <SelectField
                  ref={ stateRef }
                  label={ isCanada ? 'Select your Province' : 'State of residence*' }
                  placeholder={ isCanada ? 'Select your Province' : 'Select your State' }
                  name='state'
                  list={ Object.values(stateList) }
                  isRequired
                  onChange={ onStateChange }
                  withDropDownIcon
                />
              )}
            </GridInputWrapper>
          </FormGroup>
          <FormGroup>
            <GridInputWrapper cols='3' className='identity'>
              <SelectField
                ref={ titleRef }
                label={ getFieldLabel(titleFieldConfig) }
                placeholder={ titleFieldConfig?.emptyOption?.title }
                name={ titleFieldConfig?.id }
                // defaultValue={ genders[0] }
                list={ titleFieldConfig?.options?.map((option) => option.title) }
                isRequired
                onChange={ () => setError(false) }
                withDropDownIcon
                withQuickSearch
              />
              <TextField
                ref={ firstNameRef }
                name={ firstnameFieldConfig?.id }
                label={ getFieldLabel(firstnameFieldConfig) }
                placeholder={ firstnameFieldConfig?.placeholder }
                isRequired={ !!firstnameFieldConfig?.required }
              />
              <TextField
                ref={ lastNameRef }
                label={ getFieldLabel(lastnameFieldConfig) }
                name={ lastnameFieldConfig?.id }
                placeholder={ lastnameFieldConfig?.placeholder }
                isRequired={ !!lastnameFieldConfig?.required }
              />
            </GridInputWrapper>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>Birth Date</FormGroupLabel>
            <GridInputWrapper cols='3'>
              <DateFormat
                dateFormat={
                  MDYCountries.indexOf(countryName) !== -1 || displayState
                    ? 'MM/DD/YYYY'
                    : 'DD/MM/YYYY'
                }
                dayComponent={
                  <TextField
                    ref={ birthDayRef }
                    label='Day*'
                    placeholder='DD'
                    name='day'
                    type='number'
                    min='1'
                    max='31'
                    isRequired={ !!birthdateFieldConfig?.required }
                    maxLength={ 2 }
                  />
                }
                monthComponent={
                  <TextField
                    ref={ birthMonthRef }
                    label='Month*'
                    placeholder='MM'
                    name='month'
                    type='number'
                    min='1'
                    max='12'
                    maxLength={ 2 }
                    isRequired={ !!birthdateFieldConfig?.required }
                  />
                }
                yearComponent={
                  <TextField
                    ref={ birthYearRef }
                    label='Year*'
                    placeholder='YYYY'
                    name='year'
                    type='number'
                    min='1920'
                    max={ new Date().getFullYear() }
                    maxLength={ 4 }
                    isRequired={ !!birthdateFieldConfig?.required }
                  />
                }
              />
            </GridInputWrapper>
          </FormGroup>
          {error === 'age' && (
            <ErrorLabel>
              Unfortunately you cannot validate this form as you are not of
              legal drinking and purchasing age
            </ErrorLabel>
          )}
          <Mandatory>
            <p
              dangerouslySetInnerHTML={ {
                __html: mandatoryFieldWarning?.markup
              } }
            />
          </Mandatory>

          <FormGroup>
            <Checkboxes>
              {
                (type === 1) && (
                  <>
                    <CheckboxField
                      ref={ dataConsentRef }
                      name='data_consent'
                    >
                      <div dangerouslySetInnerHTML={ { __html: conditions[1]?.value?.replace(/\$market/g, market) } } />
                    </CheckboxField>
                    <Consent>
                      <div dangerouslySetInnerHTML={ { __html: conditions[0]?.value?.replace(/\$market/g, market) } } />
                    </Consent>
                  </>
                )
              }
              {
                (isUsa && type === 0) && (
                  <>
                    <CheckboxField
                      ref={ dataConsentRef }
                      name='data_consent'
                      isRequired
                    >
                      <Consent>
                        <p>
                          By checking this box and clicking “subscribe” I agree to receive email and other marketing communications from Cloudy Bay and the <a href='https://www.lvmh.com/houses/wines-spirits/' target='_blank' rel='noreferrer'>Moet Hennessy Entities</a>. I understand that information collected will be used as described here, the <a href='/en-us/privacy-cookies-notice/#notice-collection'>Notice at Collection</a> and our <a href='/en-us/privacy-cookies-notice/'>Privacy Policy</a>. *
                        </p>
                      </Consent>
                    </CheckboxField>
                  </>
                )
              }
              {
                (['Germany', 'Poland', 'Japan'].includes(countryName) && type === 0) && (
                  <>
                    <CheckboxField
                      ref={ dataConsentRef }
                      name='data_consent'
                      isRequired
                    >
                      <Consent>
                        <p>
                          Yes! I consent to be the first to receive personalized communications and be eligible to receive invitations to exclusive VIP events.
                          This may involve utilizing information from various sources, to create personalized experiences and offer relevant content.
                          In order to better serve my needs, I understand that <a href='/en-ww/privacy-cookies-notice'>my data</a> is processed, analyzed and shared with our parent company, <a href='https://www.lvmh.com/houses/' target='_blank' rel='noreferrer'>LVMH</a>, which includes Cloudy Bay plus other prestigious champagne, spirits, fashion, jewelry and hospitality brands.
                          My interaction with communication may be measured by automatic means. *
                        </p>
                      </Consent>
                    </CheckboxField>
                    <Consent>
                      <p>
                        You can withdraw your consent at any time by using the Unsubscribe mechanism provided within the content. For more information on your rights and the processing of your data, please consult our <a href='/en-ww/privacy-cookies-notice/#notice-collection'>Information note on the processing of personal data and cookies</a>.
                      </p>
                    </Consent>
                  </>
                )
              }
              {
                (!['Germany', 'Poland', 'Japan', 'USA'].includes(countryName) && type === 0) && (
                  <>
                    <CheckboxField
                      ref={ dataConsentRef }
                      name='data_consent'
                      isRequired
                    >
                      <Consent>
                        <p>
                          Yes! I consent to be the first to receive personalized communications and be eligible to receive invitations to exclusive VIP events.
                          In order to better serve my needs, I understand that <a href={ `/${countriesData.countries.find((c) => c.label === countryName)?.market}/privacy-cookies-notice/` }>my data</a> is processed, analyzed and shared with our parent company, <a href='https://www.lvmh.com/houses/' target='_blank' rel='noreferrer'>LVMH</a>, which includes Cloudy Bay plus other prestigious champagne, spirits, fashion, jewelry and hospitality brands.
                          My interaction with communication may be measured by automatic means. *
                        </p>
                      </Consent>
                    </CheckboxField>
                    <Consent>
                      <p>
                        You can withdraw your consent at any time by using the Unsubscribe mechanism provided within the content. For more information on your rights and the processing of your data, please consult our <a href={ `/${countriesData.countries.find((c) => c.label === countryName)?.market}/privacy-cookies-notice/#notice-collection` }>Information note on the processing of personal data and cookies</a>.
                      </p>
                    </Consent>
                  </>
                )
              }
            </Checkboxes>
          </FormGroup>

          {!isSend && (
            <Button ctaTracking={ false } type='green'>
              {submitButton.submitLabel}
            </Button>
          )}
          {isSend && (
            <SuccessMessage>
              {
                (confirmationText && confirmationText.length)
                  ? confirmationText
                  : 'Thank you, your subscription to the newsletter has been taken into account'
              }
            </SuccessMessage>
          )}
          {(error && message.length > 0) && (
            <ErrorMessage>
              { message }
            </ErrorMessage>
          )}
        </FormContainer>
      </GridContainer>
    </Container>
  )
}

NewsletterForm.propTypes = {
  email: PropTypes.string
  // origin: null,
}

export default NewsletterForm
