import styled from '@emotion/styled'
import WysiwygBase from '@/components/Wysiwyg'
import { mediaMax } from '@/styles/mixins'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightVanilla};
  position: relative;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.xsmall} 3rem;
  background-color: ${({ isActive, theme }) => isActive ? theme.colors.lightVanilla : 'transparent'};

  ${mediaMax.sm} {
    margin-left: 1rem;
    background-color: ${({ isActive, theme }) => isActive ? theme.colors.lightVanilla : theme.colors.lightVanilla3};
  }

  ${mediaMax.xs} {
    width: 80vw;
    margin-right: 2rem;
    margin-left: 0;
    height: 100%;
  }
`

export const Type = styled.p`
  color: ${({ theme }) => theme.colors.brown};
  text-transform: uppercase;
  font-size: 1rem;
`

export const Name = styled.p`
  color: ${({ theme }) => theme.colors.green};
  font-size: 1.8rem;
  margin: 0.8rem 0 0.8rem 0;
  font-family: ${({ theme }) => theme.fonts.serif};
`

export const Links = styled.div`
  display: flex;
  margin-top: 0.8rem;
`

export const Link = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.green};
`

export const LinkLabel = styled.span`
  text-transform: uppercase;
  margin-left: 0.5rem;
`

export const Details = styled(WysiwygBase)`
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  color: ${({ theme }) => theme.colors.brown};
  white-space: pre-line;
`

export const Distance = styled.span`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.green};
  position: absolute;
  bottom: ${({ theme }) => theme.spacing.xsmall};
  right: ${({ theme }) => theme.spacing.xsmall};
`
