export const formatText = (text) => text?.replace(/\n/g, '<br />')
export default formatText

export const truncate = (text, limit = 130) => {
  const index = text.indexOf(' ', limit)

  let data = ''

  if (index === -1) {
    data = text
  } else {
    data = text.substring(0, index)
    const br = data.indexOf('<br/>')

    if (br > -1) {
      data = data.substring(0, br)
    }

    data = `${data} ...`
  }

  return data
}
