import React from 'react'
import PropTypes from 'prop-types'

import resolveAssetURL from '@/utils/resolveAssetURL'

import Link from '@/components/LocalizedGatsbyLink'

import isExternalLink from '@/utils/isExternalLink'
import isPdf from '@/utils/isPdf'

import {
  Container,
  Image,
  TitleContainer,
  Subtitle,
  Title
} from './style'

const RoundedImageWithLabel = ({
  image,
  imageAlt,
  imageSize = 'normal',
  subtitle = '',
  title = '',
  to = '',
  target = '',
  onMouseOver = () => {},
  onMouseLeave = () => {},
  onClick = () => {}
}) => {
  return (
    <Container
      onMouseOver={ onMouseOver }
      onMouseLeave={ onMouseLeave }
      onClick={ onClick }
      { ...((to && (isExternalLink(to) || isPdf(to))) && { as: 'a', href: to, target: target }) }
      { ...((to && !isExternalLink(to) && !isPdf(to)) && { as: Link, to, target: target }) }
      title={
        subtitle === "wine finder" 
        ? "Try our wine finder to find your suitable wine"
        : `Discover ${title} cellar door and book an experience`
      }
    >
      <Image loading='lazy' src={ resolveAssetURL(image) } imageSize={ imageSize } alt={ imageAlt } />
      <TitleContainer>
        <Subtitle>{ subtitle }</Subtitle>
        <Title>{ title }</Title>
      </TitleContainer>
    </Container>
  )
}

RoundedImageWithLabel.propTypes = {
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  imageSize: PropTypes.oneOf(['small', 'normal']),
  subtitle: PropTypes.string,
  title: PropTypes.string,
  to: PropTypes.string,
  target: PropTypes.string,
  onMouseOver: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClick: PropTypes.func
}

export default RoundedImageWithLabel
