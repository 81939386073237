import * as React from 'react'
import PropTypes from 'prop-types'
import { useRecoilValue } from 'recoil'
import { popupDataState } from '@/recoil/popup'

import resolveAssetURL from '@/utils/resolveAssetURL'

import Icon from '@/components/Icon'

import { useSetRecoilState } from 'recoil'
import { popupManager } from '@/recoil/popup'
import { isMobileState } from '@/recoil/layout'
import { useTheme } from '@emotion/react'

import Popup from '@/components/Popup'
import { MAP_POPUP_ID } from '@/components/Popup/config'

import {
  PopupWrapper,
  Container,
  Image,
} from './style'

const MapPopup = () => {
  const popupData = useRecoilValue(popupDataState)

  return (
    <PopupWrapper>
      <Popup
        id={ MAP_POPUP_ID }
        title={ popupData?.title ? popupData.title : '' }
        subtitle={ popupData?.subtitle ? popupData.subtitle : '' }
        fullMap={popupData?.image?.url}
      >
        <Container>
          {popupData && popupData.image && <Image loading='lazy' src={ resolveAssetURL(popupData.image.url) } alt={ popupData.image.alt } />}
        </Container>
      </Popup>
    </PopupWrapper>
  )
}

MapPopup.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string
}

export default MapPopup
