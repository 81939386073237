import * as React from 'react'
import PropTypes from 'prop-types'

import { useSetRecoilState, useRecoilValue } from 'recoil'
import { popupManager, popupDataManager } from '@/recoil/popup'
import { isMobileState } from '@/recoil/layout'
import { useTheme } from '@emotion/react'

import Icon from '@/components/Icon'

import {
  Container,
  Wrapper,
  Title,
  SubTitle,
  Separator,
  Close,
  FullScreen
} from './style'

const Header = ({ title = '', subtitle = '', fullMap, closeCallback }) => {
  const { colors } = useTheme()
  const setPopup = useSetRecoilState(popupManager)
  const isMobile = useRecoilValue(isMobileState)

  const setPopupData = useSetRecoilState(popupDataManager)

  const handleClick = () => {
    setPopup('')
    setPopupData({
      origin: null
    })

    closeCallback()
  }

  return (
    <Container>
      <Wrapper>
        {fullMap && (
          <FullScreen href={ fullMap } target='_blank'>
            <Icon
              color={ colors.brown }
              size={ isMobile ? 16 : 20 }
              name='enlarge'
            />
          </FullScreen>
        )}
        { title && title.length > 0 && <Title>{ title }</Title> }
        { (title && subtitle && title.length > 0 && subtitle.length > 0) && <Separator>-</Separator> }
        { (subtitle && subtitle.length > 0) && <SubTitle>{ subtitle }</SubTitle> }
        <Close onClick={ handleClick }>
          <Icon
            color={ colors.brown }
            size={ isMobile ? 10 : 16 }
            name='cross'
          />
        </Close>
      </Wrapper>
    </Container>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  fullMap : PropTypes.string
}

export default Header
