import styled from '@emotion/styled'
import WysiwygBase from '@/components/Wysiwyg'
import { mediaMax } from '@/styles/mixins'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.xsmall} 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightVanilla};
  position: relative;
  padding: ${({ theme }) => theme.spacing.xsmall} 3rem;

  ${mediaMax.sm} {
    width: 80vw;
    margin-right: 2rem;
    background-color: ${({ isActive, theme }) => isActive ? theme.colors.lightVanilla : theme.colors.lightVanilla3};
  }

  ${mediaMax.xs} {
    height: 100%;
  }
`

export const Name = styled.p`
  color: ${({ theme }) => theme.colors.green};
  font-size: 1.8rem;
  margin: 0.8rem 0 1rem 0;
  font-family: ${({ theme }) => theme.fonts.serif};
`

export const Link = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.green};
`

export const LinkLabel = styled.span`
  text-transform: uppercase;
  margin-left: 0.5rem;
`

export const Image = styled.img`
  min-width: 33%;
  height: 42px;
  object-fit: contains;
  background: red;
`

export const ContainerHorizontal = styled(Container)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 50px;
  box-sizing: border-box;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;

  ${mediaMax.xs} {
    width: 95vw;
    padding-left: 3%;
    padding-right: 3%;
    margin-right: 0;
  }
  .productCardRetailersBtn {
    max-width: 33%;
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
  }

  ${Name} {
    font-family: ${({ theme }) => theme.fonts.sansSerif};
    text-transform: uppercase;
    font-size: 1.2rem;
    color: ${({ theme }) => theme.colors.black};
    min-width: 33%;
    margin: 0 5%;

    &.noMarginLeft {
      margin-left: 0;
    }
  }
`
