import styled from '@emotion/styled'
import { mediaMax, mediaBetween, sectionSubtitle, sectionTitle, styledScrollbar } from '@/styles/mixins'
import { Swiper } from 'swiper/react'

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.brown};
  min-height: 90vh;
  height: 90vh;
  position: relative;
  max-width: 100vw;
  overflow: hidden;

  .mapboxgl-ctrl-bottom-right {
    right: ${({ theme }) => theme.spacing.small};

    ${mediaMax.sm} {
      right: 1rem;
      bottom: 23rem;
    }
  }

  .mapboxgl-ctrl-attrib, .mapboxgl-ctrl-logo {
    display: none;
  }

  .mapboxgl-map {
    height: 100% !important;
  }
`

export const MarkerIcon = styled.img``

export const StoresListContainer = styled.div`
  position: absolute;
  height: 90%;
  bottom: 0;
  left: 7.5vw;
  width: 100%;
  max-width: 30vw;
  padding: ${({ theme }) => theme.spacing.small};
  padding-bottom: 0;
  z-index: 5;
  background: ${({ theme }) => theme.colors.white};

  ${mediaMax.lg} {
    max-width: 45vw;
  }

  ${mediaMax.sm} {
    width: calc(100vw - 4rem);
    height:90%;
    max-width: none;
    top: 2rem;
    bottom: auto;
    left: 2rem;
    padding: ${({ theme }) => theme.spacing.xsmall};
    padding-bottom: 0;
  }

  ${mediaMax.xs}{
    height: auto;
  }
`

export const StoresList = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 15.5rem);
  width: calc(100% + 6rem);
  margin-left: -3rem;
  overflow-y: scroll;
  ${styledScrollbar()}
  ${mediaBetween('sm', 'xs')}{
    width: calc(100% + 5rem);
  }
`

export const Filters = styled.div`
  display: flex;
  margin: ${({ theme }) => theme.spacing.xsmall} 0;
  cursor: pointer;

  ${mediaMax.sm} {
    margin-bottom: 0;
  }
`

export const Filter = styled.div`
  text-transform: uppercase;
  font-size: 1.2rem;
  margin-right: ${({ theme }) => theme.spacing.small};
  display: flex;
  padding: ${({ theme }) => theme.spacing.xxsmall} 0;
  color: ${({ theme, active }) => active ? theme.colors.green : theme.colors.brown};
  border-bottom: 1px solid ${({ theme, active }) => active ? theme.colors.green : theme.colors.lightVanilla};
`

export const FilterLabel = styled.span``

export const FilterCount = styled.span`
  font-size: .8rem;
  transform: translate(.5rem, -30%);
`

export const Title = styled.div`
  ${sectionTitle()}
  margin-bottom: 2.3rem;
  color: ${({ theme }) => theme.colors.brown};

  span {
    color: ${({ theme }) => theme.colors.green};
  }

  ${mediaMax.xs} {
    font-size: 3.2rem;
  }
`

export const MobileResultsContainer = styled.div`
  overflow-x: scroll;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 2rem;
  width: 100%;
  display: flex;
  ${styledScrollbar()}
`

export const MobileResultsList = styled.div`
  display: flex;
`

export const SwiperContent = styled(Swiper)`
  width: 100% !important;
  position: absolute !important;
  bottom: 0px !important;
  left: 0 !important;
  padding: 2rem !important;
  overflow: visible !important;

  .swiper-slide {
    height: 20rem !important;
  }
`
