import styled from '@emotion/styled'
import { GridContainer } from '@/components/Grid'
import { mediaMax } from '@/styles/mixins'

import LocalizedGatsbyLink from '@/components/LocalizedGatsbyLink'

export const Container = styled(LocalizedGatsbyLink)`
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
  height: 100%;
  padding: 3.2rem 2.5rem;
  overflow: hidden;
  position: relative;
  display: flex;
  text-decoration: none;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 5.2rem;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to top, ${({ theme }) => theme.colors.white}, transparent);
  }
`

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`

export const Title = styled.div`
  font-size: 1.6rem;
  font-family: ${({ theme }) => theme.fonts.serif};
  color: ${({ theme }) => theme.colors.black};
  font-weight: bold;
  margin-bottom: 1.2rem;
`

export const Type = styled.div`
  font-size: 1.2rem;
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  color: ${({ theme }) => theme.colors.lightGreen};
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: bold;
`

export const Text = styled.div`
  font-size: 1.2rem;
  line-height: 2.4rem;
  margin-top: 2rem;
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  color: ${({ theme }) => theme.colors.grey};
`
