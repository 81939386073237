import * as React from 'react'
import { useRecoilValue } from 'recoil'
import moment from 'moment'
import { popupState, popupDataState } from '@/recoil/popup'
import { EVENT_POPUP_ID } from '@/components/Popup/config'

import resolveAssetURL from '@/utils/resolveAssetURL'

import Popup from '@/components/Popup'
import { SwiperSlide } from 'swiper/react'

// import { formatDay } from '@/utils/date'

import {
  Container,
  ImageWrapper,
  SwiperContainer,
  Image,
  Wrapper,
  Content,
  EventInfos,
  DateWrapper,
  DateContainer,
  DateItem,
  DateSeparator,
  Day,
  Month,
  DetailsWrapper,
  Name,
  Hours,
  References,
  Text
} from './style'

const EventPopup = () => {
  const popupData = useRecoilValue(popupDataState)
  const popupId = useRecoilValue(popupState)

  return (
    <Popup
      id={ EVENT_POPUP_ID }
      title={ `${moment(popupData?.startDate).format('DD MMMM')} - ${moment(popupData?.endDate).format('DD MMMM')}` }
      subtitle={ `${popupData?.name}` }
    >
      <Container>
        <ImageWrapper>
          <SwiperContainer
            slidesPerView='auto'
          >
            {
              (popupData?.images?.length) && (
                popupData.images.map((image, i) => (
                  <SwiperSlide key={ i }>
                    <Image loading='lazy' src={ resolveAssetURL(image) } alt='event' />
                  </SwiperSlide>
                ))
              )
            }
          </SwiperContainer>
        </ImageWrapper>
        <Wrapper>
          {
            (!!popupData && popupId === EVENT_POPUP_ID) && (
              <EventInfos>
                <DateWrapper>
                  <DateContainer small={ !!popupData?.endDate }>
                    <DateItem>
                      <Day>
                        { moment(popupData?.startDate).format('DD') }
                      </Day>
                      <Month>
                        {moment(popupData?.startDate).format('MMMM') }
                      </Month>
                    </DateItem>
                    {popupData?.endDate && (
                      <>
                        <DateSeparator />
                        <DateItem>
                          <Day>
                            { moment(popupData?.endDate).format('DD') }
                          </Day>
                          <Month>
                            {moment(popupData?.endDate).format('MMMM') }
                          </Month>
                        </DateItem>
                      </>
                    )}
                  </DateContainer>
                </DateWrapper>
                <DetailsWrapper>
                  <Name>{ popupData?.name }</Name>
                  <Hours>{ popupData?.hours }</Hours>
                  <References>@{ popupData?.ref } - { popupData?.country }</References>
                </DetailsWrapper>
              </EventInfos>
            )
          }
          <Content>
            <Text>
              { popupData?.content }
            </Text>
          </Content>
        </Wrapper>
      </Container>
    </Popup>
  )
}

export default EventPopup
