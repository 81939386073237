import * as React from 'react'
import styled from '@emotion/styled'
import { mediaMax, mediaMin } from '@/styles/mixins'

export const StyledGridContainer = styled.div`
  width: 100%;
  max-width: ${({ large }) => large ? '130rem' : '120rem'};
  margin: 0 auto;
  padding: 0 15px;

  ${mediaMin.xl} {
    max-width: 85vw;
  }

  ${mediaMax.md} {
    padding: ${p => p.theme.paddingX.t};
  }

  ${mediaMax.xs} {
    padding: 0 ${p => p.theme.paddingX.m};
  }
`

const StyledGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  grid-gap: ${(props) => props.gap || props.theme.spacing.xlarge};
  align-items: ${(props) => props.alignItems};
`

export const GridContainer = ({
  className,
  large = false,
  children
}) => (
  <StyledGridContainer className={ className } large={ large }>{children}</StyledGridContainer>
)

export const Grid = ({
  className,
  children,
  columns = 2,
  alignItems = 'flex-start',
  gap
}) => (
  <StyledGrid
    className={ className }
    columns={ columns }
    gap={ gap }
    alignItems={ alignItems }
  >
    {children}
  </StyledGrid>
)

export default Grid
