import * as React from 'react'

import { useRecoilValue } from 'recoil'

import resolveAssetURL from '@/utils/resolveAssetURL'

import { popupDataState } from '@/recoil/popup'
import { TEAM_POPUP_ID } from '@/components/Popup/config'

import Popup from '@/components/Popup'

import {
  Container,
  ImageWrapper,
  Image,
  Wrapper,
  Content,
  Text
} from './style'

const TeamPopup = () => {
  const popupData = useRecoilValue(popupDataState)

  return (
    <Popup
      id={ TEAM_POPUP_ID }
      title={ `${popupData?.title}` }
      subtitle={ `${popupData?.subtitle}` }
    >
      <Container>
        <ImageWrapper>
          {
            popupData?.image && <Image loading='lazy' src={ resolveAssetURL(popupData?.image) } alt='Crew' />
          }
        </ImageWrapper>
        <Wrapper>
          <Content>
            <Text>
              { popupData?.description }
            </Text>
          </Content>
        </Wrapper>
      </Container>
    </Popup>
  )
}

export default TeamPopup
