import styled from '@emotion/styled'
import AsyncSelect from 'react-select/async'

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const SearchInput = styled(AsyncSelect)`
  flex-grow: 1;
`

export const GeolocationInput = styled.div`
  @keyframes flickerAnimation {
    0%   { opacity: 1; }
    50%  { opacity: 0.2; }
    100% { opacity: 1; }
  }

  margin-left: ${({ theme }) => theme.spacing.xxsmall};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  animation: 1.5s flickerAnimation infinite;
  animation: ${({ isLoading }) => isLoading ? '1.5s flickerAnimation infinite' : 'none'};
  cursor: pointer;
`
