import * as React from 'react'
import PropTypes from 'prop-types'
import formatText from '@/utils/formatText'
import { Content } from './style'

const Wysiwyg = (props) => {
  const { children, html = '', className = '', alt = false } = props

  return (
    <Content className={ `${className} ${alt && 'alt'}` } dangerouslySetInnerHTML={ { __html: `<p>${formatText(html || children)}</p>` } } />
  )
}

Wysiwyg.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  biggerText: PropTypes.bool
}

export default Wysiwyg
