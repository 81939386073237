import PropTypes from 'prop-types'

export const wineType = PropTypes.shape({
  img: PropTypes.string,
  art: PropTypes.string,
  name: PropTypes.string,
  colorBg: PropTypes.string,
  colorCta: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string
})

export const tasteType = PropTypes.shape({
  min: PropTypes.string.isRequired,
  max: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired
})

export const tagType = PropTypes.shape({
  name: PropTypes.string.isRequired
})
