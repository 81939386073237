import * as React from 'react'
import PropTypes from 'prop-types'

import {
  LinkWrapper,
  LinkLabel,
  LinkContent
} from './style'

import { useLocation } from '@reach/router'
import LocalizedGatsbyLink from '@/components/LocalizedGatsbyLink'
import isExternalLink from '@/utils/isExternalLink'
import { CLICK_ON_CTA } from '@/utils/gtmEvents'

const Link = (props) => {
  const {
    to = '',
    onClick = () => {},
    onKeyDown = () => {},
    target = '_self',
    children
  } = props

  const location = useLocation()

  const handleClick = (e) => {
    window.dataLayer && window.dataLayer.push({
      event: CLICK_ON_CTA,
      site_location: location.pathname.split('/').splice(2)[0] === '' ? 'Home' : location.pathname.split('/').splice(2)[0].replace('-', ' '),
      cta_name: children
    })
    onClick(e)
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      onKeyDown(e)
    }
  }


  return (
    <LinkWrapper
      { ...props }
      { ...((to && isExternalLink(to)) && { as: 'a', href: to, target: target, onClick: handleClick, onKeyDown: handleKeyPress }) }
      { ...((to && !isExternalLink(to)) && { as: LocalizedGatsbyLink, to, target: target, onClick: handleClick, onKeyDown: handleKeyPress}) }
      { ...(!to.length && { onClick: handleClick, onKeyDown: handleKeyPress }) }
    >
      <LinkContent>
        <LinkLabel role="button" tabIndex={0}>
          { children }
        </LinkLabel>
      </LinkContent>
    </LinkWrapper>
  )
}

Link.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  target: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default Link
