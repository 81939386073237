import breakpoints from '@/styles/breakpoints'

export function isTabletOrMobileDetect () {
  const userAgent = navigator.userAgent.toLowerCase()
  const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent)

  return (window.innerWidth <= breakpoints.sm) || isTablet
}

export function isTabletDetect () {
  const userAgent = navigator.userAgent.toLowerCase()
  const isTablet =
        (/(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
          userAgent
        ) ||
            (window.navigator.userAgent.match(/Mac/) &&
                window.navigator.maxTouchPoints &&
                window.navigator.maxTouchPoints > 2)) && window.innerWidth > window.innerHeight

  return isTablet
}
