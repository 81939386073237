import React, { useState, useRef, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { marketCodeManager } from '@/recoil/marketCode'
import RoundedImageWithLabel from '@/components/RoundedImageWithLabel'
import baseMap from '@/images/base-map.svg'
import mobileMap from '@/images/central-otago-marlborough-map.svg'
// import malboroughMap from '@/images/malborough-map.svg'
// import centralOtagoMap from '@/images/central-otago-map.svg'
import { isMobileState } from '@/recoil/layout'
import { HEADER_CLICK } from '@/utils/gtmEvents'

import PropTypes from 'prop-types'

import { subNavHeightState } from '@/recoil/header'

import BackBtn from '@/components/Header/BackBtn'

import {
  Container,
  StyledGridContainer,
  TextColumn,
  LocationsColumn,
  Title,
  Text,
  Button,
  Map,
  MobileMap,
  Header
} from './style'

const VisitUsSubnav = ({ backFunc = () => {} }) => {
  const data = useStaticQuery(graphql`
  query VisitUsSubnav {
    drupal {
      visitUsQuery: nodeById(id: "296") {
        ... on Drupal_NodeMegamenu {
          nid
          fieldMegamenuItems {
            entity {
              ... on Drupal_ParagraphMenuItemVisitUs {
                id
                fieldMenuVisitUsTitle
                fieldMenuVisitUsDescription
                fieldVisitUsSubtitleCelldoor
              }
            }
          }
        }
      }
      marlboroughQuery : nodeById(id: "234") {
        ... on Drupal_NodeCellarDoor {
          fieldCellarDoorCoverImage {
            url
            alt
          }
          fieldCellarDoorMenuBackImg {
            alt
            url
          }
          title
        }
        entityUrl {
          path
        }
      }
      centralOtagoQuery : nodeById(id: "235") {
        ... on Drupal_NodeCellarDoor {
          fieldCellarDoorCoverImage {
            url
            alt
          }
          fieldCellarDoorMenuBackImg {
            alt
            url
          }
          title
        }
        entityUrl {
          path
        }
      }
    }
  }
`)
  const isMobile = useRecoilValue(isMobileState)
  const [map, setMap] = useState(isMobile ? mobileMap : baseMap)
  const [mapAlt, setMapAlt] = useState(isMobile ? mobileMap : baseMap)
  const containerRef = useRef(null)
  const setSubNavHeight = useSetRecoilState(subNavHeightState)
  const marketCode = useRecoilValue(marketCodeManager)
  const subNavDataArray = [data.drupal.marlboroughQuery, data.drupal.centralOtagoQuery]
  const locationsData = []
  const visitUsData = data.drupal.visitUsQuery.fieldMegamenuItems.find((item) => item.entity.id === 471)

  subNavDataArray.forEach((data) => {
    locationsData.push(
      {
        name: data?.title,
        alt: data?.fieldCellarDoorCoverImage?.alt,
        image: data?.fieldCellarDoorCoverImage?.url,
        map: data?.fieldCellarDoorMenuBackImg?.url,
        mapAlt: data?.fieldCellarDoorMenuBackImg?.alt,
        path: data?.entityUrl?.path
      }
    )
  })

  const gtm = (name) => {
    typeof window !== "undefined" && window.dataLayer && window.dataLayer.push({
      event: HEADER_CLICK,
      cta_name: name
    })
  }

  useEffect(() => {
    if (containerRef.current) {
      setSubNavHeight(Math.round(containerRef.current.getBoundingClientRect().height))
    }
    return () => setSubNavHeight(0)
  }, [containerRef])

  return (
    <Container ref={ containerRef } className='subnav'>
      <StyledGridContainer>
        <Header>
          <BackBtn onClick={ backFunc } label='back' />
        </Header>
        <TextColumn>
          <Title>{ visitUsData?.entity?.fieldMenuVisitUsTitle }</Title>
          <Text>{ visitUsData?.entity?.fieldMenuVisitUsDescription }</Text>
          <Map loading='lazy' src={ map } />
        </TextColumn>
        <LocationsColumn>
          {
            locationsData.map(({ image, alt, name, map, mapAlt, path }, i) => (
              <RoundedImageWithLabel
                key={ `location-${i}` }
                image={ image }
                imageAlt={ alt }
                imageSize='small'
                title={ name }
                subtitle={visitUsData?.entity?.fieldVisitUsSubtitleCelldoor}
                onMouseOver={ () => {
                  setMap(map)
                  setMapAlt(mapAlt)
                } }
                to={ `/${marketCode}${path}/` }
                target='_self'
                onClick={ () => gtm(`Visit Us > ${name}`) }
              />
            ))
          }
        </LocationsColumn>
        <MobileMap src={ map } alt={ mapAlt } />
      </StyledGridContainer>
    </Container>
  )
}

VisitUsSubnav.propTypes = {
  backFunc: PropTypes.func
}

export default VisitUsSubnav
