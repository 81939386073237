import * as React from 'react'

import { useRecoilValue } from 'recoil'

import { popupDataState } from '@/recoil/popup'
import { STORE_LOCATOR_POPUP_ID } from '@/components/Popup/config'
import StoreLocator from '@/components/StoreLocator/'

import { Container } from './style'
import Popup from '@/components/Popup'

const StoreLocatorPopup = ({ pageType = 'Header' }) => {
  return (
    <Popup
      id={ STORE_LOCATOR_POPUP_ID }
      fullScreen
    >
      <Container>
        <StoreLocator pageType={ pageType } />
      </Container>
    </Popup>
  )
}

export default StoreLocatorPopup
