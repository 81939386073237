import React, { useEffect, useState, useRef, useLayoutEffect } from 'react'

import { useSetRecoilState } from 'recoil'
import { loadingState } from '@/recoil/loader'

import { useTheme } from '@emotion/react'
import {
  Container,
  Wrapper,
  Before,
  After,
  LogoBgWrapper,
  LogoWrapper
} from './style'

import Logo from '@/components/Logo'

import BlobOverlay from '@/libs/BlobOverlay'

const Loader = () => {
  const { colors } = useTheme()
  const containerRef = useRef(null)
  const [path, setPath] = useState(null)
  // LOCAL VALUE
  const [isLoading, setIsLoading] = useState(true)
  // STORE VALUE
  const setIsLoadingState = useSetRecoilState(loadingState)

  useEffect(() => {
    if (isLoading) {
      // TODO: ADD ITEMS TO LOAD HERE OR VIA RECOIL STORE
      setTimeout(() => {
        setIsLoading(false)
      }, 2000)
    }

    if (!path && !isLoading) {
      const overlay = new BlobOverlay({
        duration: 1500,
        loopCbk: (p) => setPath(p),
        endCbk: () => setIsLoadingState(false)
      })
      overlay.animate()
    }
  })

  useLayoutEffect(() => {
    if (containerRef.current) {
      containerRef.current.classList.remove('no-js')
    }
  }, [containerRef, isLoading, path, colors])

  return (
    <Container ref={ containerRef } className='no-js'>
      <Wrapper>
        <After>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
          <LogoBgWrapper>
            <Logo titleColor={ colors.lightGrey } subColor={ colors.lightGrey } />
          </LogoBgWrapper>
        </After>
        <Before path={ path ? `path('${path}')` : null }>
          <LogoWrapper>
            <Logo titleColor={ colors.white } />
          </LogoWrapper>
          <LogoBgWrapper>
            <Logo titleColor={ colors.white } subColor={ colors.white } />
          </LogoBgWrapper>
        </Before>
      </Wrapper>
    </Container>
  )
}

export default Loader
