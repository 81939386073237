import Link from '@/components/LocalizedGatsbyLink'
import cn from 'classnames'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import React, { useEffect, useRef, useState } from 'react'
import useScroll from 'react-use-scroll'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { loadingState } from '@/recoil/loader'
import { hasValidAgeManager } from '@/recoil/customer'
import { marketCodeManager } from '@/recoil/marketCode'

import { useLocation } from '@reach/router'

import Burger from '@/components/Burger'
import MobileNavItems from '@/components/Header/MobileNavItems'
import Search from '@/components/Header/Search'
import Socials from '@/components/Header/Socials'
import Icon from '@/components/Icon'
import Logo from '@/components/Logo'
import { popupDataManager, popupManager } from '@/recoil/popup'
import { Transition } from 'react-transition-group'

import { HEADER_CLICK, STORE_LOCATOR } from '@/utils/gtmEvents'
import isExternalLink from '@/utils/isExternalLink'
import isPdf from '@/utils/isPdf'

import { NEWSLETTER_POPUP_ID, STORE_LOCATOR_POPUP_ID, NEWSLETTER_MSG_POPUP_ID } from '@/components/Popup/config'
import isDescendant from '@/utils/isDescendant'
import theme from '../../theme'

import { isTouchDeviceState } from '@/recoil/device'
import { headerHeightState, HEADER_HEIGHT_CONFIG, subNavIdState } from '@/recoil/header'
import { isEcommerceState } from '@/recoil/isEcommerce'
import { isMobileState } from '@/recoil/layout'

import OurWinesSubnav from './OurWinesSubnav'
import VisitUsSubnav from './VisitUsSubnav'

import WineDirect from '@/utils/WineDirect'

import {
  Cart, DropdownMenu, IconsNav,
  IconsNavMobile, LogoContainer,
  LogoContainerLink,
  LogoTitle, NavContainer, NavGroup, NavIcon,
  NavIconDesktop, NavIconDesktopTemp, NavItem, NavItemIcon, NavItemLabel, Overlay, SearchWrapper, StyledGridContainer, StyledHeader, UserTool
} from './style'

const NAV_LINKS = [
  {
    id: 'our-wines',
    title: "Discover all the wines from the two Cloudy Bay wineries",
    icon: 'cart',
    label: 'Our Wines',
    to: '/our-wines',
    target: '_self'
  },
  {
    id: 'about-us',
    title: "See more about the Cloudy Bay vision, adventure and captivating flavour",
    label: 'About us',
    to: '/about-us',
    target: '_self'
  },
  {
    id: 'journal',
    title: "Find news, events information, handpicked recipes and a dash of inspiration",
    label: 'Journal',
    to: '/journal',
    target: '_self'
  },
  {
    id: 'visit-us',
    title: "Discover our places and meet the people behind the scenes",
    label: 'Visit us'
  },
  {
    id: 'wine-club',
    label: 'Wine Club',
    to: '/wine-club',
    target: '_self'
  }
]

const socialsItem = [
  {
    icon: 'facebook',
    to: 'https://www.facebook.com/CloudyBayWinery/'
  },
  {
    icon: 'instagram',
    to: 'https://www.instagram.com/cloudybaywinery/'
  },
  {
    icon: 'youtube',
    to: 'https://www.youtube.com'
  }
]

const subnavComponents = {
  'our-wines': OurWinesSubnav,
  'visit-us': VisitUsSubnav
}

const Header = ({ forcePlainStyle, pageType }) => {
  const containerRef = useRef(null)
  const searchRef = useRef(null)
  const labels = useRef({
    'our-wines': '',
    'about-us': '',
    'journal': '',
    'visit-us': '',
    'wine-club': '',
  })
  const [navLinks, setNavLinks] = useState(NAV_LINKS)
  const [search, setSearch] = useState(false)
  const [cart, setCart] = useState(false)
  const [user, setUser] = useState(false)
  const scroll = useScroll()
  const location = useLocation()
  const setPopup = useSetRecoilState(popupManager)
  const setPopupData = useSetRecoilState(popupDataManager)
  const setHeaderHeight = useSetRecoilState(headerHeightState)
  const headerHeight = useRecoilValue(headerHeightState)
  const [currentNavItem, setCurrentNavItem] = useRecoilState(subNavIdState)
  const isMobile = useRecoilValue(isMobileState)
  const isTouchDevice = useRecoilValue(isTouchDeviceState)
  const isEcommerce = useRecoilValue(isEcommerceState)
  const isLoading = useRecoilValue(loadingState)
  const ageGateValidated = useRecoilValue(hasValidAgeManager)

  const [mobileNavIsOpen, setMobileNavIsOpen] = useState(false)
  const [mode, setMode] = useState(scroll > 0 || (forcePlainStyle && scroll > 0) ? 'plain' : 'transparent')
  const iconSize = isMobile ? 24 : 20

  const marketCode = useRecoilValue(marketCodeManager)

  const gtmStoreLocator = (location, action) => {
    setPopup(STORE_LOCATOR_POPUP_ID)
    window.dataLayer && window.dataLayer.push({
      event: STORE_LOCATOR,
      site_location: 'Header',
      interaction_type: 'Open Store Locator'
    })
  }

  useEffect(() => {
    if (scroll > 0 || subnavComponents[currentNavItem] || forcePlainStyle) {
      if (mode === 'plain') return
      setMode('plain')
    } else if (!mobileNavIsOpen) {
      if (!search) {
        if (mode === 'transparent') return
        setMode('transparent')
      }
    }
  }, [search, scroll, currentNavItem, location, mobileNavIsOpen])

  const renderSubnav = (id) => {
    const SubnavComponent = subnavComponents[id]
    const backFunc = () => { setCurrentNavItem(null) }

    if (SubnavComponent) {
      return <SubnavComponent backFunc={ backFunc } />
    }

    return null
  }

  const gtm = (name) => {
    typeof window !== 'undefined' && window.dataLayer && window.dataLayer.push({
      event: HEADER_CLICK,
      cta_name: name
    })
  }

  const activeSubItem = (id) => {
    setCurrentNavItem(id)
    if (subnavComponents[id]) {
      setMode('plain')
    } else {
      if (scroll <= 0) {
        setMode('transparent')
      }
    }
  }

  const toggleMobileMenu = () => {
    if (mobileNavIsOpen) {
      setMode('transparent')
      currentNavItem !== null && activeSubItem(null)
      setSearch(false)
    } else {
      setMode('plain')
    }
    setMobileNavIsOpen(s => !s)
    setCart(false)
    setSearch(false)
  }

  const toggleSearch = () => {
    activeSubItem(null)
    setMobileNavIsOpen(false)
    setCart(false)

    if (search) {
      setMode('transparent')
      setMobileNavIsOpen(false)
    } else {
      setMode('plain')
    }

    setSearch(!search)
  }

  const toggleCart = () => {
    const wd = new WineDirect()

    if (cart) {
      setMode('transparent')
    } else {
      setMode('plain')
    }
    if(search){
      setSearch(!search)
    }
    if(mobileNavIsOpen){
      setMobileNavIsOpen(false)
    }
    wd.initWDCart()
    setCart(!cart)
    setUser(false)
    setCurrentNavItem(null)
  }

  const toggleUser = () => {
    const wd = new WineDirect()

    setCurrentNavItem(null)
    if(search){
      setSearch(!search)
    }
    wd.initWDCart()
    wd.initWDUserTools()
    setUser(!user)
    setCart(false)
  }

  const data = useStaticQuery(graphql`
    query HeaderQuery {
      drupal {
        navQuery: nodeQuery (filter: { conditions: [{ field: "type", value: "megamenu" }, { field: "status", value: "1" }]}) {
          entities {
            entityBundle
            entityLabel
            ... on Drupal_NodeMegamenu {
              fieldMegamenuItems {
                entity {
                  entityBundle
                  entityLabel
                  ... on Drupal_ParagraphMenuItemSimpleLink {
                    fieldMenuItemSimpleLink {
                      title
                      url {
                        path
                        routed
                      }
                      uri
                      options
                    }
                    fieldMenuSimpleLinkNewTab
                    fieldMenuSimpleLinkMarkets {
                      entity {
                        ... on Drupal_TaxonomyTermCountry {
                          langcode {
                            value
                          }
                          fieldCountryCode
                        }
                      }
                    }
                  }
                  ... on Drupal_ParagraphMenuItemOurWines {
                    fieldMenuItemOurWinesCover {
                      height
                      width
                      url
                      alt
                      title
                    }
                    fieldMenuOurWinesMarkets {
                      entity {
                        ... on Drupal_TaxonomyTermCountry {
                          langcode {
                            value
                          }
                          fieldCountryCode
                        }
                      }
                    }
                    fieldMenuItemOurWinesIntro
                    fieldMenuItemWfinderSubtitle
                    fieldMenuItemWfinderLink {
                      uri
                      url {
                        path
                        routed
                      }
                      title
                      options
                    }
                    fieldMenuItemOurWinesTitle
                    fieldMenuOurWinesOtherWines {
                      uri
                      url {
                        path
                        routed
                      }
                      title
                      options
                    }
                    fieldMenuItemOurWinesCta {
                      title
                      options
                      url {
                        path
                        routed
                      }
                      uri
                    }
                  }
                  ... on Drupal_ParagraphMenuItemVisitUs {
                    fieldMenuVisitUsTitle
                    fieldVisitUsSubtitleCelldoor
                    fieldMenuVisitUsDescription
                    fieldMenuVisitUsBackMap {
                      height
                      width
                      url
                      targetId
                      alt
                      title
                    }
                    fieldMenuVisitUsBackMapMob {
                      height
                      width
                      url
                      targetId
                      alt
                      title
                    }
                    fieldMenuVisitUsMarkets {
                      entity {
                        ... on Drupal_TaxonomyTermCountry {
                          langcode {
                            value
                          }
                          fieldCountryCode
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      drupal {
        nodeQuery(
          filter: {conditions: {field: "type", value: "footer", operator: EQUAL}}
          limit: 1000
        ) {
          entities {
            ... on Drupal_NodeFooter {
              fieldFooterSocialTitle
              fieldFooterMarkets {
                entity {
                  ... on Drupal_TaxonomyTermCountry {
                    fieldCountryCode
                    langcode {
                      value
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const rawNavLinks = []

  data.drupal.navQuery.entities[0].fieldMegamenuItems.forEach((i, k) => {
    let markets = []
    if (i.entity.entityBundle === "menu_item_our_wines") {
      markets = i.entity.fieldMenuOurWinesMarkets.map((market) =>
        `${market.entity.langcode.value}-${market.entity.fieldCountryCode}`.toLowerCase()
      )
      if (markets.includes(marketCode)) {
        rawNavLinks.push({
          id: 'our-wines',
          title: "Discover all the wines from the two Cloudy Bay wineries",
          icon: 'cart',
          label: i.entity.fieldMenuItemOurWinesTitle,
          to: '/our-wines',
          target: '_self',
          markets
        })
      }
    }

    if (i.entity.entityBundle === "menu_item_visit_us") {
      markets = i.entity.fieldMenuVisitUsMarkets.map((market) =>
        `${market.entity.langcode.value}-${market.entity.fieldCountryCode}`.toLowerCase()
      )
      if (markets.includes(marketCode)) {
        rawNavLinks.push({
          id: 'visit-us',
          title: "Discover our places and meet the people behind the scenes",
          label: i.entity.fieldMenuVisitUsTitle,
          markets
        })
      }
    }

    if (i.entity.entityBundle === "menu_item_simple_link") {
      markets = i.entity.fieldMenuSimpleLinkMarkets.map((market) =>
        `${market.entity.langcode.value}-${market.entity.fieldCountryCode}`.toLowerCase()
      )
      if (markets.includes(marketCode)) {
        rawNavLinks.push({
          id: i.entity.fieldMenuItemSimpleLink.url.path.replace('/', ''),
          title: i.entity.fieldMenuItemSimpleLink.title,
          label: i.entity.fieldMenuItemSimpleLink.title,
          to: i.entity.fieldMenuItemSimpleLink.url.path,
          target: i.entity.fieldMenuSimpleLinkNewTab ? '_blank' : '_self',
          markets
        })
      }
    }
  })

  const headerData = data.drupal.nodeQuery.entities.find((header) => {
    const headerMarketsCodes = header.fieldFooterMarkets.map((market) =>
      `${market.entity.langcode.value}-${market.entity.fieldCountryCode}`.toLowerCase()
    )

    return headerMarketsCodes.includes(marketCode)
  })

  useEffect(() => {
    setNavLinks(rawNavLinks)
    if (!isMobile) {
      const wineLink = {
        id: 'our-wines',
        icon: '',
        to: '/our-wines',
        target: '_self'
      }
      setNavLinks(old => {
        return old.map(
          (item) => {
            return item.id === 'our-wines' ? { ...item, ...wineLink } : item
          }
        )
      })
    } else if (isMobile) {
      const wineLink = {
        id: 'our-wines',
        label: 'Our Wines'
      }
      setNavLinks(old => {
        return old.map(
          (item) => {
            return item.id === 'our-wines' ? wineLink : item
          }
        )
      })
    }
  }, [isMobile, marketCode])

  // useEffect(() => {
  //   if (!isEcommerce && navLinks.length === 5) {
  //     setNavLinks(old => {
  //       old.pop()
  //       return old
  //     })
  //   } else if (isEcommerce && navLinks.length === 4) {
  //     setNavLinks(old => [...old,
  //       {
  //         id: 'wine-club',
  //         label: 'Wine Club',
  //         to: '/wine-club',
  //         target: '_self'
  //       }
  //     ])
  //   }
  // }, [isEcommerce])

  useEffect(() => {
    if (isMobile || isTouchDevice) return
    if (scroll > 1 && headerHeight !== HEADER_HEIGHT_CONFIG.SMALL) {
      setHeaderHeight(HEADER_HEIGHT_CONFIG.SMALL)
    } else if (scroll <= 1 && headerHeight !== HEADER_HEIGHT_CONFIG.NORMAL) {
      setHeaderHeight(HEADER_HEIGHT_CONFIG.NORMAL)
    }
  }, [scroll])

  useEffect(() => {
    setCurrentNavItem(null)
  }, [location])

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside (event) {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        if (search) {
          setMode('transparent')
          setSearch(!search)
        }
        if (cart){
          setCart(!cart)
        }

        if(user){
          setUser(!user)
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [containerRef, search, cart, user])

  const getMobileNav = () => {
    const baseNav = [
      {
        icon: 'pin',
        label: 'Where to buy',
        onClick: () => gtmStoreLocator()

      },
      {
        icon: 'contact',
        label: 'Newsletter',
        onClick: () => {
          navigate(`/${marketCode}/newsletter`)
          // setPopup(NEWSLETTER_POPUP_ID)
          setPopupData({
            origin: 'Header'
          })
        }
      }
    ]

    if (!isEcommerce) {
      return baseNav
    }

    return [
      ...baseNav,
      {
        icon: 'account',
        label: 'Account',
        onClick: () => toggleUser()
      }
    ]
  }

  const mobileNavItems = getMobileNav()

  return (
    <>
      <Overlay active={ mobileNavIsOpen } onClick={ () => setMobileNavIsOpen(false) } />
      <StyledHeader
        mode={ mode }
        mobileNavIsOpen={ mobileNavIsOpen }
        ref={ containerRef }
        style={ {
          '--header-height': `${headerHeight}px`
        } }
        onMouseLeave={
        (e) => {
          if (isTouchDevice) return
          const $sectionsNav = document.querySelector('#sections-nav')
          if ($sectionsNav) {
            const $hoveredEl = e.relatedTarget
            if (isDescendant($sectionsNav, $hoveredEl)) return
          }
          setCurrentNavItem(null)

          if (scroll <= 0 && !forcePlainStyle) {
            if (!search) {
              setMode('transparent')
            }
          }
        }
      }
      >

        <StyledGridContainer large>
          <IconsNavMobile>
            <Burger isOpen={ mobileNavIsOpen } onClick={ toggleMobileMenu } />
            <NavIcon onClick={ () => toggleSearch() }>
              <Icon size={ iconSize } name='search' color={ mode === 'transparent' ? theme.colors.white : theme.colors.brown } />
            </NavIcon>
          </IconsNavMobile>
          {pageType === 'Home'
            ?
            <LogoContainer title='Cloudy Bay New Zealand, Sauvignon Blanc, Chardonnay, Pinot Noir - Red and White wines - Marlborough Wine & Central Otago Wine Region'>
              <Logo
                titleColor={ mode === 'transparent' ? theme.colors.white : theme.colors.brown }
                subColor={ mode === 'transparent' ? theme.colors.white : theme.colors.brown }
                />
              <LogoTitle>Cloudy Bay New Zealand, Sauvignon Blanc, Chardonnay, Pinot Noir - Red and White wines - Marlborough Wine & Central Otago Wine Region</LogoTitle>
            </LogoContainer>
            :
              <LogoContainerLink to="/" title="Cloudy Bay New Zealand, Sauvignon Blanc, Chardonnay, Pinot Noir - Red and White wines - Marlborough Wine & Central Otago Wine Region">
                <Logo
                  titleColor={ mode === 'transparent' ? theme.colors.white : theme.colors.brown }
                  subColor={ mode === 'transparent' ? theme.colors.white : theme.colors.brown }
                  />
                <LogoTitle>Cloudy Bay New Zealand, Sauvignon Blanc, Chardonnay, Pinot Noir - Red and White wines - Marlborough Wine & Central Otago Wine Region</LogoTitle>
              </LogoContainerLink>
            }
          <NavContainer className={ mobileNavIsOpen && 'is-open' }>
            <NavGroup>
              {
              navLinks.map(({ id, title, icon, label, to, target = '_self' }, i) => (
                <NavItem
                  key={ i }
                  title={ title }
                  color={ mode }
                  className={ cn({ active: id === currentNavItem }, { link: !!to }) }
                  { ...((to && (isExternalLink(to) || isPdf(to))) && { as: 'a', href: to, target }) }
                  { ...((to && !isExternalLink(to) && !isPdf(to)) && { as: Link, to, target }) }
                  { ...((!to) && { as: 'div' }) }
                  onClick={
                    (e) => {
                      gtm((labels.current[id] && labels.current[id].length) ? labels.current[id] : label)
                      if (!isTouchDevice) return
                      if (to) {
                        setMobileNavIsOpen(false)
                      } else {
                        e.preventDefault()
                        activeSubItem(id)
                      }
                    }
                  }
                  onMouseEnter={
                    () => {
                      if (isTouchDevice) return
                      activeSubItem(id)
                      if (search) {
                        setSearch(!search)
                      }
                    }
                  }
                >
                  <NavItemLabel>{(labels.current[id] && labels.current[id].length) ? labels.current[id] : label}</NavItemLabel>

                  { icon && (
                    <NavItemIcon>
                      <Icon name={ icon } color={ mode === 'transparent' ? theme.colors.white : theme.colors.brown } />
                    </NavItemIcon>
                  )}

                  {isMobile && renderSubnav(id) != null && <Icon size={ 24 } name='arrow-right' />}
                </NavItem>
              ))
            }
            </NavGroup>
            <NavGroup>
              <MobileNavItems items={ mobileNavItems } />
            </NavGroup>
            <NavGroup>
              <Socials items={ socialsItem } data={ headerData } />
            </NavGroup>
          </NavContainer>
          <IconsNav>
            <NavIconDesktop tabIndex={0} aria-role="button" aria-label="Open Search Bar" name="Open Search Bar" onClick={ () => toggleSearch() } onKeyDown={(e) => e.key === "Enter" && toggleSearch()}>
              <Icon size={ iconSize } name='search' color={ mode === 'transparent' ? theme.colors.white : theme.colors.brown } />
            </NavIconDesktop>
            {
              (isEcommerce && !isMobile) && (
                <NavIcon onClick={ () => toggleUser() }>
                  <Icon size={ iconSize } name='account' color={ mode === 'transparent' ? theme.colors.white : theme.colors.brown } />
                </NavIcon>
              )
            }

            {
              isEcommerce && (
                <UserTool className={ `${user && 'visible'}` }>
                  <div className='usertools' v65remotejs='loginWidget' />
                </UserTool>
              )
            }

            {
              isEcommerce && (
                <NavIcon onClick={ () => toggleCart() } id="cartIcon">
                  <Icon size={ iconSize } name='cart' color={ mode === 'transparent' ? theme.colors.white : theme.colors.brown } />
                </NavIcon>
              )
            }

            {
              isEcommerce && (
                <Cart className={ `${cart && 'visible'}` }>
                  <div className='cart' v65remotejs='modalCart' />
                </Cart>
              )
            }

            {/* <NavIconDesktop to='#'>
            <Icon size={ iconSize } name='search' color={ mode === 'transparent' ? theme.colors.white : theme.colors.brown } />
          */}
            <NavIcon>
              <Link to='/newsletter' title="Open the newsletter form to subscribe">
                <Icon size={ iconSize } name='contact' color={ mode === 'transparent' ? theme.colors.white : theme.colors.brown } />
              </Link>
            </NavIcon>
            {/* <NavIconDesktopTemp href="https://www.google.com/url?q=https://cloud.email.cloudybay.com/capture-form?uid%3DS07JJbLBxg1cI8dGIKBu2PtxO7EMDcO4WtcmVtgUuRArgGESiqxFqIZutepWa&source=gmail-imap&ust=1655222418000000&usg=AOvVaw1xrGZlqmR70nEWxeleRr_v" target="_blank">
            <Icon size={ iconSize } name='contact' color={ mode === 'transparent' ? theme.colors.white : theme.colors.brown } />
          </NavIconDesktopTemp> */}
            <NavIcon onClick={ () => gtmStoreLocator() }>
              <Icon size={ iconSize } name='pin' color={ mode === 'transparent' ? theme.colors.white : theme.colors.brown } />
            </NavIcon>
          </IconsNav>
        </StyledGridContainer>
        <Transition
          timeout={ 0 }
          in={ !!subnavComponents[currentNavItem] }
          unmountOnExit
          mountOnEnter
        >
          <DropdownMenu>
            { renderSubnav(currentNavItem) }
          </DropdownMenu>
        </Transition>

        <SearchWrapper ref={ searchRef } className={ `${search && 'visible'}` }>
          <Search />
        </SearchWrapper>
      </StyledHeader>
    </>
  )
}

export default Header
