import styled from '@emotion/styled'
import { Swiper } from 'swiper/react'

import { mediaMax, styledScrollbar } from '@/styles/mixins'

import 'swiper/css'
import 'swiper/css/virtual'

import WysiwygBase from '@/components/Wysiwyg'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: center;
  position: relative;
  ${mediaMax.xs}{
    flex-direction: column;
    gap: 1.5rem;
  }
`

export const ImageWrapper = styled.div`
  width: 42%;
  height: 100%;
  ${mediaMax.xs}{
    width: 100%;
  }
  `
export const SwiperContainer = styled(Swiper)`
  width: 100%;
  height: 100%;

  .swiper-slide {
    width: 100%;
  }
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 30%;
`

export const Wrapper = styled.div`
  width: 58%;
  height: 100%;
  padding-left: 8rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  ${mediaMax.xs}{
    width: 100%;
    padding-left: 0;
  }
`

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  overflow-y: auto;
  // ${styledScrollbar()}
`

export const Text = styled(WysiwygBase)`
  font-size: 1.2rem;
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  color: ${({ theme }) => theme.colors.grey};
  letter-spacing: .1rem;
  line-height: 2.2rem;
  padding-right: 1.5rem;
`
