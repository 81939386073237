import React from 'react'
import PropTypes from 'prop-types'
import Link from '@/components/LocalizedGatsbyLink'

import Icon from '@/components/Icon'
import * as S from './style'

const Item = ({ item: { icon, label, to = '', onClick = () => {} } }) => {
  return (
    <S.Item
      { ...(to && { as: Link, to }) }
      { ...(!to.length && { onClick }) && { as: 'div', onClick: onClick } }
    >
      { icon && <Icon name={ icon } size={ 24 } /> }
      <S.Label>{label}</S.Label>
    </S.Item>
  )
}

Item.propTypes = {
  item: PropTypes.shape({
    icon: PropTypes.string,
    label: PropTypes.string,
    to: PropTypes.string
  })
}

export default function MobileNavItems ({ items = [] }) {
  if (items.length <= 0) return null

  return (
    <S.Container>
      {items.map((item, i) => <Item key={ i } item={ item } />)}
    </S.Container>
  )
}

MobileNavItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string,
    label: PropTypes.string,
    to: PropTypes.string,
    onClick: PropTypes.func
  }))
}
