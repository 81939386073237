import styled from '@emotion/styled'
import { Swiper } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/virtual'

import { GridContainer } from '@/components/Grid'
import { mediaMax } from '@/styles/mixins'

import {
  LeftArrow,
  RightArrow,
  Wrapper as ArrowWrapper
} from '@/components/Block/Sliders/atoms/Arrows/style'

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.lightVanilla3};
  ${mediaMax.xs} {
    height: calc(100vh - var(--header-height) + 1px);
    overflow: scroll;
    padding: var(--gap-items) 0;
  }
`

export const StyledGridContainer = styled(GridContainer)`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  ${mediaMax.xs}{
    flex-direction: column;
  }
`

export const SearchBar = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`

export const SearchLabel = styled.label`
  display: none;
`;

export const SearchInput = styled.input`
  background: transparent;
  border: none;
  webkit-appearance: none;
  padding: 2.5rem 0 2.5rem ${({ theme }) => theme.spacing.small};
  border-radius: none;
  outline: none;
  font-family: ${({ theme }) => theme.fonts.serif};
  color: ${({ theme }) => theme.colors.brown};
  font-size: 1.8rem;
  width: 100%;
  &::placeholder {
    color: ${({ theme }) => theme.colors.grey};
  }
`

export const Categories = styled.div`
  display: flex;
  align-items: stretch;

  ${mediaMax.xs} {
    width: 100%;
  }
`

export const Category = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  padding: 0 ${({ theme }) => theme.spacing.xsmall};
  position: relative;
  color: ${({ active, theme }) => active ? theme.colors.green : theme.colors.grey};
  font-weight: bold;
  letter-spacing: 1px;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 35%;
    height: 30%;
    width: 1px;
    background: ${({ theme }) => theme.colors.lightGrey}
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 100%;
    background: ${({ active, theme }) => active ? theme.colors.green : 'transparent'}
  }
  &:last-child {
    &:after {
      display: none;
    }
  }

  ${mediaMax.xs} {
    height: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
`

export const SearchResults = styled(GridContainer)`
  // height: 200px;
  padding: 2rem 0 4rem 0;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.lightGrey};

  ${mediaMax.xs} {
    padding-top: 1.6rem;
  }
`

export const SearchResultsLength = styled.div`
  font-family: ${({ theme }) => theme.fonts.serif};
  color: ${({ theme }) => theme.colors.black};
  font-size: 1.6rem;
  margin-bottom: 2rem;
`

export const SwiperContent = styled(Swiper)`
  width: 100%;
  overflow: visible !important;

  .swiper-slide {
    width: 36rem;
    height: 20rem;
    margin-right: 2.5rem;
  }
`

export const SwiperCat = styled(Swiper)`
  width: 100%;
  overflow: hidden;

  .swiper-slide {
    width: auto;
    height: auto;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
`

export const SearchResultsList = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
`

export const ActionWrapper = styled.div`
  margin-top: 4rem;
  width: 100%;
  overflow: hidden;

  ${ArrowWrapper} {
    justify-content: flex-start !important;
    flex-direction: row !important;

    ${LeftArrow},
    ${RightArrow} {
      transform: rotate(0deg) !important;
    }
  }
  ${mediaMax.xs}{
    display: none;
  }
`
