export function resolve (path, obj) {
  return path.split('.').reduce((prev, curr) => {
    if (prev) {
      if (Array.isArray(prev)) {
        return prev.map(o => o[curr])
      }

      return prev[curr]
    }

    return null
  }, obj)
}
