import styled from '@emotion/styled'
import { mediaMax } from '@/styles/mixins'

export const Container = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4rem;

  ${mediaMax.md}{
    gap: 2rem;
  }

  ${mediaMax.sm} {
    flex-direction: column;
    gap: 3rem;
    margin-bottom: var(--spacing-section);
  }

  span {
    position: absolute;
    width: 1px;
    clip: rect(0 0 0 0);
    overflow: hidden;
    white-space: nowrap;
  }
`

export const NavLink = styled.div`
  text-decoration: none;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  font-size: 1.2rem;

  ${mediaMax.xs} {
    margin-right: 0;
    font-size: 1.4rem;
  }
`
