import { useStaticQuery, graphql } from 'gatsby'

export const getStoreLocatorData = () => {
  const data = useStaticQuery(
    graphql`
      query StoreLocator {
        locations: drupal {
          nodeQuery(
            filter: {conditions: [{operator: EQUAL, field: "type", value: ["pos_item"]}, {operator: EQUAL, field: "status", value: "1"}]},
            limit: 10000
          ){
            entities {
              ... on Drupal_NodePosItem {
                nid
                sticky
                promote
                queryFieldPosType {
                  entities {
                    ... on Drupal_TaxonomyTermPosType {
                      tid
                    }
                  }
                }
                fieldPosExternalUrl {
                  uri
                }
                fieldPosCoordinates {
                  lat
                  lng
                }
                fieldPosAddress {
                  countryCode
                  locality
                  addressLine1
                  addressLine2
                  administrativeArea
                }
                fieldPosDescription {
                  value
                  format
                  processed
                }
                fieldPosEmail
                fieldPosExternalUrl {
                  attribute(key: "string")
                  url{
                    routed
                    path
                  }
                  uri
                  title
                  options
                }
                fieldPosFax
                fieldPosGoogleMonday
                fieldPosGoogleTuesday
                fieldPosGoogleWednesday
                fieldPosGoogleThursday
                fieldPosGoogleFriday
                fieldPosGoogleSaturday
                fieldPosGoogleSunday
                fieldPosGoogleMapLink {
                  attribute(key: "string")
                  url{
                    routed
                    path
                  }
                  uri
                  title
                  options
                }
                fieldPosGooglePlaceId
                fieldPosHasPage
                fieldPosName
                fieldPosNearCity
                fieldPosOpenMonday {
                  value
                  date
                }
                fieldPosOpenTuesday {
                  value
                  date
                }
                fieldPosOpenWednesday {
                  value
                  date
                }
                fieldPosOpenTuesday {
                  value
                  date
                }
                fieldPosOpenFriday {
                  value
                  date
                }
                fieldPosOpenSaturday {
                  value
                  date
                }
                fieldPosOpenSunday {
                  value
                  date
                }
                fieldPosCloseMonday {
                  value
                  date
                }
                fieldPosCloseTuesday {
                  value
                  date
                }
                fieldPosCloseWednesday {
                  value
                  date
                }
                fieldPosCloseThursday {
                  value
                  date
                }
                fieldPosCloseFriday {
                  value
                  date
                }
                fieldPosCloseSaturday {
                  value
                  date
                }
                fieldPosCloseSunday {
                  value
                  date
                }
                fieldPosPhone
                fieldPosProduct
                fieldPosTags {
                  targetId
                  entity{
                    name
                  }
                }
                fieldPosType {
                  targetId
                  entity{
                    name
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  return data
}

export default getStoreLocatorData
