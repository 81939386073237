import React, { useRef, useState, useEffect } from 'react'
import { useTheme } from '@emotion/react'
import { useRecoilValue } from 'recoil'

// import Player from '@vimeo/player'
import { popupDataState, popupState } from '@/recoil/popup'
import { VIDEO_POPUP_ID } from '@/components/Popup/config'

import Popup from '@/components/Popup'
import Icon from '@/components/Icon'

import {
  Container,
  MediaWrapper,
  PopUpWrapper,
  IconWrapper,
  Source
} from './style'

const VideoPopup = () => {
  const theme = useTheme()
  const popupData = useRecoilValue(popupDataState)
  const popup = useRecoilValue(popupState)
  const [isPlaying, setIsPlaying] = useState(true)
  const videoRef = useRef(null)
  // to control the vimeo iframe you need to use vimeo sdk player :
  // const player = new Player(id)

  // const play = (e) => {
  //   videoRef.current.play()
  //   setIsPlaying(true)
  // }

  // const pause = (e) => {
  //   videoRef.current.pause()
  //   setIsPlaying(false)
  // }

  // const handleClick = _ => {
  //   !isPlaying ? play() : pause()
  // }

  return (
    <PopUpWrapper>
      <Popup
        id={ VIDEO_POPUP_ID }
        title={ popupData?.title }
        subtitle=''
        closeCallback={ popupData?.closeCallback}
      >
        <Container>
          <MediaWrapper>
            {/* <IconWrapper>
              {
                !isPlaying
                  ? <Icon
                      name='play'
                      size={ 30 }
                      color={ theme.colors.white }
                    />
                  : <Icon
                      name='pause'
                      size={ 30 }
                      color={ theme.colors.white }
                    />
              }
            </IconWrapper> */}
            { popup === VIDEO_POPUP_ID && popupData && 
             (<Source
                src={ `https://player.vimeo.com/video/${popupData.source?.replace('https://vimeo.com/', '')}?autoplay=1` }
                allow='autoplay' frameborder='0' ref={ videoRef } id={ videoRef }
              />)}
          </MediaWrapper>
        </Container>
      </Popup>
    </PopUpWrapper>
  )
}

export default VideoPopup
